import { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Buffer } from 'buffer';
import LoadingOverlay from "../../components/LoadingOverlay";
import customerService from "../../services/customer.service";
import { AxiosError } from "axios";
import useAuth from "../../hooks/useAuth";
import { MsTeamsAdminConsent } from "../../types/customer/MsTeamsAdminConsent";
import { GraphUrlStateDetails } from "../../types/customer/GraphUrlStateDetails";
import { MsTeamsProvisioningActionEnum } from "../../enums/MsTeamsProvisioningActionEnum";
import { MsTeamsAdminAuthCode } from "../../types/customer/MsTeamsAdminAuthCode";
import { appUrl } from "../..";
import useSessionStorage from "../../hooks/useSessionStorage";
import { MsTeamsUserPolicyDetail } from "../../types/localstorage-filters/MsTeamsUserPolicyDetail";


const MsTeamsAdminAction = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [msTeamsRecordingPolicyUsers, setMsTeamsRecordingPolicyUsers] = useSessionStorage<MsTeamsUserPolicyDetail[] | undefined>(
    "MsTeamsRecordingPolicyUsers",
    undefined
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceError, setServiceError] = useState<boolean>(false);
  
  const admin_consent = searchParams.get("admin_consent");
  const tenant = searchParams.get("tenant");
  const scope = searchParams.get("scope");
  const error = searchParams.get("error");
  const error_description = searchParams.get("error_description");
  const state = searchParams.get("state");
  const code = searchParams.get("code");

  useEffect(() => {
    setLoading(true)
    if(error === null && error_description === null){
      if(state !== null){
        let stateObjString = Buffer.from(state, "base64").toString();
        let stateDetails: GraphUrlStateDetails = JSON.parse(stateObjString);
        if(stateDetails && stateDetails.msTeamsProvisioningAction === MsTeamsProvisioningActionEnum.GrantMsAdminConsent){
          updateMsTeamsAdminConsent(stateDetails);
        }
        else if(stateDetails && stateDetails.msTeamsProvisioningAction === MsTeamsProvisioningActionEnum.CreateRecordingPolicy){
          createMsTeamsRecordingPolicy(stateDetails);
        }
        else if(stateDetails && stateDetails.msTeamsProvisioningAction === MsTeamsProvisioningActionEnum.AssignRecordingPolicyToUsers){
          assignMsTeamsRecordingPolicy(stateDetails)
        }
      }
    }
    else{
      setLoading(false)
    }
  }, [])

  const updateMsTeamsAdminConsent = async (stateDetails: GraphUrlStateDetails) => {
    try{
      const customerId = auth?.tokenPayload?.CustomerId;
      if(stateDetails && customerId !== undefined && customerId === stateDetails.customerId){
        let adminConsentBody : MsTeamsAdminConsent = {
          adminConsent : admin_consent === "True" ? true : false,
          tenantGUID: tenant ?? "",
          platform: stateDetails.platform
        }
        const adminConsentResult = await customerService.updateMsTeamsAdminConsent(customerId, adminConsentBody);
        navigate("/customer-platform", { replace: true });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        setServiceError(true);
      }
    }
    finally {
      setLoading(false);
    }
  }

  const createMsTeamsRecordingPolicy = async (stateDetails: GraphUrlStateDetails) => {
    try{
      const customerId = auth?.tokenPayload?.CustomerId;
      if(stateDetails && customerId !== undefined && customerId === stateDetails.customerId){
        let authCodeBody : MsTeamsAdminAuthCode = {
          authCode: code ?? "",
          redirectUri: appUrl + '/customer-platform/admin-action'
        }
        const createPolicyResult = await customerService.CreateMsTeamsRecordingPolicy(customerId, authCodeBody);
        navigate("/customer-platform", { replace: true });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        setServiceError(true);
      }
    }
    finally {
      setLoading(false);
    }
  }

  const assignMsTeamsRecordingPolicy = async (stateDetails: GraphUrlStateDetails) => {
    try{
      const customerId = auth?.tokenPayload?.CustomerId;
      if(stateDetails && customerId !== undefined && customerId === stateDetails.customerId && msTeamsRecordingPolicyUsers !== undefined){
        let authCodeBody : MsTeamsAdminAuthCode = {
          authCode: code ?? "",
          redirectUri: appUrl + '/customer-platform/admin-action',
          recordingPolicyUsers: msTeamsRecordingPolicyUsers
        }
        const assignPolicyResult = await customerService.AssignMsTeamsRecordingPolicy(customerId, authCodeBody);
        navigate("/customer-platform", { replace: true });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        setServiceError(true);
      }
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className="w-100 text-center">
      {loading && 
        <LoadingOverlay
          customStyle={{
            position: "fixed",
            marginTop: "55px",
            zIndex: "999999",
          }}
          themeColor={"light"}
          size={"medium"}
          loadingText={"please wait while we are getting things ready for you..."}
        />
      }
      {(error !== null || error_description !== null || serviceError === true) &&
        <div className="errorTxt">
          <div className="errorTxt-h fs-30 font-weight-semi p-b-20">
            Error
          </div>
          <div className="errorTxt-p fs-16 p-b-20">
            Please try again later or feel free to contact us if the problem persists.
            <div className="errorTxt-p fs-14 p-b-20 p-t-10"> {error_description}</div>
          </div>
          <div className="errorBtn">
            <Link
              className="fs-15 bg-primary text-white padding-6 p-l-15 p-r-15"
              replace
              to="/customer-platform"
            >
              Back to Customer Platform
            </Link>
          </div>
        </div>
      }
    </div>
  );
};

export default MsTeamsAdminAction;
