export enum ExcludedColumnsEnum {
    entityFriendlyName = "entityFriendlyName",
    EntityType = "EntityType",
    Action = "Action",
    Property = "Property",
    OldValue = "OldValue",
    NewValue = "NewValue",
    ChangedBy = "ChangedBy",
    ChangedAt = "ChangedAt"

}
