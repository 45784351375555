import { CallResult } from "../types/call";
import {  axiosWithAuth } from "../utils/customAxios";
import { ReasonCodeInstance } from "../types/call/CallResultReasonCode";

const callResultService = {

    getAllCallResults: async (): Promise<CallResult[]> => {
        try {
          const response = await axiosWithAuth.get(`/callResult`);
          const fetchedCallResults:CallResult[]=response.data;
          return fetchedCallResults;
        } catch (err) {
          throw err;
        }
      },

    createCallResult:async(callResultData:CallResult):Promise<CallResult>=>{
        try{
          const response = await axiosWithAuth.post(`callResult`, callResultData);
          const createdCallResult:CallResult=response.data;
          return createdCallResult;
        }catch(err){
          throw err;
        }
      },

  updateCallResult: async (
    callResultId: number,
    newCallResultData: CallResult
  ): Promise<CallResult> => {
    try{
        const response = await axiosWithAuth.put(`/callResult/${callResultId}`, newCallResultData);
        const updatedCallResult: CallResult = response.data;
    return updatedCallResult;
    }catch(err){
        throw err;
    }
  },

  updateReasonCodesMappingWithCallResult: async (
    callResultId: number,
    reasonCodes: string[]
  ): Promise<ReasonCodeInstance[]> => {
    try{
        const response = await axiosWithAuth.put(`/callResult/${callResultId}/mapping`, reasonCodes);
        const updatedReasonCodesMapping: ReasonCodeInstance[] = response.data;
        return updatedReasonCodesMapping;
    }catch(err){
        throw err;
    }
  },

  deleteCallResult: async (callResultId: number) => {
    try{
        await axiosWithAuth.delete(`/callResult/${callResultId}`);
    }catch(err){
        throw err;
    }
  },

};

export default callResultService;
