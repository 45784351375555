import { FieldRenderProps } from "@progress/kendo-react-form";
import { Switch } from "@progress/kendo-react-inputs";
import { useCallback } from "react";

const CustomSwitch = (fieldRenderProps: FieldRenderProps) => {
  const {
    // The meta props of the Field.
    validationMessage,
    touched,
    visited,
    modified,
    valid,
    // The input props of the Field.
    value,
    onChange,
    onFocus,
    onBlur,
    // The custom props that you passed to the Field.
    ...others
  } = fieldRenderProps;

  const onValueChange = useCallback(() => {
    // onChange callback expects argument with 'value' property
    onChange({ value: !value });
  }, [onChange, value]);

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      <Switch onChange={onValueChange} checked={value} id={others.id} />
      {
        // Display an error message after the "visited" or "touched" field is set to true.
        visited && validationMessage && (
          <div className={"k-required"}>{validationMessage}</div>
        )
      }
    </div>
  );
};

export default CustomSwitch;
