import * as React from "react";
import * as ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import WaveSurfer from "wavesurfer.js";

const PlayerHiHi = () => {
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const [playing, setPlaying] = useState(false);
  const [volumeIcon, setVolumeIcon] = useState(false);
  var waveform: any = null;
  const url =
    "https://api.twilio.com//2010-04-01/Accounts/AC25aa00521bfac6d667f13fec086072df/Recordings/RE6d44bc34911342ce03d6ad290b66580c.mp3";

  useEffect(() => {
    if (waveform === null) {
      waveform = WaveSurfer.create({
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 1,
        container: "#waveformtest",
        backend: "WebAudio",
        height: 60,
        progressColor: "#68aaf2",
        responsive: true,
        waveColor: "#C4C4C4",
        cursorColor: "transparent",
      });

      waveform.load(url);
    }
  }, []);

  const handlePlay = () => {
    setPlaying(!playing);
    waveform.playPause();
  };
  const handleVolume = () => {
    setVolumeIcon(!volumeIcon);
  };

  return (
    <div className="playerBody">
      <div className="Wave" id="waveformtest" />
    </div>
  );
};

export default PlayerHiHi;
