import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from '../../constants';
import useBranding from '../../hooks/useBranding';
import { AccountInfo, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { UserTempTokenRequest } from '../../types/demo/UserTempTokenRequest';
import demoService from '../../services/demo.service';
import { Button } from '@progress/kendo-react-buttons';
import msIcon from "../../assets/images/ms-icon.svg";
import useSwal from '../../hooks/useSwal';
import { SweetAlertOptions } from 'sweetalert2';
import { Dictionary } from '../../types/Dictionary';
import useLocale from '../../hooks/useLocale';
import { AxiosError } from 'axios';

const DemoLoginRegister = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const brandingCtx = useBranding();
  const swal = useSwal();
  const localeCtx = useLocale();
  const { instance: microsoftInstance } = useMsal();

  const [translationsLoading, setTranslationsLoading] = useState<boolean>(false);
  const [translations, setTranslations] = useState<Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["DemoLoginRegister"]);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["DemoLoginRegister"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);
  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("DemoLoginRegister");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["DemoLoginRegister"]
      );
      localeCtx?.setPreviousAppLocale("DemoLoginRegister");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const getFromPath = (): string => {
    const from = location.state?.from?.pathname + location.state?.from?.search || "/home";
    return from;
  }

  const getAuthAppbaseUrl = (): string => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration?.authClientAppUrl) {
      authAppBaseUrl = brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    return authAppBaseUrl;
  }

  const getAuthAppClientId = (): string => {
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration?.authAppClientId) {
      authAppClientId = brandingCtx.branding.authAppConfiguration.authAppClientId;
    }
    return authAppClientId;
  }

  const getAuthAppLoginUrl = (): string => {
    const from = getFromPath();
    const originalRedirectUri = searchParams.get("originalRedirectUri");

    const authAppBaseUrl = getAuthAppbaseUrl();
    let authAppClientId = getAuthAppClientId();

    const authAppLoginUrl = `${authAppBaseUrl}/login?clientId=${authAppClientId}&originalRedirectUri=${originalRedirectUri ?? from}&isAutoMsLoginEnabled=true`;
    return authAppLoginUrl;
  }

  const loginWithMS = () => {
    const authAppLoginUrl = getAuthAppLoginUrl();
    window.location.replace(authAppLoginUrl);
  }

  const getMicrosoftGraphToken = async (account: AccountInfo) => {
    const tokenRequest: SilentRequest = {
      scopes: ["User.Read", "openid", "profile"],
      account: account,
    };

    let token: string | undefined;
    try {
      const { accessToken } = await microsoftInstance.acquireTokenSilent(tokenRequest);
      token = accessToken;
    } catch (e) {
      const { accessToken } = await microsoftInstance.acquireTokenPopup(tokenRequest);
      token = accessToken;
    }
    return token;
  };

  const getAPIAccessTempToken = async (reqPayload: UserTempTokenRequest) => {
    try {
      let authAppClientId = AUTH_CLIENT_ID;
      if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
        authAppClientId = brandingCtx.branding.authAppConfiguration.authAppClientId;
      }
      if (authAppClientId) {
        const statusCode = await demoService.verifyTokenAndCreateCustomer(reqPayload, authAppClientId);
        let msg = "";
        switch (statusCode) {
          case 200: msg = translationsLoading
            ? "Demo customer created successfully"
            : fetchLabelKeyTranslation("CustomerCreationText", "Demo customer created successfully")
            break;
          default: msg = translationsLoading
            ? "Something went wrong"
            : fetchLabelKeyTranslation("SomethingWentWrongText", "Something went wrong")
            break;
        }

        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: msg,
          confirmButtonText: translationsLoading
            ? "OK"
            : fetchLabelKeyTranslation("OKText", "OK")
        }
        swal.fire(swalOptions).then((resp) => {
          loginWithMS();
        });
      }
    }
    catch (err) {
      if (err instanceof AxiosError) {
        let msg = "";
        switch (err?.response?.status) {
          case 400: msg = translationsLoading
            ? "Invalid third party provider"
            : fetchLabelKeyTranslation("InvalidPartyProviderText", "Invalid third party provider")
            break;
          case 401: msg = translationsLoading
            ? "Invalid credentials"
            : fetchLabelKeyTranslation("InvalidCredentialsText", "Invalid credentials")
            break;
          case 404: msg = translationsLoading
            ? "ClientId is missing"
            : fetchLabelKeyTranslation("ClientIdMissingText", "ClientId is missing")
            break;
          case 409: msg = translationsLoading
            ? "Email already registered"
            : fetchLabelKeyTranslation("EmailExistsText", "Email already registered")
            break;
          case 422: msg = translationsLoading
            ? "User could not be inserted"
            : fetchLabelKeyTranslation("UserInsertionErrorText", "User could not be inserted")
            break;
          case 500: msg = translationsLoading
            ? "Something went wrong"
            : fetchLabelKeyTranslation("SomethingWentWrongText", "Something went wrong")
            break;
          default: msg = translationsLoading
            ? "Something went wrong"
            : fetchLabelKeyTranslation("SomethingWentWrongText", "Something went wrong")
            break;
        }

        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: msg,
          text: translationsLoading ? "Please Try Again!" : fetchLabelKeyTranslation("PleaseTryAgainText", "Please Try Again!"),
          confirmButtonText: translationsLoading ? "OK" : fetchLabelKeyTranslation("OKText", "OK")
        };
        swal.fire(swalOptions);
      }
    }
  }

  const userThirdPartyLoginHandler = async (thirdPartyProvider: "Microsoft") => {
    try {
      let authAppClientId = AUTH_CLIENT_ID;
      if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
        authAppClientId = brandingCtx.branding.authAppConfiguration.authAppClientId;
      }
      if (authAppClientId) {
        switch (thirdPartyProvider) {
          case "Microsoft": {
            const { account } = await microsoftInstance.loginPopup();
            if (account) {
              const token = await getMicrosoftGraphToken(account);
              const reqPayload: UserTempTokenRequest = {
                username: account.username,
                accessToken: token,
                thirdPartyProvider: "Microsoft",
              };
              await getAPIAccessTempToken(reqPayload);
            }
            break;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className="loginBg float-left w-100 h-100">
      <div className="h-100 p-l-15 p-r-15">
        <div className="row m-b-20 h-100">
          <div className="col-md-12 h-100">
            <div className="formGroup h-100">
              <div
                className="formInrG cardEffect"
                style={{ position: "relative" }}
              >
                {brandingCtx?.branding?.logos.find(
                  (l) => l.name === "HeaderPrimaryLogo"
                )?.logoImageUrl && (
                    <div className="hdrTrk-logo text-center p-t-5 p-b-15">
                      {/* <span className="fs-22 font-weight">LOGO_PLACEHOLDER</span> */}
                      <img
                        src={
                          brandingCtx?.branding?.logos.find(
                            (l) => l.name === "HeaderPrimaryLogo"
                          )?.logoImageUrl
                        }
                        alt="Logo"
                      />
                    </div>
                  )}

                <div className="k-form-buttons d-flex flex-wrap justify-content-center m-t-15" style={{ width: '100%' }}>
                  <>
                    <Button
                      id="msRegisterButton"
                      type={"button"}
                      style={{
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                      className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 imgBtn`}
                      onClick={loginWithMS}
                    >
                      <img
                        className="p-r-5"
                        src={msIcon}
                        height="24spx"
                        width="24px"
                      />
                      {translationsLoading ? "Login with Microsoft" : fetchLabelKeyTranslation("LoginwithMicrosoftText", "Login with Microsoft")}
                    </Button>
                    <Button
                      type={"button"}
                      style={{
                        width: "100%",
                        textTransform: "uppercase",
                      }}
                      className={`btn bg-primary text-white fs-16 p-t-7 p-b-7 imgBtn`}
                      onClick={() => userThirdPartyLoginHandler("Microsoft")}
                    >
                      <img
                        className="p-r-5"
                        src={msIcon}
                        height="24spx"
                        width="24px"
                      />
                      {translationsLoading ? "Register with Microsoft" : fetchLabelKeyTranslation("RegisterwithMicrosoftText", "Register with Microsoft")}
                    </Button>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DemoLoginRegister
