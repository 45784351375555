import React from "react";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import { kendoAlertData } from "../../pages/call-details/CallDetails";
import { SetValue } from "../../hooks/useLocalStorage";

interface AlertData {
  setAlertData: SetValue<kendoAlertData>;
  alertData: kendoAlertData;
}

const Alert: React.FC<AlertData> = ({ setAlertData, alertData }) => {
  return (
    <React.Fragment>
      <NotificationGroup
        style={{
          top: "50px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 999,
        }}
      >
        <Slide direction="down">
          <Notification
            type={{
              style: alertData.style,
              icon: true,
            }}
            closable={true}
            onClose={() => setAlertData({ ...alertData, popup: false })}
          >
            <span>{alertData.message}</span>
          </Notification>
        </Slide>
      </NotificationGroup>
    </React.Fragment>
  );
};

export default Alert;
