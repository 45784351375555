import { createContext, useState } from "react";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../hooks/useSwal";
import { useLocation, useNavigate } from "react-router-dom";

export type NavigationContextType = {
  setIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  navigateTo: (navigateData: CustomNavigateProps) => Promise<boolean>;
};

interface CustomNavigateProps {
  url?: string;
  replace?: boolean;
  state?: any;
  relative?: boolean;
  isNavigating?: boolean;
  ctrlClicked?: boolean;
}

const NavigationContext = createContext<NavigationContextType | null>(null);

export const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const swal = useSwal();
  const navigate = useNavigate();
  const location = useLocation();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const unsavedChangesPopup = async () => {
    const swalOptions: SweetAlertOptions<any, any> = {
      icon: "warning",
      title: "Your changes will not be saved!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue",
      cancelButtonText: `Cancel`,
    };
    const resp = await swal.fire(swalOptions);
    return resp;
  };

  const navigateTo = async (navigateData: CustomNavigateProps) => {
    const {
      url,
      state = location.state,
      replace = false,
      relative = false,
      isNavigating = true,
      ctrlClicked = false,
    } = navigateData;
    if (ctrlClicked) {
      window.open(url);
      return true;
    }
    if (isNavigating && !url) return false;
    if (url && location.pathname === url) return false;
    if (isChanged) {
      const resp = await unsavedChangesPopup();
      if (resp.isConfirmed) {
        if (isNavigating && url) {
          navigate(url, {
            replace,
            state,
            relative: relative ? "path" : "route",
          });
        }
        setIsChanged(false);
        return true;
      }
    } else {
      if (isNavigating && url) {
        navigate(url, {
          replace,
          state,
          relative: relative ? "path" : "route",
        });
      }
      return true;
    }
    return false;
  };

  return (
    <NavigationContext.Provider value={{ setIsChanged, navigateTo }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
