import { ForgotPassword } from "../types/auth/ForgotPassword";
import { ResetPassword } from "../types/auth/ResetPassword";
import { ChangePassword } from "../types/auth/ChangePassword";
import { axiosWithAuth, axiosWithoutAuth } from "../utils/customAxios";
import { LoginUser } from "../types/auth/LoginUser";
import { TempTokenDto } from "../types/auth/TempTokenDto";
import { TempTokenResponse } from "../types/auth/TempTokenResponse";

const authService = {
  login: async (userInfo: LoginUser): Promise<string> => {
    const response = await axiosWithoutAuth.post("/login", userInfo);
    const tokenResponse = response.data;

    return tokenResponse.token;
  },
  verifycredentials: async (tempUserToken: TempTokenDto): Promise<TempTokenResponse> => {
    const response = await axiosWithoutAuth.post(`/verify-credentials`, tempUserToken);
    const temptokenresponse:  TempTokenResponse = response.data
    return temptokenresponse;
  },
  logout: async (): Promise<boolean> => {
    const response = await axiosWithAuth.post("/logout");
    if (response.data) {
      return true;
    } else {
      return false;
    }
  },

  userForgotPassword: async (forgotPasswordData: ForgotPassword) => {
    axiosWithoutAuth.post("/forgotpassword", forgotPasswordData);
  },

  userResetPassword: async (
    resetPasswordData: ResetPassword
  ): Promise<string> => {
    const response = await axiosWithoutAuth.post(
      "/resetpassword",
      resetPasswordData
    );
    return response.data;
  },

  userChangePassword: async (
    changePasswordData: ChangePassword
  ): Promise<string> => {
    const response = await axiosWithAuth.post(
      "/changepassword",
      changePasswordData
    );
    return response.data;
  },
};

export default authService;
