import { ParticipantNumber, UpdateParticipantNumber } from "../pages/settings/system-settings/recording-policy/RecordingPolicySettings";
import { axiosWithAuth } from "../utils/customAxios";

const recordingPolicySettingsService = {
  
  getRecordingPolicyData: async (customerId?: number): Promise<ParticipantNumber[]> => {
    try {
      const response = await axiosWithAuth.get(`Customer/${customerId}/ExcludedNumbers`);
      const excludedNumbers: ParticipantNumber[] = response.data;
      return excludedNumbers;
    } catch (ex) {
      throw ex;
    }
  },

  addParticipantNumber: async (insertRecordingPolicyVal:string[],customerId?: number): Promise<ParticipantNumber[]> => {
    try {
      const response = await axiosWithAuth
      .post(
        `/Customer/${customerId}/ExcludedNumbers`,
        insertRecordingPolicyVal
      );
      const participantNumbers: ParticipantNumber[] = response.data;
      return participantNumbers;
    } catch (ex) {
      throw ex;
    }
  },

  updateParticipantNumber: async (updateRecordingPolicyVal:UpdateParticipantNumber,participantNumberId?:number,customerId?: number): Promise<ParticipantNumber> => {
    try {
      const response = await axiosWithAuth
      .put(
        `/Customer/${customerId}/ExcludedNumbers/${participantNumberId}`,
        updateRecordingPolicyVal
      )
      const participantNumber: ParticipantNumber = response.data;
      return participantNumber;
    } catch (ex) {
      throw ex;
    }
  },

  deleteParticipantNumber: async (participantNumberId:number,customerId?: number) => {
    try {
      await axiosWithAuth
      .delete(
        `/Customer/${customerId}/ExcludedNumbers/${participantNumberId}`
      )
    } catch (ex) {
      throw ex;
    }
  },

 };

export default recordingPolicySettingsService;
