import { AuthorizationProfile } from "../types/authorization-profile/AuthorizationProfile";
import { UpsertAuthorizationProfile } from "../types/authorization-profile/UpsertAuthorizationProfile";
import { axiosWithAuth } from "../utils/customAxios";

const authorizationProfileService = {
  getAuthorizationProfiles: async (): Promise<AuthorizationProfile[]> => {
    try {
      const response = await axiosWithAuth.get(`/authorizationProfile`);
      const authorizationProfile: AuthorizationProfile[] = response.data;
      return authorizationProfile;
    } catch (ex) {
      throw ex;
    }
  },

  upsertAuthorizationProfile: async (
    upsertAuthProfile: UpsertAuthorizationProfile,
    id: number
  ): Promise<AuthorizationProfile> => {
    try {
      if (id > 0) {
        const response = await axiosWithAuth.put(
          `/authorizationProfile/${id}`,
          upsertAuthProfile
        );
        const upsertedProfile: AuthorizationProfile = response.data;
        return upsertedProfile;
      } else {
        const response = await axiosWithAuth.post(
          `/authorizationProfile`,
          upsertAuthProfile
        );
        const upsertedProfile: AuthorizationProfile = response.data;
        return upsertedProfile;
      }
    } catch (ex) {
      throw ex;
    }
  },

  deleteAuthorizationProfile: async (id: number): Promise<number> => {
    try {
      const response = await axiosWithAuth.delete(
        `/authorizationProfile/${id}`
      );
      return response.data;
    } catch (ex) {
      throw ex;
    }
  },

  getfreezeprofile : async () : Promise<string> => {
    try{
      const response = await axiosWithAuth.get(
        `/systemsetting/freezeprofile`
      );

      return response.data.message;
    }
    catch(ex){
      throw ex;
    }
  }
};

export default authorizationProfileService;
