import { axiosWithoutAuth } from "../utils/customAxios";

const userNameRegexService = {
  fetchUserNameRegex: async (): Promise<RegExp> => {
    try {
      const response = await axiosWithoutAuth.get('SystemSetting/GetUserNameRegex');
      const regexString: string = response.data;
      let regex: RegExp = new RegExp('')
      try{
        regex = new RegExp(regexString);
      }catch(err){
        console.log('Error while converting string to RegExp')
      }
      
      return regex;
    } catch (err) {
      throw err;
    }
  },
};

export default userNameRegexService;
