import { Loader } from "@progress/kendo-react-indicators";
import { useEffect, useState } from "react";
import ContentPlaceholder from "../../components/ContentPlaceholder";
import AddRecordingComment from "../../components/recording-comments/AddRecordingComment";
import RecordingCommentsList from "../../components/recording-comments/RecordingCommentsList";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import { Comment } from "../../types/recording";
import { RegionSelection } from "./CallDetails";
import "./RecordingComments.scss";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";
import usersService from "../../services/users.service";
import { TinyUser } from "../../types/user";
import useMasterData from "../../hooks/useMasterData";
import useAuth from "../../hooks/useAuth";

interface TinyUserSelect extends TinyUser {
  checked?: boolean;
}

interface RecordingCommentsProps {
  comments?: Comment[];
  error?: string;
  recordingId: number;
  regionSelection: RegionSelection;
  recordingDuration: number;
  audioLoaded: boolean;
  disableCommentPlay: boolean;
  stopCommentPlay: boolean;
  playedCommentId: number;
  shared?: boolean;
  onCommentMouseOver: (commentId: number) => void;
  onCommentMouseOut: () => void;
  onAddComment: (comment: Comment) => void;
  onDeleteComment: (commentId: number) => void;
  onUpdateComment: (comment: Comment) => void;
  onUserCommentPlay: (comment: Comment) => void;
  onUserCommentStop: () => void;
  platformCallDetailId?: string;
  recordingPath: string;
  baseLicenseId?: number;
  totalCommentsTemp?: number;
  setTotalCommentsTemp: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

const RecordingComments: React.FC<RecordingCommentsProps> = ({
  comments,
  error,
  recordingId,
  regionSelection,
  recordingDuration,
  audioLoaded,
  disableCommentPlay,
  stopCommentPlay,
  playedCommentId,
  shared,
  onCommentMouseOver,
  onCommentMouseOut,
  onAddComment,
  onDeleteComment,
  onUpdateComment,
  onUserCommentPlay,
  onUserCommentStop,
  platformCallDetailId,
  recordingPath,
  baseLicenseId,
  totalCommentsTemp,
  setTotalCommentsTemp,
}) => {
  const auth = useAuth();
  const master = useMasterData();
  const localeCtx = useLocale();
  const swal = useSwal();
  const [internalUsers, setInternalUsers] = useState<TinyUserSelect[]>([]);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["RecordingComment"]
  );

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "RecordingComment"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const fetchInternalUsers = async () => {
      try {
        var users = await usersService.fetchUsers();
        master?.setStandardUsers(users);
        users = users.filter((obj) => {
          return obj.loginEnabled == true && obj.isArchived == false;
        });
        var tinyUsers = users.map((user) => {
          let obj: TinyUserSelect = {
            id: user.id,
            loginUserId: user.loginUserId,
            firstName: user.firstName ?? "",
            lastName: user.lastName ?? "",
            email: user.email,
            phoneNo: user.phoneNo,
            image: user.image,
            isArchived: user.isArchived,
            checked: false,
          };
          return obj;
        });
        setInternalUsers(tinyUsers);
      } catch (err) {
        setInternalUsers([]);
      }
    };
    if (!shared || (shared && auth?.accessToken)) {
      fetchInternalUsers();
    }
  }, [shared]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "RecordingComment"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "RecordingComment"
        ]
      );
      localeCtx?.setPreviousAppLocale("RecordingComment");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  return (
    <div className="row p-t-2">
      {!comments && (
        <div className="col-md-7">
          <div className="chat-bx">
            <div className="chat-conversation">
              <ContentPlaceholder>
                {!error && <Loader type={"infinite-spinner"} />}
                {error && (
                  <span>
                    <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                    {`${
                      translationsLoading
                        ? "Uh Oh! Something went wrong. Please try again..."
                        : fetchLabelKeyTranslation(
                            "SwtAltAddedFailedText",
                            "Uh Oh! Something went wrong. Please try again..."
                          )
                    }`}
                  </span>
                )}
              </ContentPlaceholder>
            </div>
          </div>
        </div>
      )}
      {comments && (
        <RecordingCommentsList
          recordingId={recordingId}
          comments={comments}
          onDeleteComment={onDeleteComment}
          onUpdateComment={onUpdateComment}
          audioLoaded={audioLoaded}
          disableCommentPlay={disableCommentPlay}
          stopCommentPlay={stopCommentPlay}
          playedCommentId={playedCommentId}
          onCommentMouseOver={onCommentMouseOver}
          onCommentMouseOut={onCommentMouseOut}
          onUserCommentPlay={onUserCommentPlay}
          onUserCommentStop={onUserCommentStop}
          shared={shared}
          translationsLoading={translationsLoading}
          fetchLabelKeyTranslation={fetchLabelKeyTranslation}
          platformCallDetailId={platformCallDetailId}
          internalUsers={internalUsers}
          recordingPath={recordingPath}
          setTotalCommentsTemp={setTotalCommentsTemp}
          totalCommentsTemp={totalCommentsTemp}
        />
      )}
      <AddRecordingComment
        recordingId={recordingId}
        regionSelection={regionSelection}
        recordingDuration={recordingDuration}
        onAddComment={onAddComment}
        shared={shared}
        translationsLoading={translationsLoading}
        fetchLabelKeyTranslation={fetchLabelKeyTranslation}
        platformCallDetailId={platformCallDetailId}
        internalUsers={internalUsers}
        recordingPath={recordingPath}
        setTotalCommentsTemp={setTotalCommentsTemp}
        totalCommentsTemp={totalCommentsTemp}
        baseLicenseId={baseLicenseId}
      />
    </div>
  );
};

export default RecordingComments;
