import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import { CallDetailTabEnum } from "../../../enums/callDetailTabEnum";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
  handleCallDetailNavigation: (callDetail: CallDetail, tab: CallDetailTabEnum) => void;
}

const CustomGridCellForCallOutcome = (props: CustomGridCellProps) => {
  return (
    <td className="mx-th-tag">
      <div
        className="mx-td-spn line-height-2 cursor-pointer"
        style={{ wordBreak: "break-word" }}
        onClick={()=>props.handleCallDetailNavigation(props.dataItem, CallDetailTabEnum.Outcome)}
      >
        {props.dataItem.callResult?.name}
      </div>
    </td>
  );
};

export default CustomGridCellForCallOutcome;
