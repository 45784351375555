export interface InsightKeysData{
    Directions:string;
    Received:string;
    Dialled:string;
    Transcribed:string;
    Transcription:string;
    Evaluation:string;
    Total:string;
    Good:string;
    Average:string;
    Poor:string;
    Sentiments:string;
    Positive:string;
    Neutral:string;
    Negative:string;
    Flags:string;
    Red:string;
    Green:string;
    Yellow:string;
    Blue:string;
    Orange:string;
    Topics:string;
    CallResults:string;
    Hour:string;
    Day:string;
    Week:string;
    Month:string;
    Users:string;
    Evaluators:string;
    'Call Outcomes':string;
}

export const insightKeys:InsightKeysData={
    Directions:"KeyDirections",
    Transcription:"KeyTranscription",
    Evaluation:"KeyEvaluation",
    Sentiments:"KeySentiments",
    Flags:"KeyFlags",
    Topics:"KeyTopics",
    CallResults:"KeyCallResults",
    Received:"ValueReceived",
    Dialled:"ValueDialled",
    Transcribed:"ValueTranscribed",
    Total:"ValueTotal",
    Good:"ValueGood",
    Average:"ValueAverage",
    Poor:"ValuePoor",
    Positive:"ValuePositive",
    Neutral:"ValueNeutral",
    Negative:"ValueNegative",
    Red:"ValueRed",
    Green:"ValueGreen",
    Yellow:"ValueYellow",
    Blue:"ValueBlue",
    Orange:"ValueOrange",
    Hour:"ValueHour",
    Day:"ValueDay",
    Week:"ValueWeek",
    Month:"ValueMonth",
    Users:"ValueUsers",
    Evaluators:"ValueEvaluators",
    'Call Outcomes':"ValueCallOutcomes"
}