import { axiosWithoutAuth } from "../utils/customAxios";

const demoInstanceService = {
  fetchIsDemoInstance: async (): Promise<boolean> => {
    try {
      const response = await axiosWithoutAuth.get('systemSetting/IsDemoInstance');
      return response.data.message === "true";
    } catch (err) {
      throw err;
    }
  },
};

export default demoInstanceService;
