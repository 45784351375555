import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input } from "@progress/kendo-react-inputs";
import { Buffer } from 'buffer';
import { TinyUser } from "../../types/user";
import useLocale from "../../hooks/useLocale";
import { Customer, CustomerLicensePackageCount, PlatformDetail } from "../../types/customer";
import React from "react";
import useAuth from "../../hooks/useAuth";
import customerService from "../../services/customer.service";
import { AxiosError } from "axios";
import { CustomerPlatformStatus } from "../../types/customer/CustomerPlatformStatus";
import { Loader } from "@progress/kendo-react-indicators";
import { Link, useSearchParams } from "react-router-dom";
import { appUrl } from "../..";
import { GraphUrlStateDetails } from "../../types/customer/GraphUrlStateDetails";
import { MsTeamsProvisioningActionEnum } from "../../enums/MsTeamsProvisioningActionEnum";
import { DeviceAllocation } from "../../types/device/deviceAllocation";
import deviceService from "../../services/device.service";
import CustomUserGridInfo from "../../components/custom/grid/CustomUserGridInfo";
import useSessionStorage from "../../hooks/useSessionStorage";
import useTranslation from "../../hooks/useTranslation";
import { MsTeamsUserPolicyDetail } from "../../types/localstorage-filters/MsTeamsUserPolicyDetail";

interface MsTeamsProps {
  platformDetail: PlatformDetail
}

const adminConsentUrlTemplate = "https://login.microsoftonline.com/common/adminconsent?client_id={client_id}&redirect_uri={redirect_uri}&scope={scope}&state={state}";
const authCodeUrlTemplate = "https://login.microsoftonline.com/{tenant_id}/oauth2/v2.0/authorize?client_id={client_id}&response_type=code&redirect_uri={redirect_uri}&response_mode=query&scope={scope}&state={state}";

const MsTeams: React.FC<MsTeamsProps> = ({ platformDetail }) => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const trans = useTranslation("CustomerPlatformMsTeams");
  const [msTeamsRecordingPolicyUsers, setMsTeamsRecordingPolicyUsers] = useSessionStorage<MsTeamsUserPolicyDetail[] | undefined>(
    "MsTeamsRecordingPolicyUsers",
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(false);
  const [platformStatus, setPlatformStatus] = useState<CustomerPlatformStatus | null>(null);
  const [msTeamsDevices, setMsTeamsDevices] = useState<DeviceAllocation[]>([]);
  const [customerInfo, setCustomerInfo] = useState<Customer | undefined>(undefined);
  const [isPolicyAssignmentValid, setIsPolicyAssignmentValid] = useState<boolean>(true);
  const [assignedDeviceSearch, setAssignedDeviceSearch] = useState<string>("");
  const [unassignedDeviceSearch, setUnassignedDeviceSearch] = useState<string>("");
  const [onboardingStep, setOnboardingStep] = useState<number>(1);
  const [userDialogVisible, setUserDialogVisible] = useState<boolean>(false);
  const [adminConsentUrl, setAdminConsentUrl] = useState<string | null>(null);
  const [authCodeUrl, setAuthCodeUrl] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const actionType = searchParams.get("actionType");

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["CustomerPlatformMsTeams"]) {
      trans.fetchTranslations("CustomerPlatformMsTeams")
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getCustomerInfo();
    getCustomerPlatformStatus();
    getDevices();
  }, []);

  useEffect(() => {
    checkIsPolicyAssignmentValid();
  }, [customerInfo, msTeamsDevices]);

  const getCustomerPlatformStatus = async () => {
    setLoading(true);
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        const _platformStatus = await customerService.getCustomerPlatformStatus(customerId, "MsTeams");
        setPlatformStatus(_platformStatus);
        if (_platformStatus.platformStatusHistory.find(history => history.statusName === "MsAdminConsentGranted") === undefined) {

          if (_platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
            _platformStatus.customerOnboardingDetail.msTeamsAdminConsentUrlScope !== undefined) {
            let urlState: GraphUrlStateDetails = {
              customerId: customerId,
              platform: "MsTeams",
              msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.GrantMsAdminConsent
            };
            let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
            let _adminConsentUrl = adminConsentUrlTemplate;
            _adminConsentUrl = _adminConsentUrl.replace("{client_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.azureAppClientId));
            _adminConsentUrl = _adminConsentUrl.replace("{scope}", encodeURIComponent(_platformStatus.customerOnboardingDetail.msTeamsAdminConsentUrlScope));
            _adminConsentUrl = _adminConsentUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + '/customer-platform/admin-action'));
            _adminConsentUrl = _adminConsentUrl.replace("{state}", encodeURIComponent(encodedUrlState));
            setAdminConsentUrl(_adminConsentUrl);
          }
          setOnboardingStep(1);
        }
        else if (_platformStatus.platformStatusHistory.find(history => history.statusName === "TeamsRecordingPolicyCreated") === undefined) {
          if (_platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
            _platformStatus.customerOnboardingDetail.tenantGUID !== undefined &&
            _platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope !== undefined) {
            let urlState: GraphUrlStateDetails = {
              customerId: customerId,
              platform: "MsTeams",
              msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.CreateRecordingPolicy
            };
            let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
            let _authCodeUrl = authCodeUrlTemplate;
            _authCodeUrl = _authCodeUrl.replace("{tenant_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.tenantGUID));
            _authCodeUrl = _authCodeUrl.replace("{client_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.azureAppClientId));
            _authCodeUrl = _authCodeUrl.replace("{scope}", encodeURIComponent(_platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope));
            _authCodeUrl = _authCodeUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + '/customer-platform/admin-action'));
            _authCodeUrl = _authCodeUrl.replace("{state}", encodeURIComponent(encodedUrlState));
            setAuthCodeUrl(_authCodeUrl);
          }
          setOnboardingStep(2);
        }
        else if (_platformStatus.platformStatusHistory.find(history => history.statusName === "RecordingPolicyAssigned") === undefined) {
          setOnboardingStep(3);
        }
        else {
          setOnboardingStep(4);
        }
        if(actionType==="assign-license" && _platformStatus.platformStatusHistory.find((history)=>history.statusName==="PlatformActive")){
          toggleUserDialog();
        }
      } catch (err) {
        console.log(err);
        if (err instanceof AxiosError) {
          setPlatformStatus(null);
        }
      }
      finally {
        setLoading(false);
      }
    }
  }

  const getDevices = async () => {
    setDevicesLoading(true);
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        const _devices = await deviceService.getAllCustomerDevice("MsTeams");
        setMsTeamsDevices(_devices);
      } catch (err) {
        setMsTeamsDevices([]);
      }
      finally {
        setDevicesLoading(false);
      }
    }
  }

  const getCustomerInfo = async () => {
    var customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        var customer = await customerService.getCustomerDetailsById(customerId);
        setCustomerInfo(customer);
      } catch (ex) {
        console.log(ex);
      } finally {
      }
    }
  };

  const checkIsPolicyAssignmentValid = () => {
    if(customerInfo && customerInfo.licensePackages && customerInfo.licensePackages.length > 0){
      let licenseInfo = customerInfo.licensePackages;
      if(licenseInfo.findIndex(l => l.isAutoLearnEnabled === true) !== -1){
        setIsPolicyAssignmentValid(true)
      }
      else{
        let totalLicenseCount = licenseInfo.map(l => {return l.purchased}).reduce((partialSum, a) => partialSum + a, 0);
        let consumedLicense = msTeamsDevices.filter(d => d.device.deviceDetails.isRecordingPolicyAssigned).length;
        setIsPolicyAssignmentValid(consumedLicense <= totalLicenseCount)
      }
    }else{
      setIsPolicyAssignmentValid(false);
    }
  }

  const toggleUserDialog = () => {
    getDevices();
    setUserDialogVisible(!userDialogVisible);
  };

  const searchDevice = (deviceDetails: DeviceAllocation, searchTerm: string) => {
    var searchText = searchTerm.trim().toLocaleLowerCase();
    if (
      deviceDetails.device.deviceDetails.msTeamsEmail?.toLocaleLowerCase().includes(searchText) ||
      deviceDetails.device.deviceDetails.msTeamsGivenName?.toLocaleLowerCase().includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleUnassignedDeviceSearchChange = (event: any) => {
    setUnassignedDeviceSearch(event.value);
  };

  const handleAssignedDeviceSearchChange = (event: any) => {
    setAssignedDeviceSearch(event.value);
  };

  const handleSelectAll = () => {
    let _devices: DeviceAllocation[] = [];
    _devices = msTeamsDevices.map(d => {
      d.device.deviceDetails.isRecordingPolicyAssigned = true;
      return d
    });
    setMsTeamsDevices(_devices);
  }

  const handleRemoveAll = () => {
    let _devices: DeviceAllocation[] = [];
    _devices = msTeamsDevices.map(d => {
      d.device.deviceDetails.isRecordingPolicyAssigned = false;
      return d
    });
    setMsTeamsDevices(_devices);
  }

  const handleDeviceSelection = (device: DeviceAllocation) => {
    let _devices: DeviceAllocation[] = [];
    msTeamsDevices.map(d => {
      if (d.device.deviceIdentifier === device.device.deviceIdentifier) {
        d.device.deviceDetails.isRecordingPolicyAssigned = true;
        _devices.push(d);
      }
      else {
        _devices.push(d);
      }
    });
    setMsTeamsDevices(_devices);
  }

  const handleDeviceRemove = (device: DeviceAllocation) => {
    let _devices: DeviceAllocation[] = [];
    msTeamsDevices.map(d => {
      if (d.device.deviceIdentifier === device.device.deviceIdentifier) {
        d.device.deviceDetails.isRecordingPolicyAssigned = false;
        _devices.push(d);
      }
      else {
        _devices.push(d);
      }
    });
    setMsTeamsDevices(_devices);
  }

  const getDefaultLicenseName = (licenseInfo: CustomerLicensePackageCount[]) => {
    let _licenseInfo = licenseInfo.filter(l => l.purchased > 0).sort((l1, l2) => {
      let l1Storage: number = 0;
      let l2Storage: number = 0;
      switch(l1.storageDuration.durationType.toLocaleLowerCase()){
        case "day":{
          l1Storage = l1.storageDuration.duration;
          break;
        }
        case "month": {
          l1Storage = l1.storageDuration.duration * 30;
          break;
        }
        case "year": {
          l1Storage = l1.storageDuration.duration * 365;
          break;
        }
      }
      switch(l2.storageDuration.durationType.toLocaleLowerCase()){
        case "day":{
          l2Storage = l2.storageDuration.duration;
          break;
        }
        case "month": {
          l2Storage = l2.storageDuration.duration * 30;
          break;
        }
        case "year": {
          l2Storage = l2.storageDuration.duration * 365;
          break;
        }
      }
      return l1.license.isAIEnabled === l2.license.isAIEnabled ? (l1Storage > l2Storage ? -1 : 1) : (l1.license.isAIEnabled === true ? -1 : 1);
    })
    return _licenseInfo[0];
  }

  const getMsTeamsUserPolicyDetail = () => {
    let _msTeamsUserPolicyDetail : MsTeamsUserPolicyDetail[] = [];
    if(customerInfo && customerInfo.licensePackages && customerInfo.licensePackages.length > 0){
      let _licenseInfo = [...customerInfo?.licensePackages];
      let _autoLearnLicense = _licenseInfo.find(l => l.isAutoLearnEnabled === true);
      let _users = msTeamsDevices.filter(d => d.device.deviceDetails.isRecordingPolicyAssigned);
      _users.forEach(u => {
        let _userDetail : MsTeamsUserPolicyDetail = {
          userEmail: u.device.deviceDetails.msTeamsEmail ?? "",
          licenseName: "",
          policyType: ""
        };
        if(u.licensePackage){
          _userDetail.licenseName = u.licensePackage.name;
        }else{
          if(_autoLearnLicense){
            _userDetail.licenseName = _autoLearnLicense.name;
          }
          else{
            let _license = getDefaultLicenseName(_licenseInfo);
            _userDetail.licenseName = _license.name;
            let _licenseIndex = _licenseInfo.findIndex(l => l.name === _license.name);
            _licenseInfo[_licenseIndex].purchased = _license.purchased - 1;
          }
        }
        _msTeamsUserPolicyDetail.push(_userDetail);
      });
    }
    return _msTeamsUserPolicyDetail;
  }

  const grantMsTeamsAdminConsent = async () => {
    if (adminConsentUrl !== null) {
      window.location.replace(adminConsentUrl);
    }
  }

  const createMsTeamsRecordingPolicy = async () => {
    if (authCodeUrl !== null) {
      window.location.replace(authCodeUrl);
    }
  }

  const updatePolicyAssignment = async () => {
    if(!isPolicyAssignmentValid){
      return;
    }
    const customerId = auth?.tokenPayload?.CustomerId;
    if (customerId && platformStatus && platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
      platformStatus.customerOnboardingDetail.tenantGUID !== undefined &&
      platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope !== undefined) {
      let _userDetail = getMsTeamsUserPolicyDetail();
      setMsTeamsRecordingPolicyUsers(_userDetail);
      let urlState: GraphUrlStateDetails = {
        customerId: customerId,
        platform: "MsTeams",
        msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.AssignRecordingPolicyToUsers
      };
      let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
      let _authCodeUrl = authCodeUrlTemplate;
      _authCodeUrl = _authCodeUrl.replace("{tenant_id}", encodeURIComponent(platformStatus.customerOnboardingDetail.tenantGUID ?? ""));
      _authCodeUrl = _authCodeUrl.replace("{client_id}", encodeURIComponent(platformStatus.customerOnboardingDetail.azureAppClientId));
      _authCodeUrl = _authCodeUrl.replace("{scope}", encodeURIComponent(platformStatus.customerOnboardingDetail.msTeamsAccessCodeUrlScope));
      _authCodeUrl = _authCodeUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + '/customer-platform/admin-action'));
      _authCodeUrl = _authCodeUrl.replace("{state}", encodeURIComponent(encodedUrlState));
      if (_authCodeUrl !== null) {
        window.location.replace(_authCodeUrl);
      }
    }
  }

  return (
    loading ? (
      <div className="col-md-12 d-flex justify-content-center align-items-center">
        <Loader type={"infinite-spinner"} />
      </div>
    ) : (
      <div>
        <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
          <span className="text-primary">MsTeams Platform</span>
        </div>
        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
          <div className="trkCol">
            <div className="trkCol-h font-weight-semi">{`${trans?.translationsLoading
              ? "Tenant Id"
              : trans?.fetchLabelKeyTranslation("FieldTenantId", "Tenant Id")
              }`}</div>
            <div className="trkCol-dot">:</div>
            <div className="trkCol-p">{platformDetail.customerDetail.msTeamsTenantsGuid}</div>
          </div>
        </div>
        <div className="trk-row border-bottom-solid border-w-1 border-black-1">
          <div className="trkCol">
            <div className="trkCol-h font-weight-semi">{`${trans?.translationsLoading
              ? "Admin Email"
              : trans?.fetchLabelKeyTranslation("FieldAdminEmail", "Admin Email")
              }`}</div>
            <div className="trkCol-dot">:</div>
            <div className="trkCol-p">{platformDetail.customerDetail.msTeamsAdminEmailAddress}</div>
          </div>
        </div>
        <div className="row p-t-20">
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <div className="stepMain">
              <div className="stepFlow">
                <div className="stepFlowTitle float-left w-100">
                  <span className="trk-title text-primary">
                    Setup Call Recording
                  </span>
                </div>
                <div className="stepFlowGroup">
                  <div className="stepFlowRow">
                    <div className={`stepFlowCol ${onboardingStep === 1 ? "step-current" : (onboardingStep > 1 ? "step-active" : "")}`}>
                      <div className="stepColumn">
                        <div className="stepCircle">
                          <div className="stepCircleBdr">
                            <div className="stepCircleBox">
                              <div className="stepCircleIcon">
                                {onboardingStep > 1 && <i className="bi bi-check-lg"></i>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="stepLabelRow">
                          <div className="stepRowText">
                            <div className="stepLabel">
                              Microsoft Teams Admin Consent
                            </div>
                            <div className="stepLabelDetails">
                            Microsoft Teams admin consent refers to the process by which administrators grant permissions for Recording Bot applications or services to access and integrate with Microsoft Teams on behalf of your organization. Recording Bot applications require specific permissions to interact with Microsoft Teams APIs and access certain features or data.
                            </div>
                          </div>
                          {onboardingStep === 1 &&
                            <div className="stepBtn float-right d-flex align-items-center">
                              <Button
                                className={`btn bg-primary text-white`}
                                onClick={grantMsTeamsAdminConsent}
                              >
                                Grant Consent
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`stepFlowCol ${onboardingStep === 2 ? "step-current" : (onboardingStep > 2 ? "step-active" : "")}`}>
                      <div className="stepColumn">
                        <div className="stepCircle">
                          <div className="stepCircleBdr">
                            <div className="stepCircleBox">
                              <div className="stepCircleIcon">
                                {onboardingStep > 2 && <i className="bi bi-check-lg"></i>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="stepLabelRow">
                          <div className="stepRowText">
                            <div className="stepLabel">
                              Create Recording policy for your organization
                            </div>
                            <div className="stepLabelDetails">
                            Create recording bot user for your organization to attend calls as silent user to record calls, and create Microsoft Teams recording policy for your organization to assign to users.
                            </div>
                          </div>
                          {onboardingStep === 2 &&
                            <div className="stepBtn float-right d-flex align-items-center">
                              <Button
                                className={`btn bg-primary text-white`}
                                onClick={createMsTeamsRecordingPolicy}
                              >
                                Create Policy
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <div className={`stepFlowCol ${onboardingStep === 3 ? "step-current" : (onboardingStep > 3 ? "step-active" : "")}`}>
                      <div className="stepColumn">
                        <div className="stepCircle">
                          <div className="stepCircleBdr">
                            <div className="stepCircleBox">
                              <div className="stepCircleIcon">
                                {onboardingStep > 3 && <i className="bi bi-check-lg"></i>}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="stepLabelRow">
                          <div className="stepRowText">
                            <div className="stepLabel">
                              Select Users to be Recorded
                            </div>
                            <div className="stepLabelDetails">
                              Manage recorded users by assigning or removing Microsoft Teams recording policy.
                            </div>
                          </div>
                          {onboardingStep >= 3 &&
                            <div className="stepBtn float-right d-flex align-items-center">
                              <Button className="btn bg-primary text-white" onClick={toggleUserDialog}>
                                {onboardingStep === 3 ? "Select Users"
                                : `Recorded Users (${msTeamsDevices.filter(d => d.device.deviceDetails.isRecordingPolicyAssigned).length})`
                                }
                              </Button>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stepLine"></div>
                </div>
                {userDialogVisible && (
                  <Dialog
                    title={"Select Users to be Recorded"}
                    onClose={
                      toggleUserDialog
                    }
                  >
                    {devicesLoading ?
                      <div className="col-md-12 d-flex justify-content-center align-items-center">
                        <Loader type={"infinite-spinner"} />
                      </div>
                      :
                      <>
                        <div
                          className="userListingData"
                          style={{
                            maxWidth:
                              "720px",
                          }}
                        >
                          <div className="row">
                            <div className="col-md-6 m-b-10">
                              <div className="userListingDataCol">
                                <div className="userListingTitle fs-14 text-black-10 font-weight-bold p-b-4 d-flex align-items-center justify-content-between">
                                  <span>Unassigned Users</span>
                                  <Button
                                    className="btn bg-black-5 fs-13 p-t-3 p-b-3"
                                    onClick={handleSelectAll}
                                  >
                                    Select All
                                  </Button>
                                </div>
                                <div className="UserLiSearch p-b-5">
                                  <div className="formInput">
                                    <Input
                                      className="form-control"
                                      style={{ height: "32px", background: "rgba(255,255,255,.6)" }}
                                      placeholder="Search"
                                      value={unassignedDeviceSearch}
                                      onChange={handleUnassignedDeviceSearchChange}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="detailColBox userListGroup"
                                  style={{
                                    height:
                                      "325px",
                                    overflow:
                                      "hidden auto",
                                  }}
                                >
                                  <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                    {msTeamsDevices.filter((device) => !device.device.deviceDetails.isRecordingPolicyAssigned && searchDevice(device, unassignedDeviceSearch)).map(device => {
                                      var _user: TinyUser = {
                                        id: device.device.deviceDetails?.id ?? 0,
                                        loginUserId: device.user?.loginUserId ?? 0,
                                        firstName: device.device.deviceDetails.msTeamsGivenName ?? "",
                                        lastName: device.device.deviceDetails.msTeamsSurname ?? "",
                                        email: device.device.deviceDetails.msTeamsEmail ?? "",
                                        phoneNo: "",
                                        image: device.user?.image ?? "",
                                        isArchived: device.user?.isArchived ?? false
                                      };
                                      return (
                                        <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                          <div className="tblUsr d-flex justify-content-between w-100">
                                            <CustomUserGridInfo userInfo={_user} />
                                            <div className="userActionBtn p-r-10">
                                              <a
                                                className="cursor-pointer"
                                                onClick={() => handleDeviceSelection(device)}
                                              >
                                                <span
                                                  className="fs-25 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                                  style={{
                                                    height: "30px",
                                                    width: "30px",
                                                  }}
                                                  title={`${trans?.translationsLoading
                                                    ? "Add"
                                                    : trans?.fetchLabelKeyTranslation(
                                                      "AddButton",
                                                      "Add"
                                                    )
                                                    }`}
                                                >
                                                  <i className="bi bi-arrow-right fs-16"></i>
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 m-b-10">
                              <div className="userListingDataCol">
                                <div className="userListingTitle fs-14 text-black-10 font-weight-bold p-b-4 d-flex align-items-center justify-content-between">
                                  <span>Assigned Users</span>
                                  <Button
                                    className="btn bg-black-5 fs-13 p-t-3 p-b-3"
                                    onClick={handleRemoveAll}
                                  >
                                    Remove All
                                  </Button>
                                </div>
                                <div className="UserLiSearch p-b-5">
                                  <div className="formInput">
                                    <Input
                                      className="form-control"
                                      style={{ height: "32px", background: "rgba(255,255,255,.6)" }}
                                      placeholder="Search"
                                      value={assignedDeviceSearch}
                                      onChange={handleAssignedDeviceSearchChange}
                                    />
                                  </div>
                                </div>
                                <div
                                  className="detailColBox userListGroup"
                                  style={{
                                    height:
                                      "325px",
                                    overflow:
                                      "hidden auto",
                                  }}
                                >
                                  <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                    {msTeamsDevices.filter((device) => device.device.deviceDetails.isRecordingPolicyAssigned && searchDevice(device, assignedDeviceSearch)).map(device => {
                                      var _user: TinyUser = {
                                        id: device.device.deviceDetails?.id ?? 0,
                                        loginUserId: device.user?.loginUserId ?? 0,
                                        firstName: device.device.deviceDetails.msTeamsGivenName ?? "",
                                        lastName: device.device.deviceDetails.msTeamsSurname ?? "",
                                        email: device.device.deviceDetails.msTeamsEmail ?? "",
                                        phoneNo: "",
                                        image: device.user?.image ?? "",
                                        isArchived: device.user?.isArchived ?? false
                                      };
                                      return (
                                        <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
                                          <div className="tblUsr d-flex justify-content-between w-100">
                                            <CustomUserGridInfo userInfo={_user} />
                                            <div className="userActionBtn p-r-10">
                                              <a
                                                className="cursor-pointer"
                                                onClick={() => handleDeviceRemove(device)}
                                              >
                                                <span
                                                  className="fs-25 bg-black-4 text-primary radius-50 d-flex align-items-center justify-content-center"
                                                  style={{
                                                    height: "30px",
                                                    width: "30px",
                                                  }}
                                                  title={`${trans?.translationsLoading
                                                    ? "Remove"
                                                    : trans?.fetchLabelKeyTranslation(
                                                      "RemoveButton",
                                                      "Remove"
                                                    )
                                                    }`}
                                                >
                                                  <i className="bi bi-trash3 fs-16"></i>
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <DialogActionsBar>
                          {!isPolicyAssignmentValid && 
                            <div className="float-left fs-14 tx-red">
                              you do not have enough licences to record selected users!
                            </div>
                          }
                          <Button className="btn bg-black-5" onClick={toggleUserDialog}>
                            Cancel
                          </Button>
                          <Button 
                            className={`btn bg-primary text-white ${!isPolicyAssignmentValid ? "disabledBtn" : ""}`}
                            onClick={updatePolicyAssignment}
                            disabled={!isPolicyAssignmentValid}
                          >
                            Update
                          </Button>
                        </DialogActionsBar>
                      </>
                    }
                  </Dialog>
                )}
              </div>
            </div>
          </div>
          {onboardingStep > 3 && 
          <div className="col-md-12 d-flex justify-content-center align-items-center">
            <div className="fs-14 tx-green m-t-10 fw-bold">You are up and running! Recording is now active for calls with selected users. Feel free to close this browser window.</div>
          </div>
          }
        </div>
      </div>
    )
  );
};

export default MsTeams;
