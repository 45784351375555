interface ICurrentPlaceholderProps {
  children?: React.ReactNode;
}

const ContentPlaceholder: React.FC<ICurrentPlaceholderProps> = ({
  children,
}) => {
  return (
    <div>
      <div
        className="border-w-2 border-c-2 border-black-4 border-dashed radius-5 text-muted padding-6 d-flex align-items-center justify-content-center m-t-15"
        style={{ height: "238px" }}
      >
        {children}
      </div>
    </div>
  );
};

export default ContentPlaceholder;
