import { align } from "@progress/kendo-drawing";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import useLocale from "../../hooks/useLocale";
import useLocalStorage from "../../hooks/useLocalStorage";
import { SweetAlertOptions } from "sweetalert2";
import { Dictionary } from "../../types/Dictionary";
import useSwal from "../../hooks/useSwal";

const Footer: React.FC = () => {
  const localeCtx = useLocale();
  const swal = useSwal();
  const [userBehaviourTracking, setUserBehaviourTracking] = useLocalStorage<
    string | undefined
  >("UserBehaviourTracking", undefined);

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["Footer"]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("Footer");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["Footer"]
      );
      localeCtx?.setPreviousAppLocale("Footer");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        await swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["Footer"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const AddTrackingScript = () => {
    var userTrackingScriptTag = document.createElement("script");
    userTrackingScriptTag.setAttribute(
      "src",
      "https://cdn.pagesense.io/js/tollring/fd30445c804849179de522922709acda.js"
    );
    document.head.appendChild(userTrackingScriptTag);
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const allowUserTracking = () => {
    setUserBehaviourTracking("Allow");
    AddTrackingScript();
  };

  const denyUserTracking = () => {
    setUserBehaviourTracking("Deny");
  };

  useEffect(() => {
    if (userBehaviourTracking === "Allow") {
      AddTrackingScript();
    }
  }, []);

  return (
    <div>
      <table
        className="footerContent text-center"
        style={{
          background: "#fff",
          width: "100%",
        }}
      >
        {userBehaviourTracking === undefined && (
          <tr>
            <td style={{ width: "100%" }}>
              <span style={{ padding: "0 10px 0 0" }}>
                {`${translationsLoading
                  ? "Our website uses cookies to give you the best and most relevant experience. By clicking on accept, you give your consent to the use of cookies as per our privacy policy."
                  : fetchLabelKeyTranslation(
                    "ConsentText",
                    "Our website uses cookies to give you the best and most relevant experience.By clicking on accept, you give your consent to the use of cookies as per our privacy policy."
                  )
                  }`}
              </span>
              <span>
                <Button
                  className="btn bg-primary text-white m-r-10"
                  onClick={allowUserTracking}
                >
                  {`${translationsLoading
                    ? "Accept"
                    : fetchLabelKeyTranslation(
                      "AcceptButtonText",
                      "Accept"
                    )
                    }`}
                </Button>
                <Button className="btn bg-black-5" onClick={denyUserTracking}>
                  {`${translationsLoading
                    ? "Deny"
                    : fetchLabelKeyTranslation(
                      "DenyButtonText",
                      "Deny"
                    )
                    }`}
                </Button>
              </span>
            </td>
          </tr>
        )}
      </table>
    </div>
  );
};

export default Footer;
