import { TinyObj } from "../types/TinyObj";
import { TinyUser, User } from "../types/user";
import { UpsertUser } from "../types/user/UpsertUser";
import { UserAuthProfile } from "../types/user/UserAuthProfile";
import { UserRegister } from "../types/user/UserRegister";
import { axiosWithAuth } from "../utils/customAxios";

interface IdsInfo{
  ids:number[];
}

const usersService = {
  fetchUsers: async (name?: string): Promise<User[]> => {
    try {
      const route = name ? `/user${name && `?name=${name}`}` : `/user`;
      const response = await axiosWithAuth.get(route);
      const users: User[] = response.data;

      return users;
    } catch (err) {
      throw err;
    }
  },

  updateUser: async (user: UpsertUser, id: number): Promise<User> => {
    try {
      const route = `/user/${id}`;
      const response = await axiosWithAuth.put(route, user);
      const updatedUser: User = response.data;
      return updatedUser;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  deleteUser: async (id: number) => {
    try {
      const route = `/user/${id}`;
      const response = await axiosWithAuth.delete(route);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  updateUserLogin: async (id: number, loginEnabled: boolean) => {
    try {
      const route = `/user/${id}/updatelogin/${loginEnabled ? 'true' : 'false'}`;
      const response = await axiosWithAuth.put(route);
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },

  updateUserAuthProfile: async (
    userAuthProfiles: UserAuthProfile[]
  ): Promise<User[]> => {
    try {
      const route = `/user/authorizationprofile`;
      const response = await axiosWithAuth.put(route, userAuthProfiles);
      const updatedUsers: User[] = response.data;
      return updatedUsers;
    } catch (err) {
      throw err;
    }
  },

  updateRecordingNetworkViaGroup: async (
    userGroups: TinyObj[],
    userId: number
  ): Promise<User> => {
    try {
      const route = `/user/${userId}/recordingnetwork`;
      const response = await axiosWithAuth.put(route, userGroups);
      const updatedUser: User = response.data;
      return updatedUser;
    } catch (err) {
      throw err;
    }
  },

  getUser: async (userId: string): Promise<User> => {
    try {
      const route = `user/${userId}?features=maximum`;
      var response = await axiosWithAuth.get(route);
      const user = response.data;
      return user;
    } catch (err) {
      throw err;
    }
  },

  getCurrentUser: async (): Promise<User> => {
    const response = await axiosWithAuth.get("user/me?features=maximum");
    const currentUser: User = response.data;

    return currentUser;
  },

  getCurrentTinyUser: async (): Promise<TinyUser> => {
    const response = await axiosWithAuth.get("user/me?features=minimum");
    const currentUser: TinyUser = response.data;

    return currentUser;
  },

  getTinyUsers: async (
    userIds: string[]
  ): Promise<{ [key: string]: TinyUser }> => {
    const idsInfo:IdsInfo = {
      ids:userIds.map((id:string)=>Number(id))
    };
    const response = await axiosWithAuth.post('user/tiny',idsInfo);
    const tinyUsers: { [key: string]: TinyUser } = response.data;

    return tinyUsers;
  },

  insertUser: async (newUser: UpsertUser): Promise<User> => {
    try {
      const route = `/user`;
      var response = await axiosWithAuth.post(route, newUser);
      const user = response.data;

      return user;
    } catch (err) {
      throw err;
    }
  },

  getAllTinyUsers: async (): Promise<TinyUser[]> => {
    const response = await axiosWithAuth.get(`/user?features=minimum`);
    const users: TinyUser[] = response.data;

    return users;
  },
};

export default usersService;
