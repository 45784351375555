import { CallResult, CustomerExperience } from "../types/call";
import { ArchivableTinyObject } from "../types/ArchivableTinyObject";
import { axiosForShare, axiosWithAuth } from "../utils/customAxios";

const customerDefinedValuesService = {

  fetchCustomerDefinedCallResults: async (
    shared: boolean = false
  ): Promise<ArchivableTinyObject[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/callResult`);
      const callResults: CallResult[] = response.data;

      const resList: ArchivableTinyObject[] = callResults.map((item) => {
        const newRes: ArchivableTinyObject = {
          id: item.id,
          name: item.text,
          isArchived: item.isArchived,
        };
        return newRes;
      });

      return resList;
    } catch (err) {
      throw err;
    }
  },

  fetchCustomerDefinedCustomerExperiences: async (
    shared: boolean = false
  ): Promise<ArchivableTinyObject[]> => {
    try {
      const axiosInstance = shared === true ? axiosForShare : axiosWithAuth;
      const response = await axiosInstance.get(`/customerExperience`);
      const customerExperiences: CustomerExperience[] = response.data;

      const expList: ArchivableTinyObject[] = customerExperiences.map(
        (item) => {
          const newExp: ArchivableTinyObject = {
            id: item.id,
            name: item.text,
            isArchived: item.isArchived,
          };
          return newExp;
        }
      );

      return expList;
    } catch (err) {
      throw err;
    }
  }
};

export default customerDefinedValuesService;
