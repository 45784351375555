import { FieldRenderProps } from "@progress/kendo-react-form";
import { TextArea } from "@progress/kendo-react-inputs";
import { Hint, Error } from "@progress/kendo-react-labels";
import "./CustomFormTextArea.scss";

const CustomFormTextArea = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    rows,
    value,
    showTextLimitHint,
    className,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <div className={"k-form-field-wrap"}>
      <TextArea
        valid={valid}
        id={id}
        disabled={disabled}
        maxLength={max}
        rows={rows}
        value={value}
        ariaDescribedBy={`${hindId} ${errorId}`}
        {...others}
        className={className ?? "textareaInput"}
      />
      {showTextLimitHint && (
        <Hint direction={"end"}>
          {value.length} / {max}
        </Hint>
      )}
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default CustomFormTextArea;
