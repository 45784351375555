import { APIToken, InsertAPIToken, UpdateAPIToken } from "../pages/settings/system-settings/api-access/APIAccessProps";
import { axiosWithAuth } from "../utils/customAxios";

const apiService={
    getAllTokens:async(customerId:number):Promise<APIToken[]>=>{
        try{
            const tokens=await axiosWithAuth.get(`/Customer/${customerId}/ApiAccessToken`);
            return tokens.data;
        }catch(error){
            throw error;
        }
    },

    insertNewToken:async(newTokenData:InsertAPIToken,customerId:number):Promise<APIToken>=>{
        try{
            const newToken=await axiosWithAuth.post(`/Customer/${customerId}/ApiAccessToken`,newTokenData);
            return newToken.data;
        }catch(error){
            throw error;
        }
    },

    updateToken:async(tokenId:number,customerId:number,updateTokenData:UpdateAPIToken):Promise<APIToken>=>{
        try{
            const updatedToken=await axiosWithAuth.put(`/Customer/${customerId}/ApiAccessToken/${tokenId}`,updateTokenData);
            return updatedToken.data;
        }catch(error){
            throw error;
        }
    },

    deleteToken:async(tokenId:number,customerId:number)=>{
        try{
            await axiosWithAuth.delete(`/Customer/${customerId}/ApiAccessToken/${tokenId}`);
        }catch(error){
            throw error;
        }
    }
}

export default apiService;