import { ShortScorcardType } from "../pages/call-details/RecordingScorecardTab";

var textField = "";

function compare( a:any, b:any) {

  if ( a.text.toLowerCase() < b.text.toLowerCase() ){
    return -1;
  }
  if ( a.text.toLowerCase() > b.text.toLowerCase() ){
    return 1;
  }
  return 0;
}

export const sortDataFunc = (shorts : any) : ShortScorcardType[] => {
  var sortData = shorts;
  textField = "email";

  if(shorts){
    sortData = shorts.sort(compare);
  }

  return sortData as ShortScorcardType[];
}

