import React, { useEffect, useState, useContext } from "react";
import "./UserProfile.scss";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import UserProfile from "./UserProfile";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { PlatformUserDetail, User } from "../../../../../types/user";
import { AuthorizationProfile } from "../../../../../types/authorization-profile/AuthorizationProfile";
import { Loader } from "@progress/kendo-react-indicators";
import { TinyObj } from "../../../../../types/TinyObj";
import { Customer } from "../../../../../types/customer/Customer";

import useLocale from "../../../../../hooks/useLocale";
import { Dictionary } from "../../../../../types/Dictionary";
import useAuth from "../../../../../hooks/useAuth";
import usersService from "../../../../../services/users.service";
import customerService from "../../../../../services/customer.service";
import authorizationProfileService from "../../../../../services/authorizationprofile.service";
import userGroupsService from "../../../../../services/usergroups.service";
import { navData } from "../../../../../data/NavigationData";
import { TinyUser } from "../../../../../types/user";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import NotFoundError from "../../../../error/NotFoundError";
import { AccessPermissionEnum } from "../../../../../enums";
import UserPermissions from "./UserPermissions";
import { Group } from "../../../../../types/user-groups/Group";
import { AxiosError } from "axios";
import UnauthorizedAccess from "../../../../error/UnauthorizedAccess";
import useSwal from "../../../../../hooks/useSwal";
import useMasterData from "../../../../../hooks/useMasterData";
import { concatUsername } from "../../../../../utils/profileUtils";
import LoadingOverlay from "../../../../../components/LoadingOverlay";
import useCustomNavigation from "../../../../../hooks/useCustomNavigation";

const userData: User = {
  id: 0,
  email: "",
  firstName: "",
  lastName: "",
  loginUserId: 0,
  isMicrosoftLogin: false,
  loginEnabled: false,
  devices: [],
  recordingNetwork: [],
  topics: [],
  moments: [],
  associatedGroups: {
    adminOf: [],
    memberOf: [],
    creatorOf: [],
    playbackNetwork: [],
  },
  isArchived: false,
  scorecards: [],
};

const UserView: React.FC = () => {
  const customNav = useCustomNavigation();
  const master = useMasterData();
  const auth = useAuth();
  const localeCtx = useLocale();
  const location = useLocation();
  const Swal = useSwal();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [customerInfo, setCustomerInfo] = useState<Customer | undefined>(
    undefined
  );
  const [user, setUser] = useState<User>(userData);
  const [authProfiles, setAuthProfiles] = useState<
    AuthorizationProfile[] | undefined
  >(undefined);
  const [userGroups, setUserGroups] = useState<Group[] | undefined>(undefined);
  const [changed, setchanged] = useState(false);
  const [errorToast, setErrorToast] = useState("");
  const [errorToastState, setErrorToastState] = useState(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["UserView"]);
  const [userName, setUserName] = useState("");
  const [currentUser, setCurrentUser] = useState<User>(userData);
  const [freezeProfile, setFreezeProfile] = useState("");
  const [saveUpdateCounter, setSaveUpdateCounter] = useState(1);

  useEffect(() => {
    if (!localeCtx?.selectedLocale?.current.componentTranslations["UserView"]) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("UserView");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["UserView"]
      );
      localeCtx?.setPreviousAppLocale("UserView");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      LoadProfiles(),
      LoadFreezeProfile(),
      LoadUserGroups(),
      getCustomerInfo(),
      getUserInfo(),
      getCurrentUser(),
    ]).then((responses) => {
      setLoading(false);
    });
  }, [userId]);

  const getCurrentUser = async () => {
    try {
      const user = await usersService.getCurrentUser();
      setCurrentUser(user);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    }
  };

  const getUserInfo = async () => {
    setErrorToast("");
    setErrorToastState(false);
    try {
      var user = await usersService.getUser(userId ? userId : "");
      if (user.isArchived) {
        setErrorToast("This user is archived. You cannot see the profile");
        setErrorToastState(true);
      } else {
        const userAuthData: TinyUser = {
          loginUserId: user.loginUserId,
          firstName: user.firstName ?? "",
          lastName: user.lastName ?? "",
          id: user.id,
          email: user.email,
          isArchived: user.isArchived,
        };
        if (auth?.user?.id === user.id) {
          auth?.setUser(userAuthData);
        }

        setUser(user);
        setErrorToast("");
        setErrorToastState(false);
        setUserName(
          concatUsername(
            user.firstName ? user.firstName : "",
            user.lastName ? user.lastName : ""
          )
        );
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error);
        if (error.response?.status === 404) {
          setErrorToast("User Not Found");
        } else {
          setErrorToast("Internal Server Error");
        }
        setErrorToastState(true);
      }
    } finally {
    }
  };

  const getCustomerInfo = async () => {
    var customerId = auth?.tokenPayload?.CustomerId;
    if (customerId !== undefined && customerId > 0) {
      try {
        var customer = await customerService.getCustomerDetailsById(customerId);
        setCustomerInfo(customer);
      } catch (ex) {
        if (ex instanceof Error) {
          console.log(ex);
          navigate("/error", { replace: true });
        }
      } finally {
      }
    }
  };

  const LoadProfiles = async () => {
    try {
      const data = await authorizationProfileService.getAuthorizationProfiles();
      setAuthProfiles(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const LoadFreezeProfile = async () => {
    try {
      const data = await authorizationProfileService.getfreezeprofile();
      setFreezeProfile(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const LoadUserGroups = async () => {
    try {
      const data = await userGroupsService.getUserGroups();
      setUserGroups(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const handleSelectTab = (e: any) => {
    if (changed) {
      Swal.fire({
        title: `${
          translationsLoading
            ? "Are you sure?"
            : fetchLabelKeyTranslation("SwtAltTitle", "Are you sure?")
        }`,
        text: `${
          translationsLoading
            ? "Your changes will not be saved!"
            : fetchLabelKeyTranslation(
                "SwtAltText",
                "Your changes will not be saved!"
              )
        }`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `${
          translationsLoading
            ? "Continue"
            : fetchLabelKeyTranslation("ContinueButton", "Continue")
        }`,
        cancelButtonText: `${
          translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("CancelButton", "Cancel")
        }`,
      }).then((result) => {
        if (result.isConfirmed) {
          setSelectedTab(e.selected);
          setchanged(false);
          customNav?.setIsChanged(false);
        }
      });
    } else {
      setSelectedTab(e.selected);
    }
  };

  const updateUserDetails = async () => {
    setLoading(true);
    await getUserInfo();
    await master?.fetchUpdatedStandardUsers();
    setLoading(false);
  };

  return (
    <>
      {errorToast ||
      (auth?.user &&
        auth?.user?.id !== Number(userId) &&
        !auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)) ? (
        <>
          {errorToastState && (
            <NotificationGroup
              style={{
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 999,
              }}
            >
              <Fade>
                <Notification
                  onClose={() => setErrorToastState(false)}
                  type={{ style: "error" }}
                  closable={true}
                >
                  <span>{errorToast}</span>
                </Notification>
              </Fade>
            </NotificationGroup>
          )}
          {errorToast ? <NotFoundError /> : <UnauthorizedAccess />}
        </>
      ) : (
        <>
          {translationsLoading && (
            <LoadingOverlay
              customStyle={{ position: "fixed", marginTop: "55px" }}
              themeColor={"light"}
              size={"medium"}
              loadingText={fetchLabelKeyTranslation("SwitchLanguageText", "")}
            />
          )}
          <div className="userTable maxTableCol m-b-20">
            <div className="row">
              <div className="col-md-12">
                {
                  <div className="card-header bg-white d-flex justify-content-between">
                    <span className="font-weight-semi d-flex align-items-center">
                      {`${
                        translationsLoading
                          ? "User Profile"
                          : fetchLabelKeyTranslation(
                              "SCDetailTitle",
                              "User Profile"
                            ) +
                            ": " +
                            userName
                      }`}
                    </span>
                    {(auth?.checkUserAccess(
                      AccessPermissionEnum.ManageUsersAndGroups
                    ) ||
                      auth?.checkUserAccess(
                        AccessPermissionEnum.ManageSecurityControl
                      )) && (
                      <div className="buttons-container hov-transparent d-flex align-itmes-center">
                        <Link
                          className="btn-link text-primary p-0 m-0 d-flex align-items-center cursor-pointer"
                          to="/settings/user-control"
                          onClick={(e) => {
                            e.preventDefault();
                            var ctrlClickedKey = e.ctrlKey;
                            customNav?.navigateTo({
                              url: "/settings/user-control",
                              state: { ...navData, backActive: true },
                              ctrlClicked: ctrlClickedKey,
                            });
                          }}
                        >
                          <i className="bi bi-arrow-left fs-18 p-r-5 line-height-1"></i>
                          <span className="fs-13 font-weight-semi">
                            {`${
                              translationsLoading
                                ? "Back to User Management"
                                : fetchLabelKeyTranslation(
                                    "SCDetailBackButton",
                                    "Back to User Management"
                                  )
                            }`}
                          </span>
                        </Link>
                      </div>
                    )}
                  </div>
                }

                <div className="card cardEffect">
                  <div className="card-body p-0">
                    <div className="tab-line tabContentWidth-100 tabContentP-0">
                      <TabStrip
                        className="tab-textTransformNone"
                        tabPosition="top"
                        selected={selectedTab}
                        onSelect={handleSelectTab}
                      >
                        <TabStripTab
                          title={`${
                            translationsLoading
                              ? "Profile"
                              : fetchLabelKeyTranslation(
                                  "ProfileTab",
                                  "Profile"
                                )
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              {loading ? (
                                <div
                                  style={{
                                    minHeight: "50vh",
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Loader type={"infinite-spinner"} />
                                </div>
                              ) : (
                                <UserProfile
                                  user={user}
                                  customerInfo={customerInfo}
                                  setChanged={setchanged}
                                  updateUserDetails={updateUserDetails}
                                  currentUser={currentUser}
                                />
                              )}
                            </div>
                          </div>
                        </TabStripTab>
                        <TabStripTab
                          title={`${
                            translationsLoading
                              ? "Permissions"
                              : fetchLabelKeyTranslation(
                                  "PermissionsTab",
                                  "Permissions"
                                )
                          }`}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              {loading ? (
                                <div
                                  style={{
                                    minHeight: "50vh",
                                    display: "flex",
                                    alignContent: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Loader type={"infinite-spinner"} />
                                </div>
                              ) : (
                                <UserPermissions
                                  user={user}
                                  authProfiles={authProfiles}
                                  userGroups={userGroups}
                                  updateUserDetails={updateUserDetails}
                                  currentUser={currentUser}
                                  freezeProfile={freezeProfile}
                                />
                              )}
                            </div>
                          </div>
                        </TabStripTab>
                      </TabStrip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserView;
