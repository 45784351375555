import { UserTempTokenRequest } from "../types/demo/UserTempTokenRequest";
import { axiosWithoutAuth } from "../utils/customAxios";

const demoService = {
    verifyTokenAndCreateCustomer: async (
        userInfo: UserTempTokenRequest,
        clientId: string
      ): Promise<number> => {
        axiosWithoutAuth.defaults.headers.post["x-tollringauth-clientid"] = clientId;
        const response = await axiosWithoutAuth.post("/demo/VerifyTokenAndCreateCustomer", userInfo, { headers: { isEncrypted: true } });
        return response.status;
      }
}
export default demoService;
