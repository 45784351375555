import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { useState } from "react";
import { Dictionary } from "../../types/Dictionary";
import FileDownload from "js-file-download";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { convertKeyToPascalCase } from "../../utils/stringUtils";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface CallAdditionalInfoDialogProps {
  platformCallDetailId: string;
  onDialogClose: () => void;
  data: Dictionary<string>;
  fetchLabelKeyTranslation: (key: string, defaultVal: string) => string;
  translationsLoading: boolean;
}

interface CopiableInfo {
  label: string;
  text: string;
  copied: boolean;
}

const InfoRow: React.FC<{
  info: CopiableInfo;
  onCopyClick: (label: string, text: string) => void;
}> = ({ info, onCopyClick }) => {
  return (
    <div className="trk-row border-bottom-solid border-w-1 border-black-1">
      <div className="trkCol">
        <div className="trkCol-h font-weight-semi">
          {convertKeyToPascalCase(info.label)}
        </div>
        <div className="trkCol-dot">:</div>
        <div className="trkCol-p">
          <span>{info.text}</span>
          <Button
            className="btn bg-transparent iconBtn text-primary"
            onClick={() => onCopyClick(info.label, info.text)}
          >
            {!info.copied ? (
              <i className="bi bi-files cursor-pointer m-l-1" title="Copy"></i>
            ) : (
              <i className="bi bi-check-lg m-l-1" title="Copied"></i>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

const CallAdditionalInfoDialog: React.FC<CallAdditionalInfoDialogProps> = ({
  platformCallDetailId,
  onDialogClose,
  data,
  fetchLabelKeyTranslation,
  translationsLoading
}) => {
  const [info, setInfo] = useState<CopiableInfo[]>(
    Object.keys(data).map((k) => {
      return { label: convertKeyToPascalCase(k), text: data[k], copied: false };
    })
  );
  const [value, copy] = useCopyToClipboard();

  const onDownloadClick = () => {
    let filename = `${platformCallDetailId}.json`;
    let contentType = "application/json;charset=utf-8;";
    var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(data)))], {
      type: contentType,
    });
    FileDownload(blob, filename);
  };

  const onCopyClick = async (label: string, text: string) => {
    try {
      const success = await copy(text);
      if (success) {
        setInfo((prev) =>
          prev.map((i) => {
            if (i.label === label) {
              i.copied = true;
            } else {
              i.copied = false;
            }
            return i;
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Dialog
      title={
        translationsLoading
          ? "Call Additional Information"
          : fetchLabelKeyTranslation("CallAdditionalInfoDialogTitle", "Call Additional Information")
      }
      onClose={onDialogClose}
      className="dialog-sm"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="modalInfo modalMD">
            <div className="modalInfoBtn text-right border-bottom-solid border-w-1 border-black-1">
              <Tooltip
                anchorElement={"target"}
                position={"right"}
                parentTitle={true}
              >
                <Button
                  className={`btn bg-transparent text-black-12 text-primary m-b-10 ${
                    info.length !== 0 ? "iconBtn" : "disabledIconBtn"
                  }`}
                  title={
                    translationsLoading
                      ? "Download in JSON format"
                      : fetchLabelKeyTranslation("CallAdditionalInfoDialogBtnText", "Download in JSON format")
                  }
                  disabled={info.length === 0}
                  onClick={onDownloadClick}
                >
                  <i className="bi bi-download fs-16"></i>
                </Button>
              </Tooltip>
            </div>
            <div
              className="modalInfoList scroller float-left w-100"
              style={{
                maxHeight: "200px",
                minHeight: "200px",
                overflow: "hidden auto",
              }}
            >
              {info.length > 0 ? (
                <div className="trk-container">
                  <div className="trk-inr p-b-10">
                    {info.map((i) => {
                      return (
                        <InfoRow
                          key={i.label}
                          info={i}
                          onCopyClick={onCopyClick}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="trk-container">
                  <div className="trk-inr p-b-10">
                    <div className="p-10">
                      <div
                        className="text-center text-black-10 p-5 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center m-t-5"
                        style={{ height: "170px" }}
                      >
                        {
                         translationsLoading
                          ? "No Additional Info available."
                          : fetchLabelKeyTranslation("CallAdditionalInfoDialogNoInfo", "No Additional Info available.")
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default CallAdditionalInfoDialog;
