import * as React from "react";
import * as ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from "@progress/kendo-react-charts";
import "hammerjs";
import WaveSurfer from "wavesurfer.js";
import PlayerHiHi from "../call-details/playerHiHi";
import { Color } from "@progress/kendo-drawing";
import WordcloudWrapper from "../wordcloud/WordcloudWrapper";
import { ParentSize } from "@visx/responsive";
import {
  Insight,
  InsightsFilter,
  KeywordInsightDetail,
} from "../../types/insight";

const series = [
  { category: "Negative", value: 0.25, color: "#dc3545" },
  { category: "Neutral", value: 0.30, color: "#ffc107" },
  { category: "Positive", value: 0.45, color: "#28a745" },
];

const labelContent = (props: any) => {
  let formatedNumber = Number(props.dataItem.value).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });
  return `${props.dataItem.category} : ${formatedNumber}`;
};

const CallPlayerHiHi = () => {
  const [selected, setSelected] = React.useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  const mappingForWordCloud = (keywords: KeywordInsightDetail[]) => {
    let mappedKeywords: { text: string; value: number; count: number }[] = [];

    keywords.map((val: KeywordInsightDetail) => {
      mappedKeywords.push({
        text: val.keyword,
        value: val.relevance,
        count: val.count,
      });
    });
    return mappedKeywords;
  };

  const totoAfricaLyrics = `Technology Global Social Network Business Web Internet Connect Media Mobile Data Structure Idea Concept Communication Success Recommend Share Multimedia Progress Friend Forum Message Time Contact Customer System Discussion Viral Like Media Growth Connection Trend People News Tags Follow Search Block Entertainment France Chat Information Page Business Tools Issues Business Marketing Skills Interaction Customer Broadcast tweets Customer Communication Facebook Customer Twitter Instagram Tiktok linkedin `;

  interface WordData {
    text: string;
    value: number;
    count: number;
  }

  function wordFreq(text: string): WordData[] {
    const words: string[] = text.split(/\s/);
    const freqMap: Record<string, number> = {};

    for (const w of words) {
      if (!freqMap[w]) freqMap[w] = 0;
      freqMap[w] += 1;
    }
    return Object.keys(freqMap).map((word) => ({
      text: word,
      value: freqMap[word],
      count: freqMap[word],
    }));
  }

  const words = wordFreq(totoAfricaLyrics);

  return (
    <div className="bgBody darkPlayer">
      <div className="cardBody">
        <div className="callPlayerFull">
          <div className="tab-line">
            <TabStrip selected={selected} onSelect={handleSelect}>
              <TabStripTab title="Player">
                <div className="playerFull p-l-5 p-r-5">
                  <div className="playerSection">
                    <div className="row">
                      <div className="col-md-12">
                        <span className="detailHeader d-flex align-items-center">
                          <span className="fs-16 font-weight-semi p-r-20">
                            Call Details
                          </span>
                          <div className="listInr-detail d-flex">
                            <div className="liDetail bgUserBox d-flex align-items-center p-r-5">
                              <div className="liDetail-user tblUsr">
                                <div className="topUsrAreaPic">
                                  <div className="topUsrAreaPic-i">
                                    <div className="contact-list-icon bg-blueskydull">
                                      <div className="contact-list-icon-txt">
                                        <span>KR</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="liDetail-data">
                                <div className="liDetail-h font-semi-bold text-balck-10 fs-14">
                                  Kiran Reddy
                                </div>
                                <div className="liDetail-p fs-13 line-height-1 p-r-5">
                                  kiran.reddy@hihi.co.uk
                                </div>
                              </div>
                            </div>
                            <div className="liDetail bgUserBox d-flex align-items-center m-l-15 border-left-solid border-w-2 border-white-2">
                              <div className="liDetail-user tblUsr">
                                <div className="topUsrAreaPic">
                                  <div className="topUsrAreaPic-i">
                                    <div className="contact-list-icon bg-darkseagreen">
                                      <div className="contact-list-icon-txt">
                                        <span className="fs-16">
                                          <i className="bi bi-telephone"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="liDetail-data">
                                <div className="liDetail-h fs-13">
                                  +441895478899
                                </div>
                                <div className="liDetail-p fs-13 line-height-1"></div>
                              </div>
                            </div>
                            <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-white-2">
                              <div className="liDetail-data">
                                <div className="liDetail-h fs-13 text-balck-14">
                                  Broadworks
                                </div>
                                <div className="liDetail-p fs-13 line-height-1">
                                  Platforms
                                </div>
                              </div>
                            </div>
                            <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-white-2">
                              <div className="liDetail-data">
                                <div className="liDetail-h fs-13 text-balck-14">
                                  Feb 29, 2024, 20:25
                                </div>
                                <div className="liDetail-p fs-13 line-height-1">
                                  Date/Time
                                </div>
                              </div>
                            </div>
                            <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-white-2">
                              <div className="liDetail-data">
                                <div className="liDetail-h fs-13 text-balck-14">
                                  00:02:30
                                </div>
                                <div className="liDetail-p fs-13 line-height-1">
                                  Duration
                                </div>
                              </div>
                            </div>
                            <div className="liDetail p-l-10 m-l-15 d-flex align-items-center border-left-solid border-w-2 border-white-2">
                              <div className="liDetail-data">
                                <div className="liDetail-h fs-13 text-balck-14">
                                  <button className="k-button k-button-md k-button-solid border-none k-rounded-md radius-50 d-flex align-items-center justify-conter m-r-3">
                                    <span className="k-button-text">
                                      <span
                                        className="fs-16 line-height-1"
                                        title="Flaged: None"
                                        style={{ color: "rgb(204, 204, 204);" }}
                                      >
                                        <i className="bi bi-flag-fill"></i>
                                      </span>
                                    </span>
                                  </button>
                                </div>
                                <div className="liDetail-p fs-13">
                                  Call Flag
                                </div>
                              </div>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="detailTopic border-top-solid border-bottom-solid border-white-1 border-w-1 p-t-10 p-b-10 m-t-10 m-b-15 d-flex justify-content-between">
                          <span className="detailHeader d-flex align-items-center">
                            <span
                              className="fs-15 font-weight-semi p-r-20"
                              style={{ paddingTop: "-5px" }}
                            >
                              Topics
                            </span>
                            <div className="p-r-15 p-l-15 w-100 float-left">
                              <div className="mx-th-tag">
                                <div className="mx-td-spn">
                                  <div className="keywordRow">
                                    <div className="keywordTrk bg-yellowDLL">
                                      <div>
                                        <span title="">
                                          <i className="bi bi-people m-r-6"></i>
                                          Sales
                                        </span>
                                      </div>
                                    </div>
                                    <div className="keywordTrk bg-blueDLL">
                                      <div>
                                        <span title="">
                                          <i className="bi bi-people m-r-6"></i>
                                          New Customer Onboarding
                                        </span>
                                      </div>
                                    </div>
                                    <div className="keywordTrk bg-greenDLL">
                                      <div>
                                        <span title="">
                                          <i className="bi bi-people m-r-6"></i>
                                          Product Features
                                        </span>
                                      </div>
                                    </div>
                                    <div className="keywordTrk bg-greenDarkDLL">
                                      <div>
                                        <span title="">
                                          <i className="bi bi-people m-r-6"></i>
                                          Follow up Callback
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="wavePlayer w-100 float-left">
                          <div className="WaveFormContainer">
                            <div className="playerBtnGroup">
                              <div className="playerBtn">
                                <Button className="PlayerButton fs-24">
                                  <i className="bi bi-rewind"></i>
                                </Button>
                              </div>
                              <div className="playerBtn">
                                <Button className="PlayerButton fs-24">
                                  <i className="bi bi-play"></i>
                                </Button>
                              </div>
                              <div className="playerBtn">
                                <Button className="PlayerButton fs-24">
                                  <i className="bi bi-fast-forward"></i>
                                </Button>
                              </div>
                              <div className="playerBtn">
                                <Button className="PlayerButton fs-24">
                                  <i className="bi bi-volume-up"></i>
                                </Button>
                              </div>
                              <div className="playerBtn">
                                <Button
                                  className="PlayerButton p-r-10 p-l-10 fs-14"
                                  style={{ cursor: "default" }}
                                >
                                  <span className="timeVal-start">
                                    00:00:00 /
                                  </span>
                                  <span className="timeVal-start">
                                    00:02:30
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <PlayerHiHi />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="top-player mb-2 p-t-5">
                          <div className="">
                            <div className="row">
                              <div className="col-md-12 d-flex">
                                <div
                                  className="track m-b-7"
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <div className="track-single">
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "20.504%",
                                        left: "0%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "20.504%",
                                        left: " 0%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-1"
                                      style={{
                                        width: "2.22025%",
                                        left: "20.504%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "18.4613%",
                                        left: "22.7242%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-1"
                                      style={{
                                        width: "3.28544%",
                                        left: "41.1855%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "0.265182%",
                                        left: "44.471%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "3.28544%",
                                        left: "44.7361%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "1.5079%",
                                        left: "48.0216%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "2.22025%",
                                        left: "49.5295%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "0.442722%",
                                        left: "51.7497%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "4.17309%",
                                        left: "52.1925%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "0.442718%",
                                        left: "56.3655%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "6.12593%",
                                        left: "56.8083%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "4.3484%",
                                        left: "62.9342%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "5.23827%",
                                        left: " 67.2826%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "0.442718%",
                                        left: "72.5209%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "3.28544%",
                                        left: "72.9636%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: " 0.904254%",
                                        left: " 76.249%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "2.93037%",
                                        left: "77.1533%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-1"
                                      style={{
                                        width: "0.0876598%",
                                        left: "80.0837%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor speaker-0"
                                      style={{
                                        width: "3.99555%",
                                        left: "80.1713%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor silence-both"
                                      style={{
                                        width: "15.8331%",
                                        left: "84.1669%",
                                      }}
                                    ></span>
                                  </div>
                                  <div className="legends d-flex align-items-center">
                                    <span className="float-left fs-12 font-weight-bold pr-3">
                                      <div className="d-flex">
                                        <span>Participation</span>
                                      </div>
                                    </span>
                                    <ul className="list-unstyled d-flex mb-0">
                                      <li>
                                        <span className="legends-bg silence-both"></span>
                                        Silence Both Ends
                                      </li>
                                      <li>
                                        <span className="legends-bg speaker-0"></span>
                                        participant 1
                                      </li>
                                      <li>
                                        <span className="legends-bg speaker-1"></span>
                                        participant 2
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 d-flex">
                                <div
                                  className="track m-b-7"
                                  style={{ width: "100%" }}
                                >
                                  <div className="track-single"></div>
                                  <div className="legends d-flex align-items-center">
                                    <span className="float-left fs-12 font-weight-bold pr-3">
                                      <div className="d-flex">
                                        <span>Exact Match Topics</span>
                                      </div>
                                    </span>
                                    <ul className="list-unstyled d-flex mb-0"></ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 d-flex">
                                <div
                                  className="track m-b-7"
                                  style={{ width: "100%" }}
                                >
                                  <div className="track-single">
                                    <span
                                      className="trackCursor neutral bg-warning"
                                      style={{
                                        width: "2.22025%",
                                        left: "20.5151%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor happy bg-success"
                                      style={{
                                        width: "3.28597%",
                                        left: "41.1634%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor unhappy bg-danger"
                                      style={{
                                        width: "3.28597%",
                                        left: "44.7158%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor neutral bg-warning"
                                      style={{
                                        width: "2.22025%",
                                        left: "49.5115%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor neutral bg-warning"
                                      style={{
                                        width: "4.17407%",
                                        left: "52.1758%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor unhappy bg-danger"
                                      style={{
                                        width: "6.12789%",
                                        left: "56.794%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor unhappy bg-danger"
                                      style={{
                                        width: "5.23979%",
                                        left: "67.2735%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor unhappy bg-danger"
                                      style={{
                                        width: "3.28597%",
                                        left: "72.9574%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor neutral bg-warning"
                                      style={{
                                        width: "2.93073%",
                                        left: "77.1314%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor unhappy bg-danger"
                                      style={{
                                        width: "3.99645%",
                                        left: "80.151%",
                                      }}
                                    ></span>
                                    <span
                                      className="trackCursor happy bg-success"
                                      style={{ width: "0%", left: "0%" }}
                                    ></span>
                                  </div>
                                  <div className="legends d-flex align-items-center">
                                    <span className="float-left fs-12 font-weight-bold pr-3">
                                      <div className="d-flex">
                                        <span>Sentiments</span>
                                      </div>
                                    </span>
                                    <ul className="list-unstyled d-flex mb-0">
                                      <li>
                                        <span className="legends-bg neutral bg-warning"></span>{" "}
                                        Neutral
                                      </li>
                                      <li>
                                        <span className="legends-bg unhappy bg-danger"></span>{" "}
                                        Negative
                                      </li>
                                      <li>
                                        <span className="legends-bg happy bg-success"></span>{" "}
                                        Positive
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="Transcript">
                <div className="p-l-10">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        className="topHdrBody"
                        style={{ textAlign: "right", margin: "-8px 0 0 0" }}
                      >
                        <Button className="hBoxButton" title="Email">
                          <i className="bi bi-envelope"></i>
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="call-transcript">
                        <div className="d-flex "></div>
                        <div className="text-center p-t-10">
                          <div className="row user-view">
                            <div className="col-md-12">
                              <div
                                className="transcript scroller"
                                style={{ maxHeight: "60vh", overflow: "auto" }}
                              >
                                <div className="rec-comments p-r-20">
                                  <div className="spkTrk spk1">
                                    <div
                                      className="neutral bg-success"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 1
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:00:01
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            Hi. This is a call from Exane user
                                            two thousand nine. And the test call
                                            to check whether the system we can
                                            book is local availability or is it
                                            fine or not currently the local or
                                            customer is English US. Sam, I'll be
                                            speaking few words.
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1">
                                    <div
                                      className="neutral bg-danger"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 1
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:00:22
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            Few words. And based on that,{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1 active">
                                    <div
                                      className="neutral bg-warning"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 2
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:00:28
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            topics would be not children. So in
                                            order to climb the mountain, we
                                            should exclude our physical business
                                            and provide an urgent retention on a
                                            high priority basis of that we
                                            should not be in a critical
                                            situation to climb the mountain, and
                                            we should take immediate action. To
                                            to in turn, to helpify our
                                            colleagues and the escalation
                                            process. Should should involve the
                                            rest to the next level. And the
                                            escalation procedure will include
                                            escalation chain{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1">
                                    <div
                                      className="neutral bg-warning"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 1
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:01:12
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            As far as our physical return is
                                            concerned, immediate escalation is
                                            required.{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1">
                                    <div
                                      className="unhappy bg-danger"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 1
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:01:20
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            We should not look ugly, dumb, or
                                            crazy while climbing the mountain.
                                            It it should be lame or if it's a
                                            idiot. Or we'll distribute if we
                                            don't have physical credentials to
                                            be planning the mountain. A crippled
                                            should not be able to plan the
                                            mountain.{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1">
                                    <div
                                      className="neutral bg-warning"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 2
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:01:39
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            In order to reverse the right l o s
                                            I in a chat. Good morning, guys. Is
                                            normal. He would start conversation,
                                            chatting, or texting or having a
                                            text is{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1">
                                    <div
                                      className="neutral bg-warning"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 1
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:02:01
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            required my ambulance to talk, and
                                            he'll provide the and there was a
                                            message on call. We do meeting
                                            meeting, and the meetups are
                                            required to discuss. Thing where
                                            it's assigned to discuss the
                                            meeting. Evans are in most nothing,
                                            shield, and seek languages, and
                                            emotions, the understanding or even
                                            the and then change the you're
                                            getting so bad. I thought that in in
                                            in in in in. So in the non media. So
                                            you can click the mouse, call from
                                            the phone itself. But again, we all
                                            have a name{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="spkTrk spk1">
                                    <div
                                      className="neutral bg-warning"
                                      style={{
                                        minWidth: "4px",
                                        marginRight: "10px",
                                      }}
                                    ></div>
                                    <div className="spkTrk-h text-white-12">
                                      Participant 1
                                    </div>
                                    <div className="spkTrk-txt">
                                      <div className="trans-list p-t-3">
                                        <div className="trans-group d-flex">
                                          <div className="spkTrk-time p-r-20 text-white-7">
                                            00:02:53
                                          </div>
                                          <div className="spkTrk-para m-b-15">
                                            and human son, that will do a
                                            different people or a being or
                                            living with them in this experience.
                                            Sorry. I'm not connected by working.
                                            Pending the call at three minutes.{" "}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="Summary">
                <div className="row p-2">
                  <div className="col-md-6">
                    <div className="card h-100">
                      <div className="card-header">
                        <div className="cardHeading">
                          <div className="hBox">
                            <div className="cardHeading">Summary</div>
                            <Button className="hBoxButton" title="Email">
                              <i className="bi bi-envelope"></i>
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="cardSummary">
                          <div className="cardSummaryRow">
                            <div className="cardSummaryCol">
                            
                              <span className="text-info">
                                {" "}
                                @Robert William{" "}
                              </span>
                              provided an overview of our company's product/service offerings, emphasizing how our solutions could address the client's pain points effectively.
                            </div>
                          </div>
                          <div className="cardSummaryRow">
                            <div className="cardSummaryCol">
                            Specific concerns included inventory management inefficiencies and difficulties in meeting customer demands promptly.
                              experience
                            </div>
                          </div>
                          <div className="cardSummaryRow">
                            <div className="cardSummaryCol">
                              <span className="text-info">
                                {" "}
                                @Alina Froster{" "}
                              </span>
                              Shared relevant case studies and success stories of other clients who have achieved significant improvements using our product/service.
                            </div>
                          </div>
                          <div className="cardSummaryRow">
                            <div className="cardSummaryCol">
                            Agreed upon the next steps, including scheduling a follow-up meeting for a more in-depth product demonstration tailored to XYZ Corporation's needs.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card h-100">
                      <div className="card-header">
                        <div className="hBox">
                          <div className="cardHeading">Actions</div>
                          <Button className="hBoxButton" title="Email">
                            <i className="bi bi-envelope"></i>
                          </Button>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="pointRow">
                          <div className="pointCol">
                            <i className="bi bi-arrow-right-short"></i>
                            <div className="pointColTxt">
                            Arrange a follow-up meeting with the client to delve deeper into their specific needs and provide a more detailed product/service demonstration
                            </div>
                          </div>
                        </div>
                        <div className="pointRow">
                          <div className="pointCol">
                            <i className="bi bi-arrow-right-short"></i>
                            <div className="pointColTxt">
                            Identify and coordinate internal resources necessary to support the client's implementation and onboarding process.
                            </div>
                          </div>
                        </div>
                        <div className="pointRow">
                          <div className="pointCol">
                            <i className="bi bi-arrow-right-short"></i>
                            <div className="pointColTxt">
                            Follow up with the client to address any outstanding questions or concerns they may have raised during the initial call or subsequent communications. 
                            </div>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card h-100">
                      <div className="card-header">
                        <div className="cardHeading">
                          <div className="hBox">
                            <div className="cardHeading">Key Topics</div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="textLabels">
                          <div className="keywordRow">
                            <div className="keywordTrk">Sales</div>
                            <div className="keywordTrk">New Customer onboarding</div>
                            <div className="keywordTrk">Product Features</div>
                            <div className="keywordTrk">Follow up Callback</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
              <TabStripTab title="Analytics">
                <div className="row p-2">
                  <div className="col-md-6">
                    <div className="card h-100">
                      <div className="card-header">
                        <div className="graphDiv">
                          <div className="cardHeading">Call Sentiments</div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="chartDiv">
                          <Chart>
                            <ChartArea background="transparent" />
                            <ChartTitle text="" />
                            <ChartLegend
                              position="bottom"
                              labels={{ color: "rgba(255,255,255,.6)" }}
                            />
                            <ChartSeries>
                              <ChartSeriesItem
                                type="pie"
                                data={series}
                                field="value"
                                categoryField="category"
                                labels={{
                                  visible: true,
                                  content: labelContent,
                                }}
                              />
                            </ChartSeries>
                          </Chart>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card h-100">
                      <div className="card-header">
                        <div className="graphDiv">
                          <div className="cardHeading">Word Cloud</div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="wordCloudDiv">
                          <ParentSize>
                            {({ width }) => (
                              <WordcloudWrapper
                                width={width}
                                height={320}
                                wordcloudData={words}
                                onMetricClickHandler={(e) => {
                                  console.log(e);
                                }}
                                isDarkTheme={true}
                              />
                            )}
                          </ParentSize>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabStripTab>
            </TabStrip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallPlayerHiHi;
