import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import { formatDateTime } from "../../../utils/dateTimeUtils";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
}

const CustomGridCellForDateTime = (props: CustomGridCellProps) => {
  return (
    <td className="text-break">
      {formatDateTime(props.dataItem.callTime, "MMM DD, YYYY, HH:mm")}
    </td>
  );
};

export default CustomGridCellForDateTime;
