import {
  Grid,
  GridCellProps,
  GridColumn,
  GridColumnProps,
  GridNoRecords,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomUserGridInfo from "../../../components/custom/grid/CustomUserGridInfo";
import useLocale from "../../../hooks/useLocale";
import usersService from "../../../services/users.service";
import { Dictionary } from "../../../types/Dictionary";
import {
  InsightMetricCell,
  InsightMetricKeyLabel,
  InsightViewByTblData,
} from "../../../types/insight";
import { TinyUser } from "../../../types/user";
import {
  concatUsername,
  getRandomProfileColor,
} from "../../../utils/profileUtils";
import useAuth from "../../../hooks/useAuth";
import useSwal from "../../../hooks/useSwal";
import { SortDescriptor } from "@progress/kendo-data-query";
import InsightViewByGridForMobile from "./InsightViewByGridForMobile";
import useTranslation from "../../../hooks/useTranslation";

interface UsersInsightMetricCell {
  columnName: string;
  metricKeyLabel: string;
  metricKeyCounts: { metricKey: TinyUser; count: number }[];
}

export interface AggregateData {
  name: string;
  additionalProperties: Dictionary<string>;
  displayName: string;
  subKeyCallsCnt: { key: string; count: number }[];
  totalCallsCnt: number;
  sentiments?: InsightMetricCell;
  topTopics?: InsightMetricCell;
  topMoments?: InsightMetricCell;
  topUsers?: UsersInsightMetricCell;
  evaluation?: InsightMetricCell;
  // Added for Evaluators
  evaluators?: UsersInsightMetricCell;
  duration?: string;
  averageScore?: string;
}

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

interface InsightViewByGridProps {
  viewByFilterTblData?: InsightViewByTblData;
  viewBy: InsightMetricKeyLabel;
  error?: string;
  onViewByCallCountClickHandler: (
    metricKeyLabel: string,
    metricKey: string,
    metricSubKeyLabel?: string,
    displayName?: string
  ) => void;
}

export interface CustomGridCellProps extends GridCellProps {
  dataItem: AggregateData;
}

const processMetricKeyForDisplay = (
  metricKeyLabel: string,
  metricKey: string,
  metricSubKeyLabel?: string
) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (metricKeyLabel === "Hour") {
    const hour = Number(metricKey);
    return `${
      hour > 9
        ? `${hour}:00 - ${hour === 23 ? "00" : hour + 1}:00`
        : `${hour}:00 - ${hour + 1}:00`
    }`;
  } else if (metricKeyLabel === "Day") {
    return days[Number(metricKey) % days.length];
  } else if (metricKeyLabel === "Week") {
    return `WC ${metricKey}`;
  } else if (metricKeyLabel === "Month") {
    return months[Number(metricKey) % months.length];
  }

  return metricKey;
};

const InsightViewByGrid: React.FC<InsightViewByGridProps> = ({
  viewByFilterTblData,
  viewBy,
  error,
  onViewByCallCountClickHandler,
}) => {
  const trans = useTranslation("InsightsViewByGrid");
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();

  useEffect(() => {
    console.log([trans.translations ? ["ViewByText"] : ""]);
  }, [trans.translations]);

  const CustomTopUsersPropCell = (props: CustomGridCellProps) => {
    const userMetrics = props.dataItem.topUsers;

    const UserBadge: React.FC<{ userInfo: TinyUser; cnt: number }> = ({
      userInfo,
      cnt,
    }) => {
      const name = concatUsername(
        userInfo?.firstName ? userInfo.firstName : "",
        userInfo?.lastName ? userInfo?.lastName : ""
      ).trim();
      const color = getRandomProfileColor(userInfo?.id ?? 0);
      const userIcon = userInfo?.image ? (
        <div className="contact-list-icon-img">
          <img src={require("../../../assets/images/user-img-2.jpg")} />
        </div>
      ) : name !== "" ? (
        <span>
          {name
            .split(" ")
            .map((n: string) => n[0])
            .join("")}
        </span>
      ) : (
        <span className="fs-10">UU</span>
      );

      return (
        <>
          {!userInfo ? (
            <span></span>
          ) : (
            <div
              className="keywordTrk"
              style={{
                border: "2px solid rgba(0,0,0,.08)",
                background: "transparent",
              }}
            >
              <div className="tblUsr float-left">
                <div className="topUsrAreaPic">
                  <div
                    className="topUsrAreaPic-i"
                    style={{ padding: "2px 1px 2px 0" }}
                  >
                    <div
                      className={`contact-list-icon ${color}`}
                      title={`${name}`}
                      style={{ height: "22px", width: "22px", border: "none" }}
                    >
                      <div className="contact-list-icon-txt fs-10">
                        {userIcon}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <span
                className="numCount"
                title={`${
                  trans.translationsLoading
                    ? "Calls"
                    : trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                }`}
                style={{
                  color: "rgba(0,0,0,.4)",
                  padding: "0",
                  cursor: "default",
                }}
              >
                {cnt}
              </span>
            </div>
          )}
        </>
      );
    };

    return (
        <td className="mx-th-tag mxLabel">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {userMetrics &&
              userMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <UserBadge key={idx} userInfo={m.metricKey} cnt={m.count} />
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomTopTopicsPropCell = (props: CustomGridCellProps) => {
    const topicMetrics = props.dataItem.topTopics;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {topicMetrics &&
              topicMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <div
                    className={`keywordTrk ${
                      m.additionalProperties["IsExactMatch"] === "true"
                        ? "bg-greenDarkDLL"
                        : ""
                    }`}
                    key={idx}
                  >
                    <i
                      className={`bi ${
                        m.metricKey.substring(0, 3) === "PUB"
                          ? "bi-people"
                          : "bi-lock-fill"
                      } m-r-6`}
                    ></i>
                    {m.metricKey.substring(4)}

                    {m.subKeyCounts.map((c) => {
                      return (
                        <span
                          style={{ cursor: "default" }}
                          className={`numCount`}
                          title={`${
                            trans.translationsLoading
                              ? "Calls"
                              : trans.fetchLabelKeyTranslation(
                                  "TextCalls",
                                  "Calls"
                                )
                          }`}
                        >
                          {c.count}
                        </span>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomTopMomentsPropCell = (props: CustomGridCellProps) => {
    const momentMetrics = props.dataItem.topMoments;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {momentMetrics &&
              momentMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <div className="keywordTrk" key={idx}>
                    <i
                      className={`bi ${
                        m.metricKey.substring(0, 3) === "PUB"
                          ? "bi-people"
                          : "bi-lock-fill"
                      } m-r-6`}
                    ></i>
                    {m.metricKey.substring(4)}
                    <span
                      className="numCount"
                      title={`${
                        trans.translationsLoading
                          ? "Calls"
                          : trans.fetchLabelKeyTranslation("TextCalls", "Calls")
                      }`}
                    >
                      {m.subKeyCounts.map((c) => {
                        return <span>{c.count}</span>;
                      })}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomSentimentsCell = (props: CustomGridCellProps) => {
    const sentimentMetrics = props.dataItem.sentiments;

    const SentimentBadge: React.FC<{ sentiment: string; cnt: number }> = ({
      sentiment,
      cnt,
    }) => {
      const cls =
        sentiment === "Positive"
          ? "success"
          : sentiment === "Negative"
          ? "danger"
          : "warning";
      const emoji =
        sentiment === "Positive"
          ? "smile"
          : sentiment === "Negative"
          ? "frown"
          : "neutral";
      return (
        <div
          className="keywordTrk"
          style={{
            border: "2px solid rgba(0,0,0,.08)",
            background: "transparent",
          }}
        >
          <div className="tblUsr float-left">
            <div className="topUsrAreaPic">
              <div
                className="topUsrAreaPic-i"
                style={{ padding: "3px 1px 2px 0" }}
              >
                <div
                  className="contact-list-icon"
                  title={sentiment}
                  style={{ height: "22px", width: "22px", border: "none" }}
                >
                  <i
                    className={`bi bi-emoji-${emoji}-fill fs-20 text-${cls}`}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <span
            className="numCount"
            title={`${
              trans.translationsLoading
                ? "Calls"
                : trans.fetchLabelKeyTranslation("TextCalls", "Calls")
            }`}
            style={{ color: "rgba(0,0,0,.4)", padding: "0", cursor: "default" }}
          >
            {cnt}
          </span>
        </div>
      );
    };

    return (
        <td className="mx-th-tag mxLabel">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {sentimentMetrics &&
              sentimentMetrics.metricKeyCounts.map((m, idx) => {
                return (
                  <SentimentBadge
                    key={idx}
                    sentiment={m.metricKey}
                    cnt={m.totalCount}
                  />
                );
              })}
          </div>
        </div>
      </td>
    );
  };

  const CustomViewByHeaderCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;
    let tinyUser: TinyUser | undefined = undefined;
    if (viewBy.name === "Users" || viewBy.name === "Evaluators") {
      try {
        tinyUser = JSON.parse(data.displayName);
      } catch (err) {}
    }

    const label = tinyUser ? (
      <CustomUserGridInfo userInfo={tinyUser} />
    ) : viewBy.name === "Moments" || viewBy.name === "Topics" ? (
      <React.Fragment>
        {data.displayName.substring(0, 3) === "Pri" ? (
          <i className="bi-lock-fill m-r-6" />
        ) : (
          <i className="bi-people m-r-6" />
        )}
        <span>{`${data.displayName.substring(4)}`}</span>
        <span className="boxLabelAI m-l-4 bg-primary tx-white">
          {data.additionalProperties["IsExactMatch"] === "true"
            ? "EXACT"
            : "EXTENDED"}
        </span>
      </React.Fragment>
    ) : (
      <span>{`${data.displayName}`}</span>
    );

    return (
      <td className="mx-th-tag">
        <div>{label}</div>
      </td>
    );
  };
  const CustomTotalCallsPropCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;

    const onCallsClick = (subKeyLabel?: string) => {
      let tinyUser: TinyUser | undefined = undefined;
      if (viewBy.name === "Users" || viewBy.name == "Evaluators") {
        try {
          tinyUser = JSON.parse(data.displayName);
        } catch (err) {}
      }
      const name = concatUsername(
        tinyUser?.firstName ? tinyUser.firstName : "",
        tinyUser?.lastName ? tinyUser?.lastName : ""
      ).trim();
      onViewByCallCountClickHandler(
        viewBy.name,
        data.name,
        subKeyLabel,
        tinyUser
          ? name.length > 0
            ? name
            : tinyUser.email
          : viewBy.name === "Topics"
          ? data.displayName.substring(4)
          : data.displayName
      );
    };

    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          {data.subKeyCallsCnt.length > 0 ? (
            data.subKeyCallsCnt.map((c) => {
              return (
                <div
                  className={`keywordTrk m-b-1 fs-15 cursor-pointer w-auto ${
                    c.key === "HighRelevanceTopic"
                      ? "bg-greenDLL"
                      : c.key === "LowRelevanceTopic"
                      ? "bg-yellowDLL"
                      : c.key === "ExactMatchTopic"
                      ? "bg-greenDarkDLL"
                      : ""
                  }`}
                  title={c.key}
                  style={{ color: "#1E6570" }}
                  onClick={(e) => onCallsClick(c.key)}
                >
                  {c.count}
                </div>
              );
            })
          ) : (
            <span
              className="cursor-pointer"
              style={{ color: "#259fb2" }}
              onClick={(e) => onCallsClick()}
            >
              {data.totalCallsCnt}
            </span>
          )}
        </div>
      </td>
    );
  };

  const CustomEvaluatedDurationPropCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;

    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <span style={{ color: "#259fb2" }}>{data.duration}</span>
        </div>
      </td>
    );
  };

  function convertMinutesToHours(minutes: number) {
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;

    if (hours === 0) {
      return `${remainingMinutes} mins`;
    } else if (remainingMinutes === 0) {
      return `${hours} hrs`;
    } else {
      return `${hours} hrs ${remainingMinutes} mins`;
    }
  }

  const CustomAverageScorePropCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;

    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <span style={{ color: "#259fb2" }}>{data.averageScore}</span>
        </div>
      </td>
    );
  };

  const CustomTotalEvaluatedCallsPropCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <span style={{ color: "#259fb2" }}>
            {
              data.evaluation?.metricKeyCounts.find(
                (metric) => metric.metricKey === "Total"
              )?.totalCount
            }
          </span>
        </div>
      </td>
    );
  };

  const CustomTotalEvaluatedDurationPropCell = (props: CustomGridCellProps) => {
    const data = props.dataItem;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <span style={{ color: "#259fb2" }}>
            {formatTotalEvaluatedDuration(
              Number(
                data.evaluation?.metricKeyCounts.find(
                  (metric) => metric.metricKey === "Total"
                )?.additionalProperties.EvaluatedDuration
              )
            )}
          </span>
        </div>
      </td>
    );
  };

  const formatTotalEvaluatedDuration = (seconds: number) => {
    if (seconds < 60) {
      return `${seconds} sec`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} mins ${remainingSeconds} sec`;
    } else {
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      return `${hours} hrs ${remainingMinutes} mins ${remainingSeconds} sec`;
    }
  };

  const CustomTotalAverageEvaluatedCallsScorePropCell = (
    props: CustomGridCellProps
  ) => {
    const data = props.dataItem;
    return (
      <td className="mx-th-tag">
        <div className="mx-td-spn">
          <span style={{ color: "#259fb2" }}>
            {Number(
              data.evaluation?.metricKeyCounts.find(
                (metric) => metric.metricKey === "Total"
              )?.additionalProperties.AverageScore
            ).toFixed(2)}
          </span>
        </div>
      </td>
    );
  };

  const CustomTotalDifferentEvaluatedCallsCell = (
    props: CustomGridCellProps
  ) => {
    const evaluationMetrics = props.dataItem.evaluation;

    const EvaluationBadge: React.FC<{ evaluation: string; cnt: number }> = ({
      evaluation,
      cnt,
    }) => {
      const cls =
        evaluation === "Good"
          ? "success"
          : evaluation === "Poor"
          ? "danger"
          : "warning";
      return (
        <div
          className="keywordTrk"
          style={{
            border: "2px solid rgba(0,0,0,.08)",
            background: "transparent",
          }}
        >
          <div className="tblUsr float-left">
            <div className="topUsrAreaPic">
              <div
                className="topUsrAreaPic-i"
                style={{ padding: "3px 1px 2px 0" }}
              >
                <div
                  className="contact-list-icon"
                  title={evaluation}
                  style={{ height: "22px", width: "22px", border: "none" }}
                >
                  <i
                    className={`bi bi-hand-thumbs-up-fill fs-20 text-${cls}`}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <span
            className="numCount"
            title={`${
              trans.translationsLoading
                ? "Calls"
                : trans.fetchLabelKeyTranslation("TextCalls", "Calls")
            }`}
            style={{ color: "rgba(0,0,0,.4)", padding: "0", cursor: "default" }}
          >
            {cnt}
          </span>
        </div>
      );
    };

    return (
      <td className="mx-th-tag mxLabel">
        <div className="mx-td-spn">
          <div className="keywordRow">
            {evaluationMetrics &&
              evaluationMetrics.metricKeyCounts.map((m, idx) => {
                if (
                  m.metricKey === "Good" ||
                  m.metricKey === "Average" ||
                  m.metricKey === "Poor"
                ) {
                  return (
                    <EvaluationBadge
                      key={idx}
                      evaluation={m.metricKey}
                      cnt={m.totalCount}
                    />
                  );
                } else {
                  return null;
                }
              })}
          </div>
        </div>
      </td>
    );
  };

  const customHeaderCellDuration = (props: any) => {
    return (
      <div className="mx-th-tag hoverBlack">
        <div
          className="mx-td-spn line-height-2"
          style={{
            wordBreak: "break-word",
            maxWidth: "95%",
            color: "rgba(0, 0, 0, 0.35)",
          }}
        >
          {props.title}
        </div>
      </div>
    );
  };

  const InsightViewByGridForMobileView = (
    mobileViewProps: GridCellProps,
    columns: any
  ) => {
    return (
      <InsightViewByGridForMobile
        {...mobileViewProps}
        viewBy={viewBy}
        columns={columns}
        viewByFilterTblData={viewByFilterTblData}
        onViewByCallCountClickHandler={onViewByCallCountClickHandler}
      />
    );
  };

  const defaultViewByCols: GridColumnProps[] = [
    {
      width: 0,
      field: "cellMobile",
      cell: InsightViewByGridForMobileView,
      sortable: false,
    },
    {
      field: "displayName",
      title: `${viewBy.displayName}`,
      cell: CustomViewByHeaderCell,
      sortable: true,
    },
    {
      field: "totalCalls",
      title: `${
        trans.translationsLoading
          ? "Total Calls"
          : trans.fetchLabelKeyTranslation("TotalCallsText", "Total Calls")
      }`,
      cell: CustomTotalCallsPropCell,
      width: 80,
      sortable: false,
    },
  ];

  const [sort, setSort] = React.useState([] as SortDescriptor[]);
  const [data, setData] = useState<AggregateData[]>([]);
  const [columns, setColumns] = useState<GridColumnProps[]>(defaultViewByCols);

  const [page, setPage] = useState<PageState>(initialDataState);
  const onPageChange = (event: GridPageChangeEvent) => {
    setPage(event.page);
  };
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  // useEffect(()=>{
  // },[columns.length])

  useEffect(() => {
    const buildViewTblData = async () => {
      setDataLoading(true);
      if (
        viewByFilterTblData &&
        viewByFilterTblData.rows.length > 0 &&
        viewByFilterTblData.rows[0].cells.length > 0
      ) {
        // set columns
        let foundUsersColumn = false;
        const cols: GridColumnProps[] = [];
        const row = viewByFilterTblData.rows[0];
        row.cells.forEach((c, idx) => {
          if (idx > 0) {
            switch (c.metricKeyLabel) {
              case "Users": {
                cols.push({
                  field: "topUsers",
                  title: c.columnName,
                  cell: CustomTopUsersPropCell,
                  sortable: false,
                });
                foundUsersColumn = true;
                break;
              }
              case "Duration": {
                cols.push({
                  field: "duration",
                  title: c.columnName,
                  cell: CustomEvaluatedDurationPropCell,
                  sortable: false,
                });
                break;
              }
              case "AverageScore": {
                cols.push({
                  field: "duration",
                  title: c.columnName,
                  cell: CustomAverageScorePropCell,
                  width: 200,
                  sortable: false,
                });
                break;
              }
              case "Sentiments": {
                cols.push({
                  field: "sentiments",
                  title: c.columnName,
                  cell: CustomSentimentsCell,
                  sortable: false,
                });
                break;
              }
              case "Topics": {
                if (auth?.checkTopicAvailibility()) {
                  cols.push({
                    field: "topTopics",
                    title: c.columnName,
                    cell: CustomTopTopicsPropCell,
                    width: viewBy.name === "Users" ? 150 : "",
                    headerCell: customHeaderCellDuration,
                    sortable: false,
                  });
                }
                break;
              }
              case "Moments": {
                cols.push({
                  field: "topMoments",
                  title: c.columnName,
                  cell: CustomTopMomentsPropCell,
                  sortable: false,
                });
                break;
              }
              case "Evaluation": {
                // Renaming "Total Calls" to "Total Evaluated Calls" only in case of "Evaluation"
                if (viewBy.name === "Evaluators") {
                  defaultViewByCols[2].field = "evaluation";
                  defaultViewByCols[2].title = `${
                    trans.translationsLoading
                      ? "Total Evaluated Calls"
                      : trans.fetchLabelKeyTranslation(
                          "TotalEvaluatedCallsText",
                          "Total Evaluated Calls"
                        )
                  }`;
                  defaultViewByCols[2].width = 145;
                }
                // Adding column called "Total Evaluated Calls" only in case of "Users"
                else if (viewBy.name === "Users") {
                  cols.push({
                    field: "evaluation",
                    title: "Total Evaluated Calls",
                    cell: CustomTotalEvaluatedCallsPropCell,
                    width: 130,
                    sortable: false,
                  });
                }

                cols.push({
                  field: "evaluation",
                  title: "Total Evaluated Duration",
                  cell: CustomTotalEvaluatedDurationPropCell,
                  width: 140,
                  sortable: false,
                });

                cols.push({
                  field: "evaluation",
                  title: "Avg. Score %",
                  cell: CustomTotalAverageEvaluatedCallsScorePropCell,
                  width: 100,
                  sortable: false,
                });

                cols.push({
                  field: "evaluation",
                  title: "Evaluation",
                  cell: CustomTotalDifferentEvaluatedCallsCell,
                  width: 180,
                  sortable: false,
                });
                break;
              }
            }
          }
        });

        defaultViewByCols[0].cell = (props) =>
          InsightViewByGridForMobileView(props, [
            defaultViewByCols[0],
            defaultViewByCols[1],
            defaultViewByCols[2],
            ...cols,
          ]);

        setColumns([
          defaultViewByCols[0],
          defaultViewByCols[1],
          defaultViewByCols[2],
          ...cols,
        ]);
        const uniqueUserIds: string[] = [];
        if (foundUsersColumn) {
          viewByFilterTblData.rows.forEach((r) => {
            r.cells.forEach((c) => {
              if (c.metricKeyLabel === "Users") {
                c.metricKeyCounts.forEach((kc) => {
                  if (!uniqueUserIds.includes(kc.metricKey)) {
                    uniqueUserIds.push(kc.metricKey);
                  }
                });
              }
            });
          });
        } else if (viewBy.name === "Users" || viewBy.name === "Evaluators") {
          viewByFilterTblData.rows.forEach((r) => {
            const id = r.cells[0].metricKeyCounts[0].metricKey;
            if (!uniqueUserIds.includes(id)) {
              uniqueUserIds.push(id);
            }
          });
        }

        let tinyUsers: { [key: string]: TinyUser } = {};
        let error = false;
        try {
          const res = await usersService.getTinyUsers(uniqueUserIds);
          tinyUsers = res;
        } catch (err) {
          console.error(err);
          error = true;
        }

        // set data
        const updatedData: AggregateData[] = [];
        viewByFilterTblData.rows.map((r) => {
          const dataRow: AggregateData = {
            name: "",
            displayName: "N/A",
            totalCallsCnt: 0,
            subKeyCallsCnt: [],
            additionalProperties: {},
          };
          const cell = r.cells[0];
          dataRow.name = cell.metricKeyCounts[0].metricKey;
          if (viewBy.name === "Users" || viewBy.name === "Evaluators") {
            dataRow.displayName = JSON.stringify(
              tinyUsers[cell.metricKeyCounts[0].metricKey]
            );
          } else {
            dataRow.displayName = processMetricKeyForDisplay(
              viewBy.name,
              cell.metricKeyCounts[0].metricKey
            );
          }

          Object.keys(cell.metricKeyCounts[0].additionalProperties).forEach(
            (a) => {
              dataRow.additionalProperties[a] =
                cell.metricKeyCounts[0].additionalProperties[a];
            }
          );

          dataRow.subKeyCallsCnt = [];
          if (cell.metricKeyCounts[0].subKeyCounts.length > 0) {
            cell.metricKeyCounts[0].subKeyCounts.forEach((c) => {
              dataRow.subKeyCallsCnt?.push(c);
            });
          }
          dataRow.totalCallsCnt = cell.metricKeyCounts[0].totalCount;
          if (dataRow.totalCallsCnt > 0) {
            for (let i = 1; i < r.cells.length; i++) {
              switch (r.cells[i].metricKeyLabel) {
                case "Users": {
                  const userCell: UsersInsightMetricCell = {
                    columnName: r.cells[i].columnName,
                    metricKeyLabel: r.cells[i].metricKeyLabel,
                    metricKeyCounts: [],
                  };
                  if (!error) {
                    userCell.metricKeyCounts = r.cells[i].metricKeyCounts.map(
                      (c) => {
                        return {
                          metricKey: tinyUsers[c.metricKey],
                          count: c.totalCount,
                        };
                      }
                    );
                  }
                  dataRow.topUsers = userCell;
                  break;
                }
                case "Duration": {
                  dataRow.duration = convertMinutesToHours(
                    r.cells[i]?.metricKeyCounts[0]?.totalCount
                  );
                  break;
                }
                case "AverageScore": {
                  dataRow.averageScore =
                    r.cells[i]?.metricKeyCounts[0]?.totalCount + "%";
                  break;
                }
                case "Sentiments": {
                  dataRow.sentiments = r.cells[i];
                  break;
                }
                case "Topics": {
                  dataRow.topTopics = r.cells[i];
                  break;
                }
                case "Moments": {
                  dataRow.topMoments = r.cells[i];
                  break;
                }
                case "Evaluation": {
                  dataRow.evaluation = r.cells[i];
                }
              }
            }
            updatedData.push(dataRow);
          }
        });

        let sortedData: AggregateData[] = [];
        if (viewBy.name === "Evaluators" || viewBy.name === "Users") {
          const tempSort: SortDescriptor[] = [
            { field: "displayName", dir: "asc" },
          ];
          sortedData = sortDataOnDisplayName(tempSort, updatedData);
          setSort(tempSort);
          setData(sortedData);
        } else {
          setSort([]); // if we go to tab other than Users/Evaluators, the sorting arrow on the column should get removed
          setData(updatedData);
        }
      } else {
        setData([]);
        setSort([]);
      }
      setDataLoading(false);
    };

    if (viewByFilterTblData) {
      buildViewTblData();
    }
  }, [viewByFilterTblData]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "InsightsViewByGrid"
      ]
    ) {
      trans.fetchTranslations("InsightsViewByGrid");
    }
  }, [localeCtx?.selectedLocale]);

  const sortDataOnDisplayName = (
    tempSort: SortDescriptor[],
    data: AggregateData[]
  ) => {
    const sortedData = data.sort((a, b) => {
      // Parse displayName string into objects
      const displayNameA = JSON.parse(a.displayName);
      const displayNameB = JSON.parse(b.displayName);

      // Access firstName property from parsed objects
      const firstNameA = displayNameA.firstName;
      const firstNameB = displayNameB.firstName;

      // Compare first names for sorting
      if (tempSort[0].dir === "asc") {
        return firstNameA.localeCompare(firstNameB);
      } else if (tempSort[0].dir === "desc") {
        return firstNameB.localeCompare(firstNameA);
      }
      return firstNameA.localeCompare(firstNameB);
    });
    return sortedData;
  };

  return (
    <React.Fragment>
      <div className="row p-t-10 p-r-20 p-l-20 p-b-1">
        <div className="col-md-12">
          <div className="bg-white d-flex justify-content-between">
            <span className="font-weight-semi d-flex align-items-center">
              {`${
                trans.translationsLoading
                  ? "View By"
                  : trans.fetchLabelKeyTranslation("ViewByText", "View By")
              } ${viewBy.displayName}`}
            </span>
          </div>
        </div>
      </div>
      <div className="row p-t-10">
        <div className="col-md-12">
          <div className="maxTableCol tableList">
            <div className="table-container table-responsive table-overflow-hedden borderLeftSecondChild table-mobile">
              <Grid
                data={data?.slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={data?.length}
                pageable={{ buttonCount: 4, pageSizes: true }}
                onPageChange={onPageChange}
                sortable={true}
                sort={sort}
                onSortChange={(e: GridSortChangeEvent) => {
                  if (viewBy.name === "Users" || viewBy.name === "Evaluators") {
                    let tempSort = e.sort;
                    if (e.sort.length === 0 && sort.length !== 0) {
                      tempSort = sort.map((s) => {
                        return { field: s.field, dir: "asc" };
                      });
                    }
                    const sortedData = sortDataOnDisplayName(tempSort, data);
                    setData(sortedData);
                    setSort(tempSort);
                  }
                }}

                //resizable={true}
              >
                <GridNoRecords>
                  {!viewByFilterTblData && dataLoading && (
                    <Loader type={"infinite-spinner"} />
                  )}
                  {viewByFilterTblData &&
                    !dataLoading &&
                    !error &&
                    data &&
                    data.length === 0 && (
                      <span className="fs-15">
                        {`${
                          trans.translationsLoading
                            ? "No records matching with this filter!"
                            : trans.fetchLabelKeyTranslation(
                                "MsgNoRecordfnd",
                                "No records matching with this filter!"
                              )
                        }`}
                      </span>
                    )}
                  {error && (
                    <span className="fs-15">
                      <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                      {`${
                        trans.translationsLoading
                          ? "Uh Oh! Something Went Wrong. Please Try Again!"
                          : trans.fetchLabelKeyTranslation(
                              "FailedTextMsg",
                              "Uh Oh! Something Went Wrong. Please Try Again!"
                            )
                      }`}
                    </span>
                  )}
                </GridNoRecords>
                {columns.map((column, idx) => {
                  return <GridColumn key={idx} {...column} />;
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InsightViewByGrid;
