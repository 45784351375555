import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Buffer } from 'buffer';
import useLocale from "../../hooks/useLocale";
import { PlatformDetail } from "../../types/customer";
import React from "react";
import useAuth from "../../hooks/useAuth";
import customerService from "../../services/customer.service";
import { AxiosError } from "axios";
import { CustomerPlatformStatus } from "../../types/customer/CustomerPlatformStatus";
import { Loader } from "@progress/kendo-react-indicators";
import { appUrl } from "../..";
import { GraphUrlStateDetails } from "../../types/customer/GraphUrlStateDetails";
import { MsTeamsProvisioningActionEnum } from "../../enums/MsTeamsProvisioningActionEnum";
import useTranslation from "../../hooks/useTranslation";

interface SbcMsTeamsProps {
    platformDetail: PlatformDetail
}

const adminConsentUrlTemplate = "https://login.microsoftonline.com/common/adminconsent?client_id={client_id}&redirect_uri={redirect_uri}&scope={scope}&state={state}";

const SbcMsTeams: React.FC<SbcMsTeamsProps> = ({ platformDetail }) => {
    const localeCtx = useLocale();
    const auth = useAuth();
    const trans = useTranslation("CustomerPlatformSbcMsTeams");
    const [loading, setLoading] = useState<boolean>(false);
    const [platformStatus, setPlatformStatus] = useState<CustomerPlatformStatus | null>(null);
    const [onboardingStep, setOnboardingStep] = useState<number>(1);
    const [adminConsentUrl, setAdminConsentUrl] = useState<string | null>(null);


    useEffect(() => {
        if (!localeCtx?.selectedLocale?.current.componentTranslations["CustomerPlatformSbcMsTeams"]) {
            trans.fetchTranslations("CustomerPlatformSbcMsTeams")
        }
    }, [localeCtx?.selectedLocale]);

    useEffect(() => {
        getCustomerPlatformStatus();
    }, []);


    const getCustomerPlatformStatus = async () => {
        setLoading(true);
        const customerId = auth?.tokenPayload?.CustomerId;
        if (customerId !== undefined && customerId > 0) {
            try {
                const _platformStatus = await customerService.getCustomerPlatformStatus(customerId, "SBC-MsTeams");
                setPlatformStatus(_platformStatus);
                if (_platformStatus.platformStatusHistory.find(history => history.statusName === "MsAdminConsentGranted") === undefined) {

                    if (_platformStatus.customerOnboardingDetail.azureAppClientId !== undefined &&
                        _platformStatus.customerOnboardingDetail.sbcMsTeamsAdminConsentUrlScope !== undefined) {
                        let urlState: GraphUrlStateDetails = {
                            customerId: customerId,
                            platform: "SBC-MsTeams",
                            msTeamsProvisioningAction: MsTeamsProvisioningActionEnum.GrantMsAdminConsent
                        };
                        let encodedUrlState = Buffer.from(JSON.stringify(urlState)).toString("base64");
                        let _adminConsentUrl = adminConsentUrlTemplate;
                        _adminConsentUrl = _adminConsentUrl.replace("{client_id}", encodeURIComponent(_platformStatus.customerOnboardingDetail.azureAppClientId));
                        _adminConsentUrl = _adminConsentUrl.replace("{scope}", encodeURIComponent(_platformStatus.customerOnboardingDetail.sbcMsTeamsAdminConsentUrlScope));
                        _adminConsentUrl = _adminConsentUrl.replace("{redirect_uri}", encodeURIComponent(appUrl + '/customer-platform/admin-action'));
                        _adminConsentUrl = _adminConsentUrl.replace("{state}", encodeURIComponent(encodedUrlState));
                        setAdminConsentUrl(_adminConsentUrl);
                    }
                    setOnboardingStep(1);
                }
                else {
                    setOnboardingStep(2);
                }
            } catch (err) {
                console.log(err);
                if (err instanceof AxiosError) {
                    setPlatformStatus(null);
                }
            }
            finally {
                setLoading(false);
            }
        }
    }

    const grantMsTeamsAdminConsent = async () => {
        if (adminConsentUrl !== null) {
            window.location.replace(adminConsentUrl);
        }
    }


    return (
        loading ? (
            <div className="col-md-12 d-flex justify-content-center align-items-center">
                <Loader type={"infinite-spinner"} />
            </div>
        ) : (
            <div>
                <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                    <span className="text-primary">SBC-MsTeams Platform</span>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                    <div className="trkCol">
                        <div className="trkCol-h font-weight-semi">
                            {`${trans?.translationsLoading ? "Sip Customer Ref."
                                : trans?.fetchLabelKeyTranslation("SipCustomerRef", "Sip Customer Ref.")}`}
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p">{platformDetail.customerDetail.sipCustomerReference}</div>
                    </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                    <div className="trkCol">
                        <div className="trkCol-h font-weight-semi">
                            {`${trans?.translationsLoading ? "Tenant Id"
                                : trans?.fetchLabelKeyTranslation("TenantId", "Tenant Id")}`}
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p">{platformDetail.customerDetail.sbcMsTeamsTenantGuid}</div>
                    </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                    <div className="trkCol">
                        <div className="trkCol-h font-weight-semi">
                            {`${trans?.translationsLoading ? "Admin Email"
                                : trans?.fetchLabelKeyTranslation("AdminEmail", "Admin Email")}`}
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p">{platformDetail.customerDetail.msTeamsAdminEmail}</div>
                    </div>
                </div>
                <div className="row p-t-20">
                    <div className="col-md-12">
                        <div className="stepMain">
                            <div className="stepFlow">
                                <div className="stepFlowTitle float-left w-100">
                                    <span className="trk-title text-primary">
                                        Setup Platform
                                    </span>
                                </div>
                                <div className="stepFlowGroup">
                                    <div className="stepFlowRow">
                                        <div className={`stepFlowCol ${onboardingStep === 1 ? "step-current" : (onboardingStep > 1 ? "step-active" : "")}`}>
                                            <div className="stepColumn">
                                                <div className="stepCircle">
                                                    <div className="stepCircleBdr">
                                                        <div className="stepCircleBox">
                                                            <div className="stepCircleIcon">
                                                                {onboardingStep > 1 && <i className="bi bi-check-lg"></i>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="stepLabelRow">
                                                    <div className="stepRowText">
                                                        <div className="stepLabel">
                                                            Microsoft Teams Admin Consent
                                                        </div>
                                                        <div className="stepLabelDetails">
                                                            Microsoft Teams admin consent refers to the process by which administrators grant permissions for 
                                                            applications or services to access and integrate with Microsoft Teams on behalf of your organization. 
                                                            Application requires specific permissions to interact with Microsoft Teams APIs and access certain features or data.
                                                        </div>
                                                    </div>
                                                    {onboardingStep === 1 &&
                                                        <div className="stepBtn float-right d-flex align-items-center">
                                                            <Button
                                                                className={`btn bg-primary text-white`}
                                                                onClick={grantMsTeamsAdminConsent}
                                                            >
                                                                Grant Consent
                                                            </Button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default SbcMsTeams;
