import { Success } from "../types/success/Success";
import { axiosWithAuth } from "../utils/customAxios";

const helpguideService = {
  getHelpGuideURL: async (): Promise<Success> => {
    const response = await axiosWithAuth.get(`systemsetting/helpguide`);
    return response.data;
  },
};

export default helpguideService;
