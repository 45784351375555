import moment from "moment";

export const convertEpochsToDateTime = (
  epochs: number,
  formatString: string = "MM/DD/YYYY HH:MM:SS"
): string => {
  const res = moment.unix(epochs).format(formatString);
  return res;
};

export const formatSecondsToTime = (secs: number): string => {
  return moment.utc(secs * 1000).format("HH:mm:ss");
};

export const currentTimeInEpochs = (): number => {
  const now = Date.now();
  return Math.round(now / 1000);
};

export const formatDateTime = (
  datetimeString: string,
  format: string = "MMM DD, YYYY, HH:mm"
): string => {
  return moment(datetimeString).format(format);
};
