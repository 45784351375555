import { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./layout/Header";
import useSessionStorage from "../hooks/useSessionStorage";
import { MasterDataProvider } from "../contexts/MasterDataProvider";

const SharedRoutes = () => {
  const location = useLocation();
  const [shareToken, setShareToken] = useSessionStorage<string | undefined>(
    "ShareToken",
    undefined
  );

  useEffect(() => {
    console.log(shareToken);
  }, []);

  return shareToken ? (
    <>
      <Header />
      <div className="float-left w-100 p-t-55">
        <div className="mobile-contain h-100 pt-4 p-l-15 p-r-15">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12">
                  <MasterDataProvider>
                    <Outlet />
                  </MasterDataProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default SharedRoutes;
