import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  FormElement,
  Form,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useContext, useRef } from "react";
import CustomComboBox from "../../../../components/custom/form/CustomComboBox";
import CustomInput from "../../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../../components/custom/form/CustomMultiSelect";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import { AuthorizationProfile } from "../../../../types/authorization-profile/AuthorizationProfile";
import { TinyObj } from "../../../../types/TinyObj";
import { GroupRelation, TinyUser, User } from "../../../../types/user";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import userGroupsService from "../../../../services/usergroups.service";
import usersService from "../../../../services/users.service";
import authorizationProfileService from "../../../../services/authorizationprofile.service";
import useMasterData from "../../../../hooks/useMasterData";
import { EmailValidator, NameValidator } from "../../../../utils/validatorUtil";
import { Loader } from "@progress/kendo-react-indicators";
import { UpsertUser } from "../../../../types/user/UpsertUser";
import { Button } from "@progress/kendo-react-buttons";
import "./addUserDialog.css";
import useSwal from "../../../../hooks/useSwal";
import LoadingOverlay from "../../../../components/LoadingOverlay";
import userNameRegexService from "../../../../services/usernameRegex.service";
import {
  AccessPermission,
  RecordingNetworkAccessibilty,
} from "../../../../types/master-data";
import useAuth from "../../../../hooks/useAuth";
import { AccessPermissionEnum } from "../../../../enums";
import useTranslation from "../../../../hooks/useTranslation";

const apLocaleKeyName: { [key: string]: string } = {
  "Super Admin": "SuperAdmin",
  "Recording Admin": "RecordingAdmin",
  "Playback Supervisor": "PlaybackSupervisor",
  "Recording Agent": "RecordingAgent",
};

interface IAddUserDialogProps {
  toggleDialog: () => void;
  addNewUser: (newUser: User) => void;
  currentUser?: User;
}

const AddUserDialog: React.FC<IAddUserDialogProps> = ({
  toggleDialog,
  addNewUser,
  currentUser,
}) => {
  const trans=useTranslation("AddUserDialog");
  const masterData = useMasterData();
  const auth = useAuth();
  const localeCtx = useLocale();
  const Swal = useSwal();
  const formRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [authProfiles, setAuthProfiles] = useState<
    AuthorizationProfile[] | undefined
  >(undefined);
  const [userGroups, setUserGroups] = useState<TinyObj[] | undefined>(
    undefined
  );
  const [loginEnabled, setLoginEnabled] = useState<boolean>(false);
  const [userNameRegex, setUserNameRegex] = useState<RegExp>();

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["AddUserDialog"]
    ) {
      trans.fetchTranslations("AddUserDialog");
    }
  }, [localeCtx?.selectedLocale]);

  const nameValidator = (value: string) => {
    if (value.length > 100) {
      return trans.fetchLabelKeyTranslation(
        "NameValidatorCapacity",
        "Field length cannot be more than 100!"
      );
    }

    if (userNameRegex && !NameValidator(value, userNameRegex)) {
      return trans.fetchLabelKeyTranslation(
        "InvalidCharactersError",
        "Remove invalid characters"
      );
    }

    return "";
  };

  const emailValidator = (email: string) => {
    if (!email) {
      return " ";
    }
    if (EmailValidator(email)) {
      return "";
    }
    return trans.fetchLabelKeyTranslation("EmailValidator", "Enter Correct Email");
  };

  const authProfileValidator = (authProfile: any) => {
    if (!authProfile) {
      return " ";
      // return `${
      //   translationsLoading
      //     ? "Field cannot be empty"
      //     : fetchLabelKeyTranslation(
      //         "NameValidatorEmpty",
      //         "Field cannot be empty"
      //       )
      // }`;
    }
    return "";
  };

  const updateLoginEnabled = (e: SwitchChangeEvent) => {
    setLoginEnabled(e.value);
  };

  useEffect(() => {
    LoadProfiles();
    LoadUserGroups();
    LoadUserNameRegex();
  }, []);

  useEffect(() => {
    mapAuthProfileLocalization(authProfiles);
  }, [trans.translations]);

  const LoadUserGroups = async () => {
    try {
      const data = await userGroupsService.getUserGroups();
      const groupData = data?.filter((ele) => {
        if (
          auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl) ||
          currentUser?.associatedGroups?.playbackNetwork?.find(
            (playback: TinyObj) => playback.id === ele.id
          )
        ) {
          let gp: TinyObj = {
            id: ele.id,
            name: ele.name,
          };
          return gp;
        }
      });
      setUserGroups(groupData);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const LoadProfiles = async () => {
    try {
      const data = await authorizationProfileService.getAuthorizationProfiles();
      mapAuthProfileLocalization(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const LoadUserNameRegex = async () => {
    try {
      const data = await userNameRegexService.fetchUserNameRegex();
      setUserNameRegex(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    const form = formRef.current as Form;
    const groupRelation: GroupRelation = {
      adminOf: [],
      memberOf: [],
      creatorOf: [],
      playbackNetwork: form.values.addViaGroup,
    };
    const newUser: UpsertUser = {
      email: form.values.email,
      firstName: form.values.firstName,
      lastName: form.values.lastName,
      managerEmailAddress: form.values.manager?.email,
      loginEnabled: loginEnabled,
      devices: [],
      authorizationProfileName: form.values.authProfile?.name,
      recordingNetworkAccessibility: form.values.addViaPeople?.name,
    };
    await usersService
      .insertUser(newUser)
      .then(async (user) => {
        await updateRecordingNetwork(user.id);
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: trans.fetchLabelKeyTranslation("SwtAltUserAdded", "User Added"),
          confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
        });
        form.resetForm();
        addNewUser(user);
        toggleDialog();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.status);
        if (err.response.status === 404) {
          Swal.fire({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: trans.fetchLabelKeyTranslation("SwtAltTitle", "Oops…"),
            text: trans.fetchLabelKeyTranslation(
              "SwtAltURFailed",
              "User Registration Failed"
            ),
            confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
          });
        } else if (err.response.status === 403) {
          console.log("403 Recieved");
          Swal.fire({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: trans.fetchLabelKeyTranslation("SwtAltTitle", "Oops…"),
            text: trans.fetchLabelKeyTranslation(
              "SwtAltEARegistered",
              "Email Is Already Registered."
            ),
            confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
          });
        } else if (err instanceof Error) {
          Swal.fire({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: trans.fetchLabelKeyTranslation("SwtAltTitle", "Oops…"),
            text: trans.fetchLabelKeyTranslation(
              "SwtAltWrong",
              "Something went wrong! Please Try again…"
            ),
            confirmButtonText: trans.fetchLabelKeyTranslation("OKText", "OK"),
          });
        }
      });
  };

  const updateRecordingNetwork = async (userId: number) => {
    const form = formRef.current as Form;
    if (form.values.addViaGroup !== null) {
      let updatedNetworkGroup = await usersService
        .updateRecordingNetworkViaGroup(form.values.addViaGroup, userId)
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const mapAuthProfileLocalization = (
    _authProfiles: AuthorizationProfile[] | undefined
  ) => {
    if (_authProfiles !== undefined) {
      _authProfiles = _authProfiles.filter((ap) => {
        const findSecurityControlPermission = ap.permissions.find(
          (permission: AccessPermission) => permission.id === 14
        );
        if (
          !findSecurityControlPermission ||
          auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl)
        ) {
          ap.localizationValue = ap.isSystemDefined
            ? trans.fetchLabelKeyTranslation(apLocaleKeyName[ap.name], ap.name)
            : ap.name;
          return ap;
        }
      });
      setAuthProfiles(_authProfiles);
    }
  };

  //   function generatePassword() {
  //     var length = 8,
  //         charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
  //         retVal = "";
  //     for (var i = 0, n = charset.length; i < length; ++i) {
  //         retVal += charset.charAt(Math.floor(Math.random() * n));
  //     }
  //     return retVal;
  // }

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        firstName: "",
        lastName: "",
        manager: null,
        email: "",
        authProfile: null,
        addViaPeople: null,
        addViaGroup: [],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={trans.fetchLabelKeyTranslation("AddUserTitle", "Add User")}
            onClose={toggleDialog}
          >
            {loading && (
              <LoadingOverlay
                customStyle={{ position: "fixed" }}
                themeColor={"light"}
                size={"medium"}
              />
            )}
            <div
              className="addUserBox"
              style={{ minWidth: "240px", maxWidth: "500px" }}
            >
              <div
                className="detailCol"
                style={{
                  maxHeight: "560px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div
                  className="detailColBox p-r-7 p-l-3"
                  style={{ maxHeight: "70vh" }}
                >
                  <div className="UserBoxLabel float-left w-100">
                    <div className="formBoxRow fs-14 font-weight-bold">
                      {trans.fetchLabelKeyTranslation(
                              "BasicDetailsTitle",
                              "Details"
                            )}
                    </div>
                    <div className="formBoxRow">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="form-group">
                              <div className="formLabel fs-14 text-black-11 p-b-3">
                                {trans.fetchLabelKeyTranslation(
                                        "FirstNameTitle",
                                        "First Name"
                                      )}
                              </div>
                              <div className="formControl">
                                <Field
                                  id="firstName"
                                  name="firstName"
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter(
                                    "firstName"
                                  )}
                                  placeholder={trans.fetchLabelKeyTranslation(
                                    "FirstNamePlaceHolder",
                                    "Enter First Name"
                                  )}
                                  component={CustomInput}
                                  validator={nameValidator}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="form-group">
                              <div className="formLabel fs-14 text-black-11 p-b-3">
                                {trans.fetchLabelKeyTranslation(
                                        "LastNameTitle",
                                        "Last Name"
                                      )}
                              </div>
                              <div className="formControl">
                                <Field
                                  id="lastName"
                                  name="lastName"
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter(
                                    "lastName"
                                  )}
                                  placeholder={trans.fetchLabelKeyTranslation(
                                    "LastNamePlaceHolder",
                                    "Enter Last Name"
                                  )}
                                  component={CustomInput}
                                  validator={nameValidator}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formBoxRow">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="formBoxLabel fs-14">
                              {trans.fetchLabelKeyTranslation(
                                      "LineManagerTitle",
                                      "Line Manager"
                                    )}
                            </div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                <Field
                                  id="manager"
                                  name="manager"
                                  data={masterData?.users.filter(
                                    (user: TinyUser) => !user.isArchived
                                  )}
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter("manager")}
                                  component={CustomComboBox}
                                  itemRender={itemRender}
                                  textField="email"
                                  filtering={true}
                                  sorting={true}
                                  sortField={"email"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="formBoxLabel fs-14">
                              {trans.fetchLabelKeyTranslation(
                                      "EmailTitle",
                                      "Email"
                                    )}{" "}
                              *
                            </div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                <Field
                                  id="email"
                                  name="email"
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter("email")}
                                  placeholder={trans.fetchLabelKeyTranslation(
                                    "EmailPlaceHolder",
                                    "Enter Email"
                                  )}
                                  component={CustomInput}
                                  validator={emailValidator}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="UserBoxLabel float-left w-100">
                    <div className="formBoxRow fs-14 font-weight-bold">
                      {trans.fetchLabelKeyTranslation(
                              "LoginDetailsTitle",
                              "Login"
                            )}
                    </div>
                    <div className="formBoxRow d-flex justify-content-between p-t-7 p-b-7">
                      <div className="formBoxLabel fs-14">{trans.fetchLabelKeyTranslation(
                              "EnableLoginTitle",
                              "Enable Login"
                            )}</div>
                      <div className="formBoxAction">
                        <div className="switchButton">
                          <Switch
                            checked={loginEnabled}
                            onChange={updateLoginEnabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UserBoxLabel float-left w-100 m-b-8">
                    <div className="formBoxRow fs-14 font-weight-bold">
                      {trans.fetchLabelKeyTranslation(
                              "PermissionsTitle",
                              "Permissions"
                            )}
                    </div>
                    <div className="formBoxRow p-t-5 p-b-5">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-col p-b-5">
                            <div className="formBoxLabel fs-14">
                              {trans.fetchLabelKeyTranslation(
                                      "AuthorizationProfileTitle",
                                      "Permission Groups"
                                    )}{" "}
                              *
                            </div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                <Field
                                  id="authProfile"
                                  name="authProfile"
                                  data={authProfiles}
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter(
                                    "authProfile"
                                  )}
                                  component={CustomComboBox}
                                  textField="localizationValue"
                                  filtering={true}
                                  validator={authProfileValidator}
                                  sorting={true}
                                  sortField={"localizationValue"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UserBoxLabel float-left w-100">
                    <div className="formBox">
                      <div className="formBoxRow fs-14 font-weight-bold">
                        {trans.fetchLabelKeyTranslation(
                                "AddMorePeopleToPlaybackNetworkTitle",
                                "Edit Playback Network"
                              )}
                      </div>
                      <div className="formBoxRow p-t-5 p-b-1">
                        <div className="formBoxLabel fs-14">{trans.fetchLabelKeyTranslation(
                                "AddViaPeopleTitle",
                                "Via People"
                              )}</div>
                        <div className="formBoxAction">
                          <div className="formInput">
                            <Field
                              id="addViaPeople"
                              name="addViaPeople"
                              data={masterData?.data?.recordingNetworkAccessibilties.filter(
                                (accessbility: RecordingNetworkAccessibilty) =>
                                  accessbility.id !== 4 ||
                                  auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageSecurityControl
                                  )
                              )}
                              style={{ height: "32px" }}
                              value={formRenderProps.valueGetter(
                                "addViaPeople"
                              )}
                              component={CustomComboBox}
                              textField="localizationValue"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="formBoxRow p-t-5 p-b-15">
                        <div className="formBoxLabel fs-14">
                          {trans.fetchLabelKeyTranslation(
                                  "AddViaUserGroupTitle",
                                  "Via User Groups"
                                )}
                        </div>
                        <div className="formBoxAction">
                          <div className="formInput">
                            <Field
                              id="addViaGroup"
                              name="addViaGroup"
                              data={userGroups}
                              // style={{ height: "32px" }}
                              value={formRenderProps.valueGetter("addViaGroup")}
                              component={CustomMultiSelect}
                              textField="name"
                              filtering={true}
                              sorting={true}
                              sortField={"name"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button className="btn bg-black-5" onClick={toggleDialog}>
                {trans.fetchLabelKeyTranslation("CancelButton", "Cancel")}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                onClick={submitHandler}
                disabled={!formRenderProps.valid}
              >
                {trans.fetchLabelKeyTranslation("SaveButton", "Save")}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default AddUserDialog;
