import { TinyUser } from "../../types/user";

const fetchUsersFromCommentText = (commentText: string,allUsers:TinyUser[]|undefined) => {
    const users: TinyUser[] = [];
    commentText.split(" ").map((comment: string) => {
      if (
        comment.length > 4 &&
        comment[0] === "@" &&
        comment[1] === "[" &&
        comment[comment.length - 2] === "]" &&
        comment[comment.length - 1] === "*"
      ) {
        let newComment = comment.slice(2, comment.length - 2);
        const user = allUsers?.find((user) => user.email === newComment);
        if (user && !users.find((u) => u.id === user.id)) users.push(user);
      }
    });

    return users;
  };

  const filterCommentText = (commentText: string) => {
    let newComment = "";
    commentText.split(" ").map((cmt: string) => {
      if (
        cmt.length > 4 &&
        cmt[0] === "@" &&
        cmt[1] === "[" &&
        cmt[cmt.length - 2] === "]" &&
        cmt[cmt.length - 1] === "*"
      ) {
        newComment += `${cmt.slice(2, cmt.length - 2)}`;
      } else {
        newComment += `${cmt} `;
      }
    });
    return newComment;
  };

  export {filterCommentText,fetchUsersFromCommentText}