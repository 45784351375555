import "./error.scss";
import erro500img from "../../assets/images/500-error.gif";
import { Link } from "react-router-dom";

const InternalServerError = () => {
  return (
    <div className="errorContainer float-left w-100 text-center">
      <div className="errorImg">
        <img
          src={erro500img}
          style={{ height: "120px", width: "auto" }}
          alt={"Something went wrong! Please try again..."}
        />
      </div>
      <div className="errorTxt">
        <div className="errorTxt-h fs-30 font-weight-semi p-b-20">
          Internal server error
        </div>
        <div className="errorTxt-p fs-16 p-b-20">
          Please try again later or feel free to contact us if the problem
          persists.
        </div>
        <div className="errorBtn">
          <Link
            className="fs-15 bg-primary text-white padding-6 p-l-15 p-r-15"
            replace
            to="/home"
          >
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default InternalServerError;
