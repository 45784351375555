import React, { useEffect } from "react";
import { TinyUser } from "../../../types/user/TinyUser";
import { concatUsername, getRandomProfileColor } from "../../../utils/profileUtils";
import { IsValidString } from "../../../utils/stringUtils";

interface navigateToProfile {
  navigate: boolean;
  navigateFunction: () => void;
}

type CustomUserProp = {
  label?: string | undefined;
  userInfo: TinyUser;
  selected?: boolean;
  iconCls?: string;
  navigateToProfile?: navigateToProfile;
};

const CustomUserGridInfo: React.FC<CustomUserProp> = ({
  label,
  userInfo,
  iconCls,
  navigateToProfile,
}) => {
  var bgClassName = getRandomProfileColor(userInfo.id);
  var firstName = IsValidString(userInfo.firstName) ? userInfo.firstName : "";
  var lastName = IsValidString(userInfo.lastName) ? userInfo.lastName : "";
  var userName = `${firstName} ${lastName}`.trim();

  var isImageAvailable = IsValidString(userInfo.image);
  var isUserNameAvailable = IsValidString(userName);
  var isEmailAvailable = IsValidString(userInfo.email);
  var isPhoneNumberAvailable = IsValidString(userInfo.phoneNo);
  var userInitials = isUserNameAvailable
    ? userName
        .split(" ")
        .map((n: string) => n[0])
        .join("")
    : "";

  var header = isUserNameAvailable
    ? concatUsername(
      userInfo.firstName ? userInfo.firstName : "",
      userInfo.lastName ? userInfo.lastName : ""
    )
    : isEmailAvailable
    ? userInfo.email
    : userInfo.phoneNo;
  var content = isUserNameAvailable
    ? isEmailAvailable
      ? userInfo.email
      : userInfo.phoneNo
    : "";

  return (
    <div className="tblUsr">
      <div className="topUsrAreaPic">
        <div className="topUsrAreaPic-i">
          <div className={"contact-list-icon " + bgClassName}>
            <div
              className={`contact-list-icon-txt ${
                navigateToProfile?.navigate ? "cursor-pointer" : ""
              }`}
              onClick={
                navigateToProfile && navigateToProfile?.navigate
                  ? navigateToProfile.navigateFunction
                  : () => {}
              }
            >
              {isImageAvailable && (
                <div className="contact-list-icon-img ">
                  <img
                    src={require("../../../assets/images/user-img-2.jpg")}
                    alt={"user"}
                  />
                </div>
              )}
              {!isImageAvailable && isUserNameAvailable && userInitials}
              {!isImageAvailable && !isUserNameAvailable && (
                <span className="fs-16">
                  <i className={`${iconCls ? iconCls : "bi bi-telephone"}`}></i>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="topUsrAreaPic-tx hoverTextFull">
          {label !== undefined && label.trim() !== "" && (
            <span className="fs-13 font-weight-semi line-height-1">
              {label}
            </span>
          )}
          <span
            onClick={
              navigateToProfile && navigateToProfile?.navigate
                ? navigateToProfile.navigateFunction
                : () => {}
            }
            className={`text-primary font-weight-semi ${
              navigateToProfile?.navigate ? "cursor-pointer" : "cursor-default"
            } text-decoration-none float-left w-100 line-height-2 mx-td-spn viewFullWordHover`}
            title={header}
          >
            {header}
          </span>
          <span
            onClick={
              navigateToProfile && navigateToProfile?.navigate
                ? navigateToProfile.navigateFunction
                : () => {}
            }
            className={`text-muted fs-13 line-height-2 mx-td-spn viewFullWordHover ${
              navigateToProfile?.navigate ? "cursor-pointer" : "cursor-default"
            }`}
            title={content}
          >
            {content}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomUserGridInfo;
