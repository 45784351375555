import { CallDetail } from "../types/call";
import { IsValidString } from "./stringUtils";

export const searchInTopics = (data: CallDetail, searchText: string): boolean => {
    let result = false;
    
    data?.recordings?.forEach((recording) => {
        recording.topics !== null && recording.topics.forEach((topic) => {
            if (topic.topicName?.name?.toLocaleLowerCase().includes(searchText)) {
                result = true;
            }
        });
    });
    return result;
};

export const searchCallData = (data: CallDetail, searchTerm: string): boolean => {
    const searchText = searchTerm.trim().toLocaleLowerCase();
    let firstName = IsValidString(data?.firstParty?.firstName)
        ? data?.firstParty?.firstName
        : "";
    let lastName = IsValidString(data?.firstParty?.lastName)
        ? data?.firstParty?.lastName
        : "";
    let firstPartyName = `${firstName} ${lastName}`.trim();
    
    if (
        data?.firstParty?.email?.toLocaleLowerCase().includes(searchText) ||
        firstPartyName?.toLocaleLowerCase().includes(searchText) ||
        data?.firstParty?.phoneNo?.toLocaleLowerCase().includes(searchText) ||
        data?.secondParties?.filter(e => e.email?.toLocaleLowerCase().includes(searchText)).length > 0 ||
        data?.secondParties?.filter(e => e.firstName?.toLocaleLowerCase().includes(searchText)).length > 0 ||
        data?.secondParties?.filter(e => e.lastName?.toLocaleLowerCase().includes(searchText)).length > 0 ||
        data?.secondParties?.filter(e => e.phoneNo?.toLocaleLowerCase().includes(searchText)).length > 0 ||
        data?.platform?.name.toLocaleLowerCase().includes(searchText) ||
        data?.callResult?.name.toLocaleLowerCase().includes(searchText) ||
        data?.reasonCode?.name.toLocaleLowerCase().includes(searchText) ||
        data?.callTag?.toLocaleLowerCase().includes(searchText) ||
        searchInTopics(data, searchText)
    ) {
        return true;
    } else {
        return false;
    }
};
