interface CardWordFilterProps {
    selectedAlphabet: string;
    handleAlphabetFilterChange : (value: string) => void
  }

const CardWordFilter = (filterprops : CardWordFilterProps) =>
{
    const alphabetFilter: string[] = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];

    return (
        <div className="cardWordFilter">
        <div className="wordFilterTitle">All</div>
        <div className="wordFilterBox" >
          {alphabetFilter.map((e) => {            
            return (
              <div
                className= "wordFilterLetter"
                onClick={() => filterprops.handleAlphabetFilterChange(e)}
                key={e}                          
                id= {e}
              >
                {e}
              </div>
            );
          })}
        </div>
      </div>
    );
};

export default CardWordFilter;