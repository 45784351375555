import { Group } from "../../../types/user-groups/Group";

interface IGroupGripListProps {
    group: Group;
}
const CustomGridGroupInfo : React.FC<IGroupGripListProps> = ({
    group,
}) => {
    return (
        <div className="UserRow w-100 float-left p-t-10 p-b-10 border-bottom-solid border-w-1 border-black-1">
          <div className="tblUsr">
            <div className="topUsrAreaPic">
              <div className="topUsrAreaPic-i">
                <div className="contact-list-icon bg-darksteelblue">
                  <div className="contact-list-icon-txt">
                    <i className="bi bi-people-fill"></i>
                  </div>
                </div>
              </div>
              <div className="topUsrAreaPic-tx hoverTextFull">
                <span
                  className="text-primary font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2 mx-td-spn viewFullWordHover"
                  title="Lab1 User1"
                >
                  {group.name}
                </span>
                <span
                  className="text-muted fs-13 line-height-2"
                  title="labuser1.tollring"
                >
                  <div className="numIcon float-right d-flex align-items-center">
                    <div className="numIconLi p-r-10">
                      <span className="numIconListI fs-12 text-black-10">
                        Admins:
                      </span>
                      <span className="numIconListTx fs-12 text-black-11 font-weight-semi">
                        {group.groupAdmins.length}
                      </span>
                    </div>
                    <div className="numIconLi">
                      <span className="numIconListI fs-12 text-black-10">
                        Members:
                      </span>
                      <span className="numIconListTx fs-12 text-black-11 font-weight-semi">
                        {group.groupMembers.length}
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
    )
}

export default CustomGridGroupInfo;