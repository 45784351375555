import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
}

const CustomGridCellForCallTag = (props: CustomGridCellProps) => {
  return (
    <td className="mx-th-tag">
      <div
        className="mx-td-spn line-height-2"
        style={{ wordBreak: "break-word" }}
      >
        {props.dataItem.callTag}
      </div>
    </td>
  );
};

export default CustomGridCellForCallTag;
