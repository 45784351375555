import moment from "moment";

export interface Dates{
    tempStartDate:Date;
    tempEndDate:Date;
}

export const getDateBasedOnFilterType=(period:number,filter:number):Dates=>{
  let tempStartDate = new Date();
  tempStartDate.setHours(0,0,0,0);
  let tempEndDate = new Date();
  tempEndDate.setHours(23,59,59,999);
    switch (period) {
        // Day Filter
        case 1: {
          switch (filter) {
            // Today
            case 1: {
              break;
            }
            // Yesterday
            case 2: {
              tempStartDate.setDate(tempStartDate.getDate() - 1);
              tempEndDate.setDate(tempEndDate.getDate() - 1);
              break;
            }
            // Last 7 Days
            case 3: {
              tempStartDate.setDate(tempStartDate.getDate() - 6);
              tempEndDate.setDate(tempEndDate.getDate());
              break;
            }
            // Last 30 Days
            case 4: {
              tempStartDate.setDate(tempStartDate.getDate() - 29);
              tempEndDate.setDate(tempEndDate.getDate());
              break;
            }
          }
          break;
        }
        // Week Filter
        case 2: {
          switch (filter) {
            // This week
            case 1: {
              const startOfWeek = moment().startOf("isoWeek").toDate();
              tempStartDate.setMonth(startOfWeek.getMonth());
              tempStartDate.setDate(startOfWeek.getDate());
              break;
            }
            // Last Week
            case 2: {
              const startOfLastWeek = moment()
                .subtract(1, "weeks")
                .startOf("isoWeek")
                .toDate();
              tempStartDate.setMonth(startOfLastWeek.getMonth());
              tempStartDate.setDate(startOfLastWeek.getDate());
              const endOfLastWeek = moment()
                .subtract(1, "weeks")
                .endOf("isoWeek")
                .toDate();
              tempEndDate.setMonth(endOfLastWeek.getMonth());
              tempEndDate.setDate(endOfLastWeek.getDate());
              break;
            }
          }
          break;
        }
        // Month Filter
        case 3: {
          switch (filter) {
            // This Month
            case 1: {
              const startOfMonth = moment().startOf("month").toDate();
              tempStartDate.setDate(startOfMonth.getDate());
              break;
            }
            // Last Month
            case 2: {
              const startOfLastMonth = moment()
                .subtract(1, "months")
                .startOf("month")
                .toDate();
              tempStartDate.setFullYear(startOfLastMonth.getFullYear());
              tempStartDate.setMonth(startOfLastMonth.getMonth());
              tempStartDate.setDate(startOfLastMonth.getDate());
  
              const endOfLastMonth = moment()
                .subtract(1, "months")
                .endOf("month")
                .toDate();
              tempEndDate.setFullYear(endOfLastMonth.getFullYear());
              tempEndDate.setMonth(endOfLastMonth.getMonth());
              tempEndDate.setDate(endOfLastMonth.getDate());
  
              break;
            }
          }
          break;
        }
        // Custom Filter
        default:
          break;
      }
      return {tempStartDate,tempEndDate}
}