import "./CallSummary.scss";
import { formatDateTime, formatSecondsToTime } from "../../utils/dateTimeUtils";
import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import CustomFormTextArea from "../../components/custom/form/CustomFormTextArea";
import { CallDetail, CallDetailPropUpdateInput } from "../../types/call";
import React from "react";
import { Loader } from "@progress/kendo-react-indicators";
import CustomComboBox from "../../components/custom/form/CustomComboBox";
import { ColorCategory } from "../../types/master-data";
import { ArchivableTinyObject } from "../../types/ArchivableTinyObject";
import useMasterData from "../../hooks/useMasterData";
import useAuth from "../../hooks/useAuth";
import customerDefinedValuesService from "../../services/customerDefinedValues.service";
import callsService from "../../services/calls.service";
import useLocale from "../../hooks/useLocale";
import { Dictionary } from "../../types/Dictionary";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CallAdditionalInfoDialog from "./CallAdditionalInfoDialog";
import { axiosWithAuth } from "../../utils/customAxios";
import { ReasonCodeInstance } from "../../types/call/CallResultReasonCode";
import { AxiosError } from "axios";
import { SweetAlertOptions } from "sweetalert2";
import useSwal from "../../hooks/useSwal";
import LoadingOverlay from "../../components/LoadingOverlay";
import reasonCodeService from "../../services/reasonCode.service";

interface CallSummaryProps {
  callDetail: CallDetail;
  shared?: boolean;
  onUpdateCallDetailProperties: (updatedCallDetail: CallDetail) => void;
}

interface IFormValue {
  callTag: string;
  callResult?: ArchivableTinyObject;
  reasonCode: ReasonCodeInstance | undefined;
}

const CallSummary: React.FC<CallSummaryProps> = ({
  callDetail,
  shared,
  onUpdateCallDetailProperties,
}) => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const masterData = useMasterData();
  const formRef = useRef<any>();
  const colorCategories = masterData?.data?.colorCategories;
  const [callResOptions, setCallResOptions] = useState<
    ArchivableTinyObject[] | undefined
  >();
  const [callResOptionsLoading, setCallResOptionsLoading] =
    useState<boolean>(true);
  const [patchUpdateLoading, setPatchUpdateLoading] = useState<boolean>(false);
  const [patchUpdateError, setPatchUpdateError] = useState<
    string | undefined
  >();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["CallSummary"]);
  const [formInitialData, setFormInitialData] = useState<IFormValue>({
    callTag: callDetail.callTag ? callDetail.callTag : "",
    callResult:
      callDetail.callResult === null ? undefined : callDetail.callResult,
    reasonCode:
      callDetail.reasonCode === null ? undefined : callDetail.reasonCode,
  });
  const [reasonCodes, setReasonCodes] = useState<ReasonCodeInstance[]>([]);
  const [reasonCodeLoader, setReasonCodeLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);

  const callTagValidator = (value: string) => {
    if (value.length > 1000) {
      return "Comment length cannot be more than 1000!";
    }

    return "";
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["CallSummary"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("CallSummary");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["CallSummary"]
      );
      localeCtx?.setPreviousAppLocale("CallSummary");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    const sendRequest = async () => {
      try {
        setCallResOptionsLoading(true);
        const data =
          await customerDefinedValuesService.fetchCustomerDefinedCallResults(
            shared === true
          );
        setCallResOptions(
          data.filter((d: ArchivableTinyObject) => {
            return !d.isArchived;
          })
        );
      } catch (err) {
        if (err instanceof Error) {
          console.error(err);
        }
      } finally {
        setCallResOptionsLoading(false);
      }
    };

    sendRequest();
  }, []);

  useEffect(() => {
    setFormInitialData({
      callTag: callDetail.callTag ? callDetail.callTag : "",
      callResult: callDetail.callResult,
      reasonCode: callDetail.reasonCode ? callDetail.reasonCode : undefined,
    });
  }, [callDetail]);

  useEffect(() => {
    const fetchReasonCodes = async () => {
      setReasonCodeLoader(true);
      try {
        const data = await reasonCodeService.getReasonCodesForOutcome(
          callDetail.callResult?.id ?? 0
        );
        setReasonCodes(data.filter((d: ReasonCodeInstance) => !d.isArchived));
        setReasonCodeLoader(false);
        setError("");
      } catch (error) {
        setReasonCodes([]);
        setReasonCodeLoader(false);
        if (error instanceof AxiosError) {
          setError(error.response && error.response.data.error);
        }
      }
    };
    if (callDetail.callResult && (!shared || (shared && auth?.accessToken))) {
      fetchReasonCodes();
    }
  }, []);

  const submitHandler = (dataItem: { [name: string]: any }) => {
    const { callTag, callResult, reasonCode } = dataItem;
    const updatedCallDetailInput: CallDetailPropUpdateInput = {
      tag: callTag ? callTag : "",
      callResultText: callResult ? callResult.name : null,
      reasonCodeName: reasonCode ? reasonCode.name : null,
    };
    setPatchUpdateLoading(true);
    callsService
      .updateCallDetailCustDefinedProperties(
        callDetail.platformCallDetailId,
        updatedCallDetailInput,
        shared === true
      )
      .then((res) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
          title: `${
            translationsLoading
              ? "Outcome Updated"
              : fetchLabelKeyTranslation(
                  "SwtAltupdateDetailsTitle",
                  "Outcome Updated"
                )
          }`,
        };
        swal.fire(swalOptions);
        onUpdateCallDetailProperties(res);
      })
      .catch((err) => {
        if (err instanceof Error) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "error",
            title: `${
              translationsLoading
                ? "Oops..."
                : fetchLabelKeyTranslation(
                    "SwtAltupdateDetailsFailedTitle",
                    "Oops..."
                  )
            }`,
            text: `${
              translationsLoading
                ? "Something went wrong!"
                : fetchLabelKeyTranslation(
                    "SwtAltupdateDetailsText",
                    "Something went wrong!"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
          setPatchUpdateError(err.message);
          const form = formRef.current as Form;
          form.resetForm();
        }
      })
      .finally(() => {
        setPatchUpdateLoading(false);
      });
  };

  const onCancelHandler = () => {
    const form = formRef.current as Form;
    form.resetForm();
  };

  const outcomeFieldHandler = async (
    e: any,
    formRenderProps: FormRenderProps
  ) => {
    formRenderProps.onChange("reasonCode", { value: null });
    formRenderProps.onChange("callResult", { value: e.value });
    if (e.value) {
      try {
        setReasonCodeLoader(true);
        const data = await reasonCodeService.getReasonCodesForOutcome(
          e.value.id
        );
        setReasonCodes(data.filter((d: ReasonCodeInstance) => !d.isArchived));
        setReasonCodeLoader(false);
        setError("");
      } catch (error) {
        setReasonCodes([]);
        setReasonCodeLoader(false);
        if (error instanceof AxiosError) {
          setError(error.response && error.response.data.error);
        }
      }
    }
  };

  const compareState = () => {
    const form = formRef.current as Form;

    if (
      callDetail.callResult?.name == form.values.callResult?.name &&
      callDetail.reasonCode?.name == form.values.reasonCode?.name &&
      callDetail?.callTag == form.values?.callTag
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <div>
      {patchUpdateLoading && (
        <LoadingOverlay
          customStyle={{ position: "fixed", marginTop: "55px" }}
          themeColor={"light"}
          size={"medium"}
        />
      )}
      <Form
        ref={formRef}
        key={JSON.stringify(formInitialData)}
        onSubmit={submitHandler}
        initialValues={formInitialData}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <div className="row">
              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group m-b-10">
                      <div className="fs-13 font-weight-semi p-b-2">
                        {`${
                          translationsLoading
                            ? "Outcome"
                            : fetchLabelKeyTranslation(
                                "FieldOutcome",
                                "Outcome"
                              )
                        }`}
                      </div>
                      <div className="formInput">
                        <Field
                          name={"callResult"}
                          data={callResOptions?.filter(
                            (item) => item.isArchived === false
                          )}
                          disabled={
                            (!auth?.accessToken && shared === true) ||
                            reasonCodeLoader
                          }
                          textField="name"
                          value={formRenderProps.valueGetter("callResult")}
                          loading={callResOptionsLoading}
                          component={CustomComboBox}
                          dataItemKey="id"
                          onChange={(e) => {
                            outcomeFieldHandler(e, formRenderProps);
                            compareState();
                          }}
                          sorting={true}
                          sortField={"name"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group m-b-10">
                      <div className="fs-13 font-weight-semi p-b-2">
                        {`${
                          translationsLoading
                            ? "Reason Code"
                            : fetchLabelKeyTranslation(
                                "FieldReasonCode",
                                "Reason Code"
                              )
                        }`}
                      </div>
                      <div className="formInput">
                        <Field
                          name={"reasonCode"}
                          data={reasonCodes}
                          disabled={
                            (!auth?.accessToken && shared === true) ||
                            reasonCodeLoader ||
                            reasonCodes.length === 0
                          }
                          textField="name"
                          value={formRenderProps.valueGetter("reasonCode")}
                          component={CustomComboBox}
                          loading={reasonCodeLoader}
                          dataItemKey="id"
                          sorting={true}
                          sortField={"name"}
                          onChange={compareState}
                        />
                      </div>
                      <div>
                        {error !== "" && (
                          <p style={{ color: "red", fontSize: "12px" }}>
                            {error}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <div className="fs-13 font-weight-semi p-b-2">{`${
                    translationsLoading
                      ? "Notes"
                      : fetchLabelKeyTranslation("FieldNotes", "Notes")
                  }`}</div>
                  <div className="formInput">
                    <Field
                      id={"callTag"}
                      name={"callTag"}
                      className="form-control"
                      style={{ height: "auto" }}
                      placeholder={`${
                        translationsLoading
                          ? "Type notes here..."
                          : fetchLabelKeyTranslation(
                              "FieldNotesPlaceholder",
                              "Type notes here..."
                            )
                      }`}
                      disabled={
                        (!auth?.accessToken && shared === true) ||
                        reasonCodeLoader
                      }
                      max={1000}
                      autoSize={true}
                      rows={3}
                      showTextLimitHint={false}
                      value={formRenderProps.valueGetter("callTag")}
                      component={CustomFormTextArea}
                      validator={callTagValidator}
                      onChange={compareState}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <Button
                  className={`btn line-height-1 bg-black-5 fs-16 ${
                    !formRenderProps.modified ? "disabledBtn" : ""
                  }`}
                  style={{
                    height: "34px",
                    marginRight: "2px",
                    marginTop: "10px",
                  }}
                  onClick={onCancelHandler}
                  disabled={
                    !formRenderProps.modified ||
                    (!auth?.accessToken && shared === true) ||
                    reasonCodeLoader
                  }
                >
                  {`${
                    translationsLoading
                      ? "Cancel"
                      : fetchLabelKeyTranslation("CancelText", "Cancel")
                  }`}
                </Button>
                <Button
                  className={`btn bg-primary text-white fs-16 ${
                    !formRenderProps.allowSubmit ||
                    (!auth?.accessToken && shared === true) ||
                    buttonStateTracker
                      ? "disabledBtn"
                      : ""
                  }`}
                  style={{ marginTop: "10px" }}
                  type="submit"
                  disabled={
                    !formRenderProps.allowSubmit ||
                    (!auth?.accessToken && shared === true) ||
                    reasonCodeLoader ||
                    buttonStateTracker
                  }
                >
                  {patchUpdateLoading ? (
                    <Loader
                      size={"small"}
                      type={"infinite-spinner"}
                      themeColor={"light"}
                    />
                  ) : (
                    `${
                      translationsLoading
                        ? "Save"
                        : fetchLabelKeyTranslation("SaveText", "Save")
                    }`
                  )}
                </Button>
              </div>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
};

export default CallSummary;
