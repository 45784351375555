import { Outlet } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import Footer from "./Footer";
import Header from "./Header";

const Layout: React.FC = () => {
  const [userBehaviourTracking, setUserBehaviourTracking] = useLocalStorage<
    string | undefined
  >("UserBehaviourTracking", undefined);
  return (
    <div
      className={`container-fluid px-0 ${
        userBehaviourTracking ? "" : "mBottom70"
      }`}
    >
      <div className="row no-gutters min-vh-100">
        <div className="col-md-12">
          <Header />
          <Outlet />
          <Footer></Footer>
        </div>
      </div>
    </div>
  );
};

export default Layout;
