import React from "react";

type ICallDirectionProps = {
  callDirection: string;
  callType: string;
  iconSize?: number | undefined;
  isMobileView?: boolean
};

const CallDirection: React.FC<ICallDirectionProps> = ({ callDirection, callType, iconSize, isMobileView = false}) => {
  var fontSize = iconSize !== undefined && iconSize > 0 ? iconSize : 18;
  return (
    <>
      {callDirection === "Received" && callType === "External" && (
        <span className={`fs-${fontSize} tx-green`} title="Received">
          { isMobileView ? "Received" : <i className="bi bi-arrow-left"></i> }
        </span>
      )}
      {callDirection === "Dialled" && callType === "External" && (
        <span className={`fs-${fontSize} text-info`} title="Dialled">
          { isMobileView ? "Dialled" : <i className="bi bi-arrow-right"></i> }
        </span>
      )}
      {callDirection === "Received" && callType === "Internal" && (
        <span className={`fs-${fontSize} text-muted`} title="Internal Received">
          { isMobileView ? "Internal Received" : <i className="bi bi-arrow-left"></i> }
        </span>
      )}
      {callDirection === "Dialled" && callType === "Internal" && (
        <span className={`fs-${fontSize} text-muted`} title="Internal Dialled">
          { isMobileView ? "Internal Dialled" : <i className="bi bi-arrow-right"></i> }
        </span>
      )}
    </>
  );
};

export default CallDirection;
