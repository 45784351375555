import { Page } from "../types/Page";
import {
  Insight,
  InsightCallDetails,
  InsightCallFilterMetrics,
  InsightMetricKeyLabel,
  InsightsFilter,
  InsightViewByTblData,
} from "../types/insight";
import { axiosWithAuth } from "../utils/customAxios";
import { InsightFilterDates } from "../types/insight/InsightFilterDates";

const insightsService = {
  upsertUserInsight: async (filter: InsightsFilter): Promise<Insight> => {
    const response = await axiosWithAuth.post("/insight", filter);
    const userInsight: Insight = response.data;

    return userInsight;
  },

  getUserInsightById: async (insightId: string): Promise<Insight> => {
    const response = await axiosWithAuth.get(`insight/${insightId}`);
    const insight: Insight = response.data;

    return insight;
  },

  deleteUserInsight: async (insightId: string): Promise<boolean> => {
    const response = await axiosWithAuth.delete(`/insight/${insightId}`);
    const success: boolean = response.data;

    return success;
  },

  getInsightCallDetails: async (
    metricFilter: InsightCallFilterMetrics,
    page: Page,
    searchTerm: string
  ): Promise<InsightCallDetails> => {
    let requestQuery = "?";
    const queryFilters: string[] = [];
    if (metricFilter.filterKey && metricFilter.filterValue) {
      let filterKeyValQuery = `filterKey=${metricFilter.filterKey}&filterValue=${metricFilter.filterValue}`;
      if (metricFilter.filterSubKey) {
        filterKeyValQuery += `&filterSubKey=${metricFilter.filterSubKey}`;
      }
      if (filterKeyValQuery.trim() !== "") queryFilters.push(filterKeyValQuery);
    }
    if (metricFilter.viewBy && metricFilter.viewByFilterValue) {
      let viewBykeyValQuery = `viewBy=${metricFilter.viewBy}&viewByFilterValue=${metricFilter.viewByFilterValue}`;
      if (metricFilter.viewByFilterSubKey) {
        viewBykeyValQuery += `&viewByFilterSubKey=${metricFilter.viewByFilterSubKey}`;
      }
      if (viewBykeyValQuery.trim() !== "") queryFilters.push(viewBykeyValQuery);
    }
    const pageFilter = (page) ? `skip=${page.skip}&take=${page.take}` : "";
    if (pageFilter.trim() !== "") queryFilters.push(pageFilter);

    const sortFilter = (page?.sort)
      ? `sortField=${page.sort.sortField}&sortDirection=${page.sort.sortDirection}` : "";
    if (sortFilter.trim() !== "") queryFilters.push(sortFilter);

    const searchFilter = searchTerm ? `searchTerm=${encodeURIComponent(searchTerm)}` : "";
    if (searchFilter.trim() !== "") queryFilters.push(searchFilter);

    requestQuery += queryFilters.join("&");

    const response = await axiosWithAuth.get(
      `/insight/${metricFilter.insightId}/calldetail${requestQuery}`
    );
    const callDetails: InsightCallDetails = response.data;

    return callDetails;
  },

  getInsightMetricLabels: async (viewBy: boolean = false) => {
    const response = await axiosWithAuth.get(
      `/insight/metrics?viewBy=${viewBy}`
    );
    const metricLabels: InsightMetricKeyLabel[] = response.data;

    return metricLabels;
  },

  getInsightViewByFilteredData: async (
    insightId: string,
    viewBy: string,
    filterKey?: string,
    filterSubKey?: string,
    filterValue?: string,
    filterDates? : InsightFilterDates
  ): Promise<InsightViewByTblData> => {
    let requestQuery = "";
    if (filterKey && filterValue) {
      requestQuery += `&filterKey=${filterKey}&filterValue=${filterValue}`;
      if (filterSubKey) {
        requestQuery += `&filterSubKey=${filterSubKey}`;
      }
    }

    if(filterDates){
      requestQuery += `&filterDates=${JSON.stringify(filterDates)}`;
    }
    const response = await axiosWithAuth.get(
      `/insight/${insightId}/viewBy?viewBy=${viewBy}${requestQuery}`
    );
    const insightViewByTblData: InsightViewByTblData = response.data;

    return insightViewByTblData;
  },
};

export default insightsService;
