import { MasterData } from "../types/master-data";
import { axiosWithAuth } from "../utils/customAxios";

const masterDataService = {
  getMasterData: async (keys: string[]): Promise<MasterData> => {
    let requestQuery = "";
    if (keys.length > 0) {
      requestQuery += "?" + keys.map((k) => `categoryName=${k}`).join("&");
    }
    const response = await axiosWithAuth.get(`/masterData${requestQuery}`);
        const masterData = response.data;

    return masterData;
  },
};

export default masterDataService;
