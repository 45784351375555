import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { AxiosError } from "axios";
import { useRef, useState, useEffect, BaseSyntheticEvent } from "react";
import CustomInput from "../../../../components/custom/form/CustomInput";
import { StorageModes } from "../../../../enums";
import storageService from "../../../../services/storage.service";
import {
  DonutStorageVisualization,
  StorageLocation,
  UpsertStorageLocation,
} from "../../../../types/storage";
import useSwal from "../../../../hooks/useSwal";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import useBranding from "../../../../hooks/useBranding";
import StorageVisualizationChart from "../StorageVisualizationChart";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { storageSizetoKB } from "../StorageSettings";

interface AzureStorageLocationProps {
  data?: StorageLocation;
  configuring: boolean;
  onUserAddStorageToggle: () => void;
  onStorageLocationUpserted: (
    upsertedStorageLocation: StorageLocation,
    updated: boolean
  ) => void;
  onActivateStorageLocation?: (storageLocationId: number) => void;
  disabled: boolean;
}

const AzureStorageLocation: React.FC<AzureStorageLocationProps> = ({
  data,
  configuring,
  onUserAddStorageToggle,
  onStorageLocationUpserted,
  onActivateStorageLocation,
  disabled,
}) => {
  const brandingCtx = useBranding();
  const formRef = useRef<any>();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const [editing, setEditing] = useState<boolean>(false);
  const [upserting, setUpserting] = useState<boolean>(false);

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
    "AzureStorageLocation"
    ]
  );
  const [buttonStateTracker, setButtonStateTracker] = useState<boolean>(true);
  const [testingConnection, setTestingConnection] = useState<boolean>(false);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
      "AzureStorageLocation"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "AzureStorageLocation"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
        "AzureStorageLocation"
        ]
      );
      localeCtx?.setPreviousAppLocale("AzureStorageLocation");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const [donutStorage, setDonutStorage] = useState<DonutStorageVisualization[]>(
    [
      {
        field: `${translationsLoading
          ? "Free"
          : fetchLabelKeyTranslation("FreeText", "Free")
          }`,
        spacePercentage: undefined,
        spaceInGB: undefined,
        color: "grey",
        legend: "",
      },
      {
        field: `${translationsLoading
          ? "Used"
          : fetchLabelKeyTranslation("UsedText", "Used")
          }`,
        spacePercentage: undefined,
        spaceInGB: undefined,
        color: brandingCtx?.branding?.themeColors[0].colorValue ?? "red",
        legend: "",
      },
    ]
  );

  const onUserEditingToggle = () => {
    setEditing((prevState) => !prevState);
    if (configuring) {
      onUserAddStorageToggle();
    }
    const form = formRef.current as Form;
    form.resetForm();
  };

  const onTestConnection = async () => {
    try {
      const form = formRef.current as Form;
      setTestingConnection(true);
      const storageLocation: UpsertStorageLocation = {
        name: form.valueGetter("name"),
        mode: StorageModes.AzureBlob,
        detail: {
          keys: {
            ConnectionString: form.valueGetter("connectionString"),
            ContainerName: form.valueGetter("containerName"),
          },
        },
        totalSizeInBytes:
          form.valueGetter("totalSizeInBytes") * storageSizetoKB,
      };

      const isSuccessful: boolean = await storageService.testStorageLocation(
        storageLocation
      );
      if (isSuccessful) {
        Swal.fire({
          icon: "success",
          confirmButtonText: `OK`,
          title: "Connection successful.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Connection not successful.",
        });
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Connection not successful due to server error.",
          // text: `${err.message}`,
        });
      }
    } finally {
      setTestingConnection(false);
    }
  };

  const onSubmitHandler = async (dataItem: { [name: string]: any }) => {
    try {
      setUpserting(true);

      let payload: UpsertStorageLocation = {
        name: dataItem.name,
        mode: StorageModes.AzureBlob,
        totalSizeInBytes: dataItem.totalSizeInBytes * storageSizetoKB,
        detail: {
          keys: {
            ContainerName: dataItem.containerName,
            ConnectionString: dataItem.connectionString,
          },
        },
      };

      const upsertedStorageLocation: StorageLocation = await storageService.upsertStorageLocation(payload, data?.id ?? 0);
      onStorageLocationUpserted(upsertedStorageLocation, data !== undefined);
      setEditing(false);
      Swal.fire({
        icon: "success",
        confirmButtonText: `OK`,
        title: `Azure Storage Location ${data ? "Updated" : "Added"}`,
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        if (err.response?.status === 406) {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Storage with similar details already exists.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err.message}`,
          });
        }
      }
    } finally {
      setUpserting(false);
    }
  };

  const onUserActivateStorage = () => {
    if (onActivateStorageLocation && data) {
      onActivateStorageLocation(data.id);
    }
  };

  const inputFieldValidator = (value: string | number) => {
    if (!value || (typeof value === "string" && value.trim().length === 0)) {
      return `${translationsLoading
        ? "This field is mandatory."
        : fetchLabelKeyTranslation(
          "MandatoryFieldValidationMssg",
          "This field is mandatory."
        )
        }`;
    }
    return "";
  };

  const negativeValueFieldValidator = (value: number) => {
    if (value && (value < 0)) {
      //return "Can not enter negative values."
      return `${translationsLoading
        ? "Storage size cannot be negative"
        : fetchLabelKeyTranslation(
          "NegativeStorageValidationMsg",
          "Storage size cannot be negative"
        )
        }`;
    }
    return "";
  };

  const compareState = () => {
    const form = formRef.current as Form;
    if (
      data?.name == form.values.name &&
      data?.totalSizeInBytes ==
      form.values.totalSizeInBytes * storageSizetoKB &&
      data?.detail.keys.ContainerName == form.values.containerName &&
      data?.detail.keys.ConnectionString == form.values.connectionString
    ) {
      setButtonStateTracker(true);
    } else {
      setButtonStateTracker(false);
    }
  };

  return (
    <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
      <div className="visibleDataDiv float-left w-100">
        {data?.id && !editing && (
          <div className="row">
            <div className="col-md-12">
              <div className="float-left w-100 text-right p-t-5">
                {!data.isActive && (
                  <Button
                    className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${disabled ? "disabledBtn" : "border-primary"
                      }`}
                    style={{ height: "26px" }}
                    onClick={onUserActivateStorage}
                    disabled={disabled}
                  >
                    <span className="p-r-5">
                      <>
                        {translationsLoading
                          ? "Activate Storage"
                          : fetchLabelKeyTranslation(
                            "ActivateStorage",
                            "Activate Storage"
                          )}
                      </>
                    </span>
                    <i className="bi bi-check2-circle"></i>
                  </Button>
                )}
                <Button
                  className={`btn bg-transparent text-black-12 text-primary m-r-5 p-r-7 p-l-7 ${disabled ? "disabledBtn" : "border-primary"
                    }`}
                  style={{ height: "26px" }}
                  onClick={onUserEditingToggle}
                  disabled={disabled}
                >
                  <span className="p-r-5">
                    <>
                      {translationsLoading
                        ? "Edit"
                        : fetchLabelKeyTranslation("EditButton", "Edit")}
                    </>
                  </span>
                  <i className="bi bi-pencil"></i>
                </Button>
              </div>
            </div>
          </div>
        )}
        <Form
          onSubmit={onSubmitHandler}
          ref={formRef}
          initialValues={{
            name: data?.name,
            containerName: data?.detail.keys.ContainerName,
            connectionString: data?.detail.keys.ConnectionString,
            totalSizeInBytes: data?.totalSizeInBytes
              ? (data.totalSizeInBytes / storageSizetoKB).toFixed(2)
              : 0,
          }}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-md-5">
                  <div className="trRowData float-left w-100 p-b-5">
                    {(configuring || editing) && (
                      <div className="trk-row">
                        <div className="trkCol d-flex">
                          <div
                            className="trkCol-h font-weight-semi"
                            style={{ minWidth: "150px" }}
                          >
                            <>
                              {translationsLoading
                                ? "Storage Name"
                                : fetchLabelKeyTranslation(
                                  "StorageName",
                                  "Storage Name"
                                )}
                            </>
                          </div>
                          <div className="trkCol-dot">:</div>
                          <div className="trkCol-p border-none">
                            {!configuring && !editing ? (
                              <span>{data?.name}</span>
                            ) : (
                              <Field
                                className="textInput"
                                id={"name"}
                                name={"name"}
                                value={formRenderProps.valueGetter("name")}
                                required={true}
                                placeholder={translationsLoading
                                  ? "Storage Name"
                                  : fetchLabelKeyTranslation(
                                    "StorageName",
                                    "Storage Name"
                                  )}
                                component={CustomInput}
                                validator={inputFieldValidator}
                                onChange={compareState}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Container Name"
                              : fetchLabelKeyTranslation(
                                "ContainerName",
                                "Container Name"
                              )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.ContainerName}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"containerName"}
                              name={"containerName"}
                              value={formRenderProps.valueGetter(
                                "containerName"
                              )}
                              required={true}
                              placeholder={translationsLoading
                                ? "Container Name"
                                : fetchLabelKeyTranslation(
                                  "ContainerName",
                                  "Container Name"
                                )}
                              component={CustomInput}
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Connection String"
                              : fetchLabelKeyTranslation(
                                "ConnectionString",
                                "Connection String"
                              )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{`\u25CF`.repeat(20)}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"connectionString"}
                              name={"connectionString"}
                              style={{ maxWidth: "400px" }}
                              value={formRenderProps.valueGetter(
                                "connectionString"
                              )}
                              required={true}
                              placeholder={translationsLoading
                                ? "Connection String"
                                : fetchLabelKeyTranslation(
                                  "ConnectionString",
                                  "Connection String"
                                )}
                              component={CustomInput}
                              validator={inputFieldValidator}
                              onChange={compareState}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          <>
                            {translationsLoading
                              ? "Storage Size (GB)"
                              : fetchLabelKeyTranslation(
                                "StorageSizeTitle",
                                "Storage Size (GB)"
                              )}
                          </>
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>
                              {!donutStorage.find(
                                (donut: DonutStorageVisualization) =>
                                  donut.field === "Free" &&
                                  donut.spaceInGB === undefined
                              )
                                ? `${data?.totalSizeInBytes &&
                                (
                                  data.totalSizeInBytes / storageSizetoKB
                                ).toFixed(2)
                                }`
                                : "No storage defined"}
                            </span>
                          ) : (
                            <Field
                              type="number"
                              className="textInput"
                              id={"totalSizeInBytes"}
                              name={"totalSizeInBytes"}
                              value={formRenderProps.valueGetter(
                                "totalSizeInBytes"
                              )}
                              onWheel={(e: BaseSyntheticEvent) => e.target.blur()}
                              placeholder={translationsLoading
                                ? "Storage Size (GB)"
                                : fetchLabelKeyTranslation(
                                  "StorageSizeTitle",
                                  "Storage Size (GB)"
                                )}
                              component={CustomInput}
                              min={0}
                              presicion={2}
                              onKeyDown={(evt: any) => {
                                if (
                                  evt.key === "e" ||
                                  evt.key === "+" ||
                                  evt.key === "-"
                                ) {
                                  evt.preventDefault();
                                }
                              }}
                              validator={negativeValueFieldValidator}
                              onChange={compareState}
                              format={"n2"}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <StorageVisualizationChart
                  editing={editing}
                  data={data}
                  donutStorage={donutStorage}
                  setDonutStorage={setDonutStorage}
                  freeLabel={ translationsLoading ? "Free" : fetchLabelKeyTranslation("freeLabelText", "free")}
                  usedLabel={ translationsLoading ? "Used" : fetchLabelKeyTranslation("usedLabelText", "Used")}
                />
              </div>
              {(configuring || editing) && (
                <div className="row">
                  <div className="col-md-12 text-right p-b-5">
                    <div className="btnRightDiv">
                      <Button
                        className="btn bg-black-5 m-r-5"
                        onClick={onUserEditingToggle}
                      >
                        <>
                          {translationsLoading
                            ? "Cancel"
                            : fetchLabelKeyTranslation(
                              "CancelButton",
                              "Cancel"
                            )}
                        </>
                      </Button>
                      <Button
                        type={"button"}
                        className={`btn bg-primary text-white m-r-5 ${!formRenderProps.valid ||
                          testingConnection ||
                          disabled
                          ? "disabledBtn"
                          : ""
                          }`}
                        disabled={
                          !formRenderProps.valid ||
                          testingConnection ||
                          disabled
                        }
                        onClick={onTestConnection}
                      >
                        {testingConnection ? (
                          <Loader
                            size={"small"}
                            type={"infinite-spinner"}
                            themeColor={"light"}
                          />
                        ) : (
                          <>
                            {translationsLoading
                              ? "Test Connection"
                              : fetchLabelKeyTranslation(
                                "TestConnectionButton",
                                "Test Connection"
                              )}
                          </>
                        )}
                      </Button>
                      <Button
                        className={`btn bg-primary text-white ${!formRenderProps.allowSubmit ||
                          !formRenderProps.modified ||
                          !formRenderProps.valid ||
                          buttonStateTracker ||
                          disabled
                          ? "disabledBtn"
                          : ""
                          }`}
                        disabled={
                          !formRenderProps.allowSubmit ||
                          !formRenderProps.modified ||
                          !formRenderProps.valid ||
                          buttonStateTracker ||
                          disabled
                        }
                      >
                        {upserting ? (
                          <Loader
                            size={"small"}
                            type={"infinite-spinner"}
                            themeColor={"light"}
                          />
                        ) : (
                          <>
                            {translationsLoading
                              ? "Update"
                              : fetchLabelKeyTranslation(
                                "UpdateButton",
                                "Update"
                              )}
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default AzureStorageLocation;
