import { StorageLocation,  UpsertStorageLocation } from "../types/storage";
import { axiosWithAuth } from "../utils/customAxios";

const storageService = {
  getStorageLocations: async (): Promise<StorageLocation[]> => {
    const response = await axiosWithAuth.get(`/storage`);
    const storageLocations: StorageLocation[] = response.data;

    return storageLocations;
  },
  upsertStorageLocation: async (
    storageLocation: UpsertStorageLocation, id: number
  ): Promise<StorageLocation> => {

    if (id > 0) {
      const payload: UpsertStorageLocation = {
        name: storageLocation.name,
        mode: storageLocation.mode,
        totalSizeInBytes:Math.trunc(storageLocation.totalSizeInBytes??0),
        detail: storageLocation.detail
      }
      const response = await axiosWithAuth.put(`/storage/${id}`, payload);
      const updatedStorageLocation: StorageLocation = response.data;

      return updatedStorageLocation;
    } else {
      const payload: UpsertStorageLocation = {
        name: storageLocation.name,
        mode: storageLocation.mode,
        totalSizeInBytes:Math.trunc(storageLocation.totalSizeInBytes??0),
        detail: storageLocation.detail
      }
      const response = await axiosWithAuth.post(`/storage`, payload);
      const insertedStorageLocation: StorageLocation = response.data;

      return insertedStorageLocation;
    }
  },
  activateStorageLocation: async (
    storageLocationId: number
  ): Promise<StorageLocation> => {
    const response = await axiosWithAuth.put(
      `/storage/${storageLocationId}/activate`
    );
    const updatedStorageLocation: StorageLocation = response.data;

    return updatedStorageLocation;
  },
  deactivateStorageLocations: async (): Promise<boolean> => {
    const response = await axiosWithAuth.put(`/storage/deactivate`);
    const success = response.data;

    return success;
  },
  testStorageLocation: async (storageLocation: UpsertStorageLocation): Promise<boolean> => {
    const response = await axiosWithAuth.post(`/storage/test`, storageLocation); 
    const success = response.data;

    return success;
  },
};

export default storageService;
