import { axiosWithoutAuth } from "../utils/customAxios";

const payloadEncryptionKeyService = {
  fetchPublicKey: async (): Promise<string> => {
    try {
      const response = await axiosWithoutAuth.get('SystemSetting/GetPayloadEncryptionKey');
      const publicKey: string = response.data;

      return publicKey;
    } catch (err) {
      throw err;
    }
  },
};

export default payloadEncryptionKeyService;
