import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { MasterDataProvider } from "../contexts/MasterDataProvider";
import { CustomerPlatformStatus, PlatformStatusHistory } from "../types/customer/CustomerPlatformStatus";
import { Customer, PlatformDetail } from "../types/customer";
import customerService from "../services/customer.service";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import useBranding from "../hooks/useBranding";
import { SessionStorageKeys } from "../enums";
import { AUTH_BASE_URL, AUTH_CLIENT_ID } from "../constants";
import { appUrl } from "..";
import useLocale from "../hooks/useLocale";
import LoadingOverlay from "./LoadingOverlay";

const ProtectedRoutes: React.FC = () => {
  const location = useLocation();
  const localeCtx = useLocale();
  const auth = useAuth();
  const navigate = useNavigate();
  const brandingCtx = useBranding();
  const [msTeamsPlatformStatus, setMsTeamsPlatformStatus] = useState<CustomerPlatformStatus>();
  const [customerDetails, setCustomerDetails] = useState<Customer>();
  const [platformStatusLoading, setPlatformStatusLoading] = useState<boolean>(true);
  const [customerLoading, setCustomerLoading] = useState<boolean>(true);

  useEffect(() => {
    getMsTeamsPlatformDetails();
    getCustomerDetails();
  }, [auth?.tokenPayload?.CustomerId])

  useEffect(() => {
    if (!msTeamsPlatformStatus || (msTeamsPlatformStatus.platformName === "MsTeams" &&
      !msTeamsPlatformStatus.platformStatusHistory.find((platformHistory: PlatformStatusHistory) => platformHistory.statusName === "PlatformActive"))) {
      getMsTeamsPlatformDetails();
    }
  }, [navigate])

  const getMsTeamsPlatformDetails = async () => {
    try {
      setPlatformStatusLoading(true);
      const msTeamsStatus = await customerService.getCustomerPlatformStatus(auth?.tokenPayload?.CustomerId ?? 0, "MsTeams");
      setMsTeamsPlatformStatus(msTeamsStatus);
    } catch (error) {
      console.log(error);
    }
    finally {
      setPlatformStatusLoading(false);
    }
  }

  const getCustomerDetails = async () => {
    try {
      setCustomerLoading(true);
      const customerDetails = await customerService.getCustomerDetailsById(auth?.tokenPayload?.CustomerId ?? 0);
      setCustomerDetails(customerDetails)
    } catch (error) {
      console.log(error);
    }
    finally{
      setCustomerLoading(false);
    }
  }

  const logoutHandler = async () => {
    let authAppBaseUrl = AUTH_BASE_URL;
    if (brandingCtx?.branding?.authAppConfiguration.authClientAppUrl) {
      authAppBaseUrl =
        brandingCtx.branding.authAppConfiguration.authClientAppUrl;
    }
    let authAppClientId = AUTH_CLIENT_ID;
    if (brandingCtx?.branding?.authAppConfiguration.authAppClientId) {
      authAppClientId =
        brandingCtx.branding.authAppConfiguration.authAppClientId;
    }

    try {
      console.log("Loggin out...");
      window.sessionStorage.removeItem(
        SessionStorageKeys.VoiceAIInsightsFilter
      );
      localStorage.removeItem("VoiceAIToken");

      window.location.replace(
        `${authAppBaseUrl}/logout?clientId=${authAppClientId}&redirectUri=${appUrl}/login&language=${localeCtx?.selectedLocale?.current.locale.code}`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const grantPermissionHandler=()=>{
    window.open("/customer-platform",'_blank','noopener noreferrer')
  }

  return auth?.accessToken ? (
    platformStatusLoading || customerLoading ? 
      <LoadingOverlay
        customStyle={{ position: "fixed", marginTop: "55px" }}
        themeColor={"light"}
        size={"medium"}
        loadingText={""}
      />
    :
    <>
      {location.pathname!=="/customer-platform" && location.pathname!=="/customer-platform/admin-action" && customerDetails && msTeamsPlatformStatus && customerDetails.platformDetails.length===1 &&
      customerDetails.platformDetails.find((platform:PlatformDetail)=>platform.platform==="MsTeams") &&
      msTeamsPlatformStatus.platformName==="MsTeams" &&
      !msTeamsPlatformStatus.platformStatusHistory.find((platformHistory:PlatformStatusHistory)=> platformHistory.statusName==="PlatformActive") &&
        <Dialog title="Authorize Synchronization" className="dialogAuth">
            <div className="row">
              <div className="col">
                <img src="https://tollringtest.blob.core.windows.net/voiceailogo/A365_RX_Logo.png" alt="A365 Logo" className="logo" />
              </div>
            </div>
            <hr />
            <div className="row" style={{ maxWidth: "800px" }}>
              <div className="col">
                <p>To activate call recording, we need your Microsoft Teams Administrator to authorize synchronization with Microsoft 365.</p> <p>If you are the Teams Administrator, please</p>
                <button onClick={grantPermissionHandler} className='btn btn-sm bg-primary text-white mb-3'>Grant Permission now!</button>
                <p>If not, please contact your Teams Administrator to proceed with the authorization.</p>
              </div>
            </div>
            <DialogActionsBar>
              <Button onClick={logoutHandler}>Log Out!</Button>
            </DialogActionsBar>
          </Dialog>
      }
      <div className="float-left w-100 p-t-55">
        <div className="mobile-contain h-100 pt-4 p-l-15 p-r-15">
          <div className="container-fluid h-100">
            <div className="row h-100">
              <div className="col-md-12">
                <MasterDataProvider>
                  <Outlet />
                </MasterDataProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoutes;
