import { User } from "../types/user";

export const sortUsersData = (_users: User[]) => {
    return _users.sort((a, b) => {
      let a_name = (
        (a.firstName ? a.firstName : "") +
        " " +
        (a.lastName ? a.lastName : "")
      )
        .trim()
        .toLocaleLowerCase();
      let b_name = (
        (b.firstName ? b.firstName : "") +
        " " +
        (b.lastName ? b.lastName : "")
      )
        .trim()
        .toLocaleLowerCase();
      return a_name > b_name ? 1 : -1;
    });
  };