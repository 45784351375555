import * as forge from 'node-forge';
import payloadEncryptionKeyService from '../services/payloadEncryption.service';

export interface encryptionResponse {
    encryptedSymmetricKey: string,
    encryptedData: string,
    iv: string
}

interface publicKeyLocalStorage {
    PayloadEncryptionPublicKey: string,
    expiryDateTime: Date;
}

export const encryption = async (stringifiedJSON: string): Promise<encryptionResponse | string> => {
    const publicKey: string | null = window.localStorage.getItem('PayloadEncryptionPublicKey');

    if (publicKey == null || publicKey == undefined) {
        const publicKeyFromAPI: string = await setPrimaryKeyInStorage();

        return encryptData(stringifiedJSON, publicKeyFromAPI);

    } else {
        if (new Date() > JSON.parse(publicKey).expiryDateTime) {
            const publicKeyFromAPI: string = await setPrimaryKeyInStorage();
            return encryptData(stringifiedJSON, publicKeyFromAPI);
        }
        else {
            return encryptData(stringifiedJSON, JSON.parse(publicKey).PayloadEncryptionPublicKey);
        }
    }
}

const setPrimaryKeyInStorage = async () => {
    const publicKeyFromAPI = await payloadEncryptionKeyService.fetchPublicKey();

    const publicKeyToSet: publicKeyLocalStorage = {
        PayloadEncryptionPublicKey: publicKeyFromAPI,
        expiryDateTime: new Date((new Date()).getTime() + 24 * 60 * 60 * 1000) // Adding 24 hrs to current time
    }

    window.localStorage.setItem('PayloadEncryptionPublicKey', JSON.stringify(publicKeyToSet));
    return publicKeyFromAPI;
}

const encryptData = (stringifiedJSON: string, publicKey: string): encryptionResponse | string => {
    try {
        const forgePublicKey = forge.pki.publicKeyFromPem(publicKey);
        // Generate AES key and IV
        const symmetricKey = forge.random.getBytesSync(32); // AES-256 key size
        const iv = forge.random.getBytesSync(16); // AES IV size is 16 bytes

        // Encrypt the symmetric key using RSA
        const encryptedSymmetricKey = forgePublicKey.encrypt(symmetricKey, 'RSA-OAEP', { md: forge.md.sha256.create(), });

        // Encrypt the data using AES and the symmetric key
        const messageBytes = forge.util.encodeUtf8(stringifiedJSON);

        const aesCipher = forge.cipher.createCipher('AES-CBC', symmetricKey);

        aesCipher.start({ iv });
        aesCipher.update(forge.util.createBuffer(messageBytes));
        aesCipher.finish();
        const encryptedData = aesCipher.output.getBytes();

        const encryptedSymmetricKeyBase64 = forge.util.encode64(encryptedSymmetricKey);
        const encryptedDataBase64 = forge.util.encode64(encryptedData);
        const ivBase64 = forge.util.encode64(iv);

        const result: encryptionResponse = {
            encryptedSymmetricKey: encryptedSymmetricKeyBase64,
            encryptedData: encryptedDataBase64,
            iv: ivBase64
        };

        return result;
    } catch (err) {
        // If encryption fails, hit API with normal payload
        return JSON.parse(stringifiedJSON);
    }
}