import { GridCellProps } from '@progress/kendo-react-grid'
import { APIToken } from './APIAccessProps';
import useSwal from '../../../../hooks/useSwal';
import useLocale from '../../../../hooks/useLocale';
import { useEffect, useState } from 'react';
import { Dictionary } from '../../../../types/Dictionary';
import { SweetAlertOptions } from 'sweetalert2';
import { Loader } from '@progress/kendo-react-indicators';
import React from 'react';
import { TinyUserGroup } from '../../../../types/user/UserGroupRelation';
import { Button } from '@progress/kendo-react-buttons';
import useMasterData from '../../../../hooks/useMasterData';
interface APIAccessForMobileProps extends GridCellProps {
    dataItem: APIToken;
    loading: boolean;
    error: boolean;
    onEditHandler: (data: APIToken) => void;
    onDeleteHandler: (data: APIToken) => Promise<void>
}

const APIAccessForMobile = (props: APIAccessForMobileProps) => {
    const swal = useSwal();
    const localeCtx = useLocale();
    const masterData = useMasterData();


    const [translationsLoading, setTranslationsLoading] = useState<boolean>(false);
    const [translations, setTranslations] = useState<Dictionary<string> | undefined>
        (localeCtx?.selectedLocale?.current.componentTranslations["APIAccess"]);

    useEffect(() => {
        if (!localeCtx?.selectedLocale?.current.componentTranslations["APIAccess"]) {
            fetchTranslations();
        }
    }, [localeCtx?.selectedLocale]);

    const fetchTranslations = async () => {
        try {
            setTranslationsLoading(true);
            const resp = await localeCtx?.setComponentTranslations("APIAccess");
            setTranslations(resp);
        } catch (err) {
            console.error(err);
            setTranslations(
                localeCtx?.selectedLocale?.previous.componentTranslations["APIAccess"]
            );
            localeCtx?.setPreviousAppLocale("APIAccess");
            if (localeCtx?.localeSwitchFailed) {
                const swalOptions: SweetAlertOptions<any, any> = {
                    icon: "error",
                    title: "Error",
                    text: "Couldn't Switch Language",
                };
                await swal.fire(swalOptions);
            }
        } finally {
            setTimeout(() => {
                setTranslationsLoading(false);
            }, 100);
        }
    };

    const fetchLabelKeyTranslation = (
        key: string,
        defaultValue: string
    ): string => {
        return translations && translations[key] ? translations[key] : defaultValue;
    };

    console.log(props)
    const loading = props.loading;
    const error = props.error;
    const tokenName = props.dataItem.tokenName ?? "";
    const permissionGroup = props.dataItem.authorizationProfile?.name ?? "";
    const recordingNetwork = props.dataItem.recordingNetworkAccessibility?.name ?? "";
    const tokenExpiry = props.dataItem.tokenExpiry ?? "";

    return (
        <td className="sysSettingsListMobile">
            <div className="listRowMobile float-left p-0">
                {loading && <Loader type={"infinite-spinner"} />}
                {!loading && error && (
                    <span className="fs-15">
                        <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                        {`${translationsLoading
                            ? "Uh Oh! Something Went Wrong. Please Try Again!"
                            : fetchLabelKeyTranslation(
                                "ErrMsg",
                                "Uh Oh! Something Went Wrong. Please Try Again!"
                            )
                            }`}
                    </span>
                )}

                {!loading && !error
                    &&
                    <React.Fragment>
                        <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className="text-primary font-weight-semi line-height-2">
                                <span>
                                    {tokenName}
                                </span>
                            </div>
                            <div className="d-flex flex-wrap text-primary font-weight-semi line-height-2">
                                <Button
                                    className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn
                                    ${masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges && "disabledBtn"}
                                    `}
                                    onClick={() => props.onEditHandler(props.dataItem)}
                                    disabled={masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges}
                                >
                                    <span
                                        className="fs-17 line-height-1 text-primary"
                                        title={`${translationsLoading
                                            ? "Edit"
                                            : fetchLabelKeyTranslation("EditButton", "Edit")
                                            }`}
                                    >
                                        <i className="bi bi-pencil"></i>
                                    </span>
                                </Button>
                                <Button
                                    className={`radius-50 iBtn-bg d-flex align-items-center justify-conter m-r-3 iconBtn
                                    ${masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges && "disabledBtn"}
                                    `}
                                    onClick={() => props.onDeleteHandler(props.dataItem)}
                                    disabled={masterData?.telepoDisableConfig?.systemSetting.apiAccess.disableChanges}
                                >
                                    <span
                                        className="fs-17 line-height-1 text-primary"
                                        title={`${translationsLoading
                                            ? "Delete"
                                            : fetchLabelKeyTranslation("DeleteButton", "Delete")
                                            }`}
                                    >
                                        <i className="bi bi-trash3"></i>
                                    </span>
                                </Button>
                            </div>
                        </div>

                        <div className="uCardCol mx-th-tag">
                            <div className="tblUsr">
                                <div className="topUsrAreaPic">
                                    <div className="topUsrAreaPic-tx">
                                        <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                                            <i style={{ fontSize: "8px", color: new Date() > new Date(tokenExpiry) ? "red" : "blue" }}
                                                className="bi bi-circle-fill"></i>&nbsp;{new Date() > new Date(tokenExpiry) ?
                                                    translationsLoading
                                                        ? " Expired"
                                                        : fetchLabelKeyTranslation("ExpiredStatusText", " Expired")
                                                    : translationsLoading
                                                        ? "Active"
                                                        : fetchLabelKeyTranslation("ActiveStatusText", " Active")
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="miniTextTime float-left w-100 p-t-6">
                            <div className="listLabelRow d-flex flex-wrap justify-content-start w-100 float-left line-height-1">
                                <div className="listLabelCol">
                                    <span className="fs-12 font-weight-semi p-r-3 text-black-12">
                                        {translationsLoading
                                            ? "Permission Group"
                                            : fetchLabelKeyTranslation("PermissionGroupGridTitle", "Permission Group")
                                        }:
                                    </span>
                                </div>
                                <div className="listLabelCol">
                                    <span className="fs-12 text-black-11">
                                        {permissionGroup}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="miniTextTime float-left w-100 p-t-6">
                            <div className="listLabelRow d-flex flex-wrap justify-content-start w-100 float-left line-height-1">
                                <div className="listLabelCol">
                                    <span className="fs-12 font-weight-semi p-r-3 text-black-12">
                                        {translationsLoading
                                            ? "Recording Network"
                                            : fetchLabelKeyTranslation("RecordingNetworkGridTitle", "Recording Network")
                                        }:
                                    </span>
                                </div>
                                <div className="listLabelCol">
                                    <span className="fs-12 text-black-11">
                                        {recordingNetwork}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="miniTextTime float-left w-100 p-t-6">
                            <div className="listLabelRow d-flex flex-wrap justify-content-start w-100 float-left line-height-1">
                                <div className="listLabelCol" style={{ minWidth: 'fit-content' }}>
                                    <span className="fs-12 font-weight-semi p-r-3 text-black-12">
                                        {translationsLoading
                                            ? "User Groups"
                                            : fetchLabelKeyTranslation("UserGroupsGridTitle", "User Groups")
                                        }:
                                    </span>
                                </div>

                                <div className="uCardCol mx-th-tag">
                                    <div className="tblUsr">
                                        <div className="topUsrAreaPic">
                                            <div className="topUsrAreaPic-tx">
                                                <span className="cursor-default text-decoration-none float-left w-100 line-height-1 mx-td-spn">
                                                    {props.dataItem.recordingUserGroups.map((group: TinyUserGroup) => {
                                                        return (
                                                            <div className="keywordTrk">{group.name}</div>
                                                        )
                                                    })}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="miniTextTime float-left w-100 p-t-6">
                            <div className="listLabelRow d-flex flex-wrap justify-content-start w-100 float-left line-height-1">
                                <div className="listLabelCol">
                                    <span className="fs-12 font-weight-semi p-r-3 text-black-12">
                                        {translationsLoading
                                            ? "Token Expiry"
                                            : fetchLabelKeyTranslation("TokenExpiryGridTitle", "Token Expiry")
                                        }:
                                    </span>
                                </div>
                                <div className="listLabelCol">
                                    <span className="fs-12 text-black-11">
                                        {tokenExpiry}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </React.Fragment>
                }
            </div>
        </td>
    )
}

export default APIAccessForMobile
