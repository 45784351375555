import { Group } from "../types/user-groups/Group";
import { axiosWithAuth } from "../utils/customAxios";

const userGroupsService = {
  getUserGroups: async (): Promise<Group[]> => {
    try {
      const response = await axiosWithAuth.get(`/group`);
      const groups: Group[] = response.data;
      return groups;
    } catch (ex) {
      throw ex;
    }
  },

  upsertUserGroup: async (group: Group): Promise<Group> => {
    try {
      if (group.id > 0) {
        const response = await axiosWithAuth.put(`/group`, group);
        const upsertedGroup: Group = response.data;
        return upsertedGroup;
      } else {
        const response = await axiosWithAuth.post(`/group`, group);
        const upsertedGroup: Group = response.data;
        return upsertedGroup;
      }
    } catch (ex) {
      throw ex;
    }
  },

  deleteUserGroups: async (id: number): Promise<Group[]> => {
    try {
      const response = await axiosWithAuth.delete(`/group/${id}`);
      return response.data;
    } catch (ex) {
      throw ex;
    }
  },
};

export default userGroupsService;
