import React, { useEffect, useState } from "react";
import { RecordingSentiment } from "../../types/recording";
import { IsValidString } from "../../utils/stringUtils";

type CustomSentimentProps = {
  iconSize?: number | undefined;
  sentiment?: RecordingSentiment;
  isAIAnalysed?: boolean;
};

const CustomSentiment: React.FC<CustomSentimentProps> = ({
  iconSize,
  sentiment,
  isAIAnalysed,
}) => {
  const [overallSentiment, setOverallSentiment] = useState<
    string | undefined
  >();

  var fontSize = iconSize !== undefined && iconSize > 0 ? iconSize : 18;

  useEffect(() => {
    if (sentiment) {
      let res = undefined;
      if (
        sentiment.positivePrediction > sentiment.negativePrediction &&
        sentiment.positivePrediction > sentiment.neutralPrediction
      ) {
        res = "Positive";
      } else if (
        sentiment.negativePrediction > sentiment.positivePrediction &&
        sentiment.negativePrediction > sentiment.neutralPrediction
      ) {
        res = "Negative";
      } else if (
        sentiment.neutralPrediction > sentiment.positivePrediction &&
        sentiment.neutralPrediction > sentiment.negativePrediction
      ) {
        res = "Neutral";
      }

      setOverallSentiment(res);
    }
  }, [sentiment]);

  const positiveSentiment = (
    <span className={`fs-${fontSize} tx-green`} title="Positive">
      <i className="bi bi-emoji-smile"></i>
    </span>
  );

  const negativeSentiment = (
    <span className={`fs-${fontSize} tx-red`} title="Negative">
      <i className="bi bi-emoji-frown"></i>
    </span>
  );

  const neutralSentiment = (
    <span className={`fs-${fontSize} tx-amber`} title="Neutral">
      <i className="bi bi-emoji-neutral"></i>
    </span>
  );

  const noSentiment = (
    <span className={`fs-${fontSize} text-black-6`} title="Not Available">
      <i className="bi bi-emoji-smile"></i>
    </span>
  );

  return (
    <div className={`${isAIAnalysed ? "" : "blurArea1"}`}>
      {overallSentiment ? (
        <>
          {overallSentiment === "Positive" && positiveSentiment}
          {overallSentiment === "Negative" && negativeSentiment}
          {overallSentiment === "Neutral" && neutralSentiment}
        </>
      ) : (
        noSentiment
      )}
    </div>
  );
};

export default CustomSentiment;
