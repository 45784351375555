import { SpeakerTranscriptInfo } from "../types/transcription";
import { CombinedTranscription } from "../types/transcription/CombinedTranscription";
import { RecordingTrackSegment } from "../types/player-track";

export const combineTranscriptSentiment = (processedTranscript: SpeakerTranscriptInfo[],
sentimentsTrackInsight?: RecordingTrackSegment[]) : CombinedTranscription[] =>
{
    var combinedTranscript : CombinedTranscription[] = []
    
    let t = 0;

    let iNoSentimentCount = 0;

    if(sentimentsTrackInsight?.length == 3)
    {        
        for(let s = 0; s < sentimentsTrackInsight?.length; s++){
            if(sentimentsTrackInsight[s].start == 0 &&
                sentimentsTrackInsight[s].end == 0)
                {
                    iNoSentimentCount++;
                }
        }            
    }

    for(let i = 0 ; i < processedTranscript.length; i++)
    {
        let sentimentTemp = '';
        let sentimentCssTemp = '';
        
        

        if(sentimentsTrackInsight){
            while(processedTranscript[i].start > sentimentsTrackInsight[t].end){
                if(t< sentimentsTrackInsight.length-1){
                    t++;
                   }
                   else
                    break;
            }

            let j = t;
            let k = j;
            let timeToUse = 0

            while(processedTranscript[i].end > sentimentsTrackInsight[j].end)
            {
                let time = 0;

                if(processedTranscript[i].end > sentimentsTrackInsight[j].end)
                    time = sentimentsTrackInsight[j].end - processedTranscript[i].start;
                else
                    time =  processedTranscript[i].end -sentimentsTrackInsight[j].start;

                if(time > timeToUse)
                    k = j;

               if(j< sentimentsTrackInsight.length-1){
                j++;
               }
               else
                break;
            }
            

            sentimentTemp = sentimentsTrackInsight[k].label;
            sentimentCssTemp = sentimentsTrackInsight[k].cssLabel;

            if(iNoSentimentCount == 3){
                sentimentCssTemp = "unhappy bg-black-5";
            }
        }

        combinedTranscript.push({
            id: processedTranscript[i].id,
            speaker: processedTranscript[i].speaker,
            text: processedTranscript[i].text,
            start: processedTranscript[i].start,
            end: processedTranscript[i].end,
            active: processedTranscript[i].active,
            sentiment: sentimentTemp,
            sentimentCss : sentimentCssTemp

        });
    }

    return combinedTranscript;
}