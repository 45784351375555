import { useContext, useEffect, useState } from "react";
import Swal, { SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from "sweetalert2";
import useBranding from "./useBranding";

interface SwalHookReturnType {
  fire: (options: SweetAlertOptions<any, any>) => Promise<SweetAlertResult<any>>
  showValidationMessage: (message: string) => void
  isLoading: () => boolean
}

const useSwal = (): SwalHookReturnType => {
  const brandingCtx = useBranding()
  const fire = async (options: SweetAlertOptions<any, any>): Promise<SweetAlertResult<any>> => {
    const optionsWithBranding: SweetAlertOptions<any, any> = {
      ...options,
      iconColor: brandingCtx?.branding?.themeColors[0].colorValue,
      confirmButtonColor: brandingCtx?.branding?.themeColors[0].colorValue,
      cancelButtonColor: brandingCtx?.branding?.themeColors[0].colorValue
    }
    var res = await Swal.fire({ ...optionsWithBranding })
    return res;
  }

  const showValidationMessage = (message: string) => {
    Swal.showValidationMessage(message);
  };

  const isLoading = (): boolean => {
    return Swal.isLoading();
  };

  return { fire, showValidationMessage, isLoading }
};

export default useSwal;