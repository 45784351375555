export enum AccessPermissionEnum {
    ListenCalls=1,
    ShareCalls=2,
    EvaluateCalls=3, 
    ManageUsersAndGroups=4,
    ManageScorecards=6,
    ManageTopics=8,
    ManageAccount=10,
    ManageOutcomes=12,
    ManageSecurityControl=14,
    DeleteCalls=15,
    ManageCompliance=16
}

export enum EntityLocalizationGroupEnum {
    AuditKeysFields = "AuditFieldKeys",
    Scorecard = "Scorecard",
    ScorecardQuestionGroup = "ScorecardQuestionGroup",
    ScorecardQuestion = "ScorecardQuestion"
}