import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import { formatSecondsToTime } from "../../../utils/dateTimeUtils";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
}

const CustomGridCellForDurationSeconds = (props: CustomGridCellProps) => {
  return <td>{formatSecondsToTime(props.dataItem.callDuration)}</td>;
};

export default CustomGridCellForDurationSeconds;
