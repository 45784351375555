import { Dictionary } from "../types/Dictionary";
import { LocalizationCode } from "../types/locale/LocalizationCode";
import { EntityLocalizationConfiguration, Translation } from "../types/locale/Translation";
import { axiosWithAuth, axiosWithoutAuth } from "../utils/customAxios";

const localeService = {
  GetLocalizationSupportedLanguage: async (): Promise<LocalizationCode[]> => {
    try {
      const response = await axiosWithAuth.get(`/locale/language`);
      const supportedLanguage: LocalizationCode[] = response.data;
      return supportedLanguage;
    } catch (err) {
      throw err;
    }
  },

  GetTranslationText: async (
    localeCode: string,
    keySource: string,
    keyGroup: string,
    keySubGroup: string | null,
    key: string | null
  ): Promise<any> => {
    try {
      const response = await axiosWithAuth.get(
        `/locale/translate?locale=${localeCode}&source=${keySource}&keyGroup=${keyGroup}&keySubGroup=${keySubGroup}&keyName=${key}`
      );
      const translation = response.data;
      return translation;
    } catch (err) {
      throw err;
    }
  },

  fetchSupportedLocales: async (): Promise<LocalizationCode[]> => {
    const response = await axiosWithoutAuth.get("/locale/language");
    const supportedLocales: LocalizationCode[] = response.data;

    return supportedLocales;
  },

  fetchTranslations: async (
    localeCode: string,
    keyGroup: string,
    keySubGroup?: string,
    key?: string
  ): Promise<Dictionary<string>> => {
    let requestQuery = "";
    if (keySubGroup) {
      requestQuery += `&keySubGroup=${keySubGroup}`;
    }
    if (key) {
      requestQuery += `&key=${key}`;
    }

    const response = await axiosWithoutAuth.get(
      `/locale/translate?locale=${localeCode}&source=WebApp&keyGroup=${keyGroup}${requestQuery}`
    );
    const translations: Dictionary<string> = response.data;

    return translations;
  },

  fetchMasterDataTranslations: async (
    localeCode: string,
    keyGroup: string[]
  ): Promise<Dictionary<Dictionary<string>>> => {
    let requestQuery = "";
    if (keyGroup.length > 0) {
      requestQuery += keyGroup.map((k) => `keyGroup=${k}`).join("&");
    }

    const response = await axiosWithoutAuth.get(
      `/locale/translate/keygroups?locale=${localeCode}&source=MasterData&${requestQuery}`
    );
    const translations: Dictionary<Dictionary<string>> = response.data;

    return translations;
  },

  fetchKendoTranslations: async (
    code: string
  ): Promise<Dictionary<Dictionary<string>>> => {
    const response = await axiosWithoutAuth.get(
      `/locale/translate/kendo?locale=${code}`
    );
    const translations: Dictionary<Dictionary<string>> = response.data;

    return translations;
  },

  fetchEntityLocalizationNameKeyMapping: async () => 
  {
    const response = await axiosWithoutAuth.get(`/systemsetting/entitylocalizationconfiguration`)
    const mapping : EntityLocalizationConfiguration[] = response.data
    return mapping
  }
};

export default localeService;
