import { Branding } from "../types/Branding";
import { axiosWithoutAuth } from "../utils/customAxios";
import { Buffer } from "buffer";

const brandingService = {
  fetchBranding: async (appUrl: string): Promise<Branding> => {
    try {
      const encodedAppUrl = Buffer.from(appUrl).toString('base64');
      const response = await axiosWithoutAuth.get(
        `branding/brandingbyappurl?appUrl=${encodedAppUrl}`
      );
      const branding: Branding = response.data;

      return branding;
    } catch (err) {
      throw err;
    }
  },
};

export default brandingService;
