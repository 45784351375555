import { DeviceAllocation } from "../types/device/deviceAllocation";
import { PlatformUserDetail } from "../types/user";
import { axiosWithAuth } from "../utils/customAxios";

const deviceService = {
  getAllCustomerDevice: async (platform: string = ""): Promise<DeviceAllocation[]> => {
    var requestQuery = "";
    if(platform && platform !== ""){
      requestQuery += `?platform=${platform}`;
    }
    const response = await axiosWithAuth.get(`/device${requestQuery}`);
    const devices: DeviceAllocation[] = response.data;
    return devices;
  },
};

export default deviceService;
